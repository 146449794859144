import Accumulative from 'src/controllers/Accumulative';
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
const accumulativeInitialState = {};

export const getInvestmentList = createAsyncThunk(
  'accumulative/getInvestmentList',
  async () => {
    const resp = await Accumulative.getInvestmentList();
    return resp;
  },
);

export const getAccumulativeList = createAsyncThunk(
  'accumulative/getAccumulativeList',
  async () => {
    const resp = await Accumulative.getAccumulativeList();
    return resp;
  },
);

export const accumulativeCreate = createAsyncThunk(
  'accumulative/accumulativeCreate',
  async (params: any) => {
    const resp = await Accumulative.accumulativeCreate(params);
    return resp;
  },
);

export const getAccumulativeProfitTransaction = createAsyncThunk(
  'accumulative/getAccumulativeProfitTransaction',
  async (params: any) => {
    const resp = await Accumulative.getAccumulativeProfitTransaction(params);
    return resp;
  },
);

export const accumulativeWithdraw = createAsyncThunk(
  'accumulative/accumulativeWithdraw',
  async (params: any) => {
    const resp = await Accumulative.accumulativeWithdraw(params);
    return resp;
  },
);

export const accumulativeProfitWithdraw = createAsyncThunk(
  'accumulative/accumulativeProfitWithdraw',
  async (params: any) => {
    const resp = await Accumulative.accumulativeProfitWithdraw(params);
    return resp;
  },
);

const accumulative = createSlice({
  name: 'accumulative',
  initialState: accumulativeInitialState,
  reducers: {},
});

const { actions, reducer } = accumulative;
export default reducer;
