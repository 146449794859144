import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import {
  getProfile,
  logOut,
  setUserLogin,
} from "src/components/Features/user/userSlice";
import { useAppDispatch } from "src/components/Redux/store";
import Types from "src/constants/Types";

const useGetProfile = () => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    const accessToken = Cookies.get(Types.ACCESS_TOKEN);
    if (accessToken) {
      const resp: any = await dispatch(getProfile());
      // console.log('resp user---', resp);
      if (resp?.payload?.status === true) {
        const data = resp.payload.data || {};
        dispatch(setUserLogin(data));
        setData(data);
        setLoading(false);
      }
      else {
        Cookies.remove(Types.ACCESS_TOKEN);
        dispatch(logOut());
      }
    }

  };
  return { data, loading };
};

export default useGetProfile;
