import React, { memo, useEffect, useState } from "react";
import { toast } from "react-toastify";
import ProductImages from "src/assets/Images/Product";
import { removeAddress } from "src/components/Features/address/addressSlice";
import { getAddress } from "src/components/Features/user/userSlice";
import ModalAddAddress from "src/components/Modals/ModalAddAddress";
import ModalEditAddress from "src/components/Modals/ModalEditAddress";
import { useAppDispatch } from "src/components/Redux/store";

const MyAddress = () => {
  const dispatch = useAppDispatch();
  const [openAddAddress, setOpenAddAddress] = useState(false);
  const [openEditAddress, setOpenEditAddress] = useState(false);
  const [listAddress, setListAddress] = useState([]);
  const [chooseAddress, setChooseAddress] = useState("");

  useEffect(() => {
    getUserAddress();
  }, []);

  const getUserAddress = async () => {
    const resp: any = await dispatch(getAddress());
    if (resp.payload.status === true) {
      console.log("resp address---", resp);
      const data = resp.payload.data || [];
      setListAddress(data);
    }
  };

  const onDelete = async (userAddressID: number | string) => {
    const resp: any = await dispatch(removeAddress({ userAddressID }));
    if (resp.payload.status === true) {
      toast.success("Xóa địa chỉ thành công");
      getUserAddress();
    }
  };
  return (
    <div className="col col-md-9 mt-5">
      <div className="bg-white py-4 px-3 rounded container-right">
        <div className="d-flex justify-content-between">
          <div className="txt-title-right">Sổ địa chỉ</div>
          <button
            className="btn-add-bank"
            onClick={() => setOpenAddAddress(true)}
          >
            + Thêm mới
          </button>
        </div>
        <div className="border w-100 my-4" />
        {!listAddress.length ? (
          <div className="text-center fw-semibold">
            Hiện bạn chưa thêm địa chỉ nào !
          </div>
        ) : (
          listAddress.map((item: any, index: number) => {
            return (
              <div key={item?._id}>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <div>
                    <div className="d-flex align-items-center">
                      <div className="txt-name-address">{item?.name}</div>
                      <div className="txt-name-address mx-1">•</div>
                      <div className="txt-name-address">{item?.phone}</div>
                    </div>
                    <div className="txt-des-address pt-1 text-start">
                      {item?.address},{item?.wardName},{item?.districtName},
                      {item?.provinceName}
                    </div>
                    {item?.isDefault && (
                      <div className="txt-default mt-1">Mặc định</div>
                    )}
                  </div>
                  <div className="gap-2 d-flex">
                    <button
                      className="border border-0 btn p-0"
                      onClick={() => {
                        setChooseAddress(item);
                        setOpenEditAddress(true);
                      }}
                    >
                      <img
                        src={ProductImages.icon_edit}
                        width={40}
                        height={40}
                        alt=""
                      />
                    </button>
                    <button
                      className="border border-0 btn p-0"
                      onClick={() => onDelete(item?.userAddressID)}
                    >
                      <img
                        src={ProductImages.icon_delete}
                        width={40}
                        height={40}
                        alt=""
                      />
                    </button>
                  </div>
                </div>

                {listAddress.length === index + 1 ? (
                  <div />
                ) : (
                  <div className="border w-100 my-4" />
                )}
              </div>
            );
          })
        )}
      </div>
      <ModalAddAddress
        isOpen={openAddAddress}
        isClose={() => setOpenAddAddress(false)}
        isSubmit={() => getUserAddress()}
        province={(value: any) => console.log(value)}
        district={(value: any) => console.log(value)}
        ward={(value: any) => console.log(value)}
        chooseAddress={(text: string) => console.log(text)}
      />

      <ModalEditAddress
        isOpen={openEditAddress}
        isClose={() => setOpenEditAddress(false)}
        isSubmit={() => getUserAddress()}
        chooseAddress={chooseAddress}
      />
    </div>
  );
};

export default memo(MyAddress);
