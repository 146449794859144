import React from "react";
import NavBar from "../Navbar";
import { useSelector } from "react-redux";
import { userInfor } from "src/components/Redux/selector";

function ReturnPolicy() {
  const user = useSelector(userInfor);

  return (
    <div>
      {!user && <NavBar />}
      <div className="container bg-white rounded-1 p-3 d-flex flex-column">
        <div className="py-3 fw-semibold fs-4 text-center">
          Chính sách đổi trả hàng
        </div>
        <div>
          Nhằm đảm bảo quyền lợi người tiêu dùng, nâng cao chất lượng dịch vụ
          sau bán hàng, FFE có đưa ra một số quy định rõ ràng về “chính sách đổi
          trả” như sau:
        </div>
        <div className="fw-semibold">I – Cách thức hoàn trả:</div>
        <div>
          Quý khách vui lòng trực tiếp kiểm tra kỹ hàng hoá ngay khi nhận hàng
          từ nhân viên giao hàng, nếu có vấn đề liên quan tới chủng loại, mẫu
          mã, chất lượng, số lượng hàng hoá không đúng như trong đơn đặt hàng,
          quý khách có quyền trả hàng ngay lập tức. Nếu không có bất cứ vấn đề
          gì, quý khách vui lòng nhận hàng và ký bào biên bản nhận hàng. Sau khi
          kí vào biên bản này, công ty sẽ không có trách nhiệm với những yêu cầu
          đổi trả vì hư hỏng, trầy xước, bể vỡ, mốp méo, sai hàng hóa, vv… từ
          quý khách sau này.
        </div>
        <div className="fw-semibold pt-1">
          II – Trường hợp khách được trả hàng
        </div>
        <div>
          - Sản phẩm bị mất niêm phong, bị giao sai về số lượng, thông tin và
          mẫu mã so với đơn đặt hàng.
        </div>
        <div>- Sản phẩm hàng hóa không đúng số seri/IMEI</div>
        <div>
          - Sản phẩm bị lỗi hình thức (trầy xước, vỡ, móp méo, ố vàng…).
        </div>
        <div>
          - Sản phẩm không còn nguyên vẹn vỏ thùng, xốp và các phụ kiện kèm
          theo..
        </div>
        <div className="fw-semibold pt-1">III – Thời gian hoàn trả</div>
        <div>
          - Ngay sau khi giao hàng và khách hàng chưa kí vào biên bản nhận hàng.
        </div>
        <div className="fw-semibold pt-1">
          IV – Thời gian khách được nhận tiền hoàn trả
        </div>
        <div>
          - Thế giới điện máy online chỉ nhận tiền sau khi khách hàng ký vào
          biên bản nhận hàng.
        </div>
        <div>
          - Tuy nhiên trong trường hợp khách hàng đã chuyển khoản trước: chúng
          tôi sẽ hoàn trả lại tiền cho khách hàng trong vòng 24 giờ kể từ thời
          điểm nhận được hàng trả về.
        </div>
      </div>
    </div>
  );
}

export default ReturnPolicy;
