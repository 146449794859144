
const ProfileImages = {
    edit: require('./edit.png'),
    lock: require('./lock.png'),
    icon_warning: require('./icon_warning.png'),
    icon_dot: require('./icon_dot.png'),
    close: require('./close.png'),
    error: require('./error.png'),
    heo: require('./heo.png'),
}

export default ProfileImages