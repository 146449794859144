import React from "react";
import NavBar from "../Navbar";
import { useSelector } from "react-redux";
import { userInfor } from "src/components/Redux/selector";

function InformationSecurityPolicy() {
  const user = useSelector(userInfor);

  return (
    <div>
      {!user && <NavBar />}
      <div className="container bg-white rounded-1 p-3 d-flex flex-column">
        <div className="py-3 fw-semibold fs-4 text-center">
          Chính sách bảo mật thông tin
        </div>
        <div className="fw-semibold">
          1. Mục đích và phạm vi thu thập thông tin
        </div>
        <div>
          - Ffe.vn không bán, chia sẻ hay trao đổi thông tin cá nhân của khách
          hàng thu thập trên trang web cho một bên thứ ba nào khác.
        </div>
        <div>
          - Thông tin cá nhân thu thập được sẽ chỉ được sử dụng trong nội bộ
          công ty.
        </div>
        <div>
          - Khi bạn liên hệ đăng ký dịch vụ, thông tin cá nhân mà ffe.vn thu
          thập bao gồm:
        </div>
        <div>• Họ và tên</div>
        <div>• Địa chỉ</div>
        <div>• Điện thoại</div>
        <div>• Email</div>
        <div>Ngoài thông tin cá nhân là các thông tin về dịch vụ</div>
        <div>• Tên sản phẩm</div>
        <div>• Số lượng</div>
        <div>• Thời gian giao nhận sản phẩm</div>
        <div className="fw-semibold pt-1">2. Phạm vi sử dụng thông tin</div>
        <div>
          Thông tin cá nhân thu thập được sẽ chỉ được ffe.vn sử dụng trong nội
          bộ công ty và cho một hoặc tất cả các mục đích sau đây:
        </div>
        <div>• Hỗ trợ khách hàng</div>
        <div>• Cung cấp thông tin liên quan đến dịch vụ</div>
        <div>
          • Xử lý đơn đặt hàng và cung cấp dịch vụ và thông tin qua trang web
          của chúng tôi theo yêu cầu của bạn
        </div>
        <div>
          • Chúng tôi có thể sẽ gửi thông tin sản phẩm, dịch vụ mới, thông tin
          về các sự kiện sắp tới hoặc thông tin tuyển dụng nếu quý khách đăng kí
          nhận email thông báo. Ngoài ra, chúng tôi sẽ sử dụng thông tin bạn
          cung cấp để hỗ trợ quản lý tài khoản khách hàng; xác nhận và thực hiện
          các giao dịch tài chính liên quan đến các khoản thanh toán trực tuyến
          của bạn
        </div>
        <div className="fw-semibold pt-1">3. Thời gian lưu trữ thông tin</div>
        <div>
          - Đối với thông tin cá nhân, ffe.vn chỉ xóa đi dữ liệu này nếu khách
          hàng có yêu cầu, khách hàng yêu cầu gửi mail về appffevn@gmail.com
        </div>
        <div className="fw-semibold pt-1">
          4. Những người hoặc tổ chức có thể được tiếp cận với thông tin cá nhân
        </div>
        <div>
          Đối tượng được tiếp cận với thông tin cá nhân của khách hàng thuộc một
          trong những trường hợp sau:
        </div>
        <div>- Công ty cổ phần xây dựng và nội thất Xuân Mai</div>
        <div>
          - Các đối tác có ký hợp động thực hiện 1 phần dịch vụ do hộ kinh doanh
          Trần Văn Chính, Các đối tác này sẽ nhận được những thông tin theo thỏa
          thuận hợp đồng (có thể 1phần hoặc toàn bộ thông tin tùy theo điều
          khoản hợp đồng) để tiến hành hỗ trợ người dùng sử dụng dịch vụ do Công
          ty cung cấp.
        </div>
        <div className="fw-semibold pt-1">
          5. Địa chỉ của đơn vị thu thập và quản lý thông tin cá nhân
        </div>
        <div>Tên công ty: CÔNG TY CỔ PHẦN SẢN XUẤT VÀ THƯƠNG MẠI FFE GROUP</div>
        <div>
          Địa Chỉ: A11 Ngõ 17 P. Lê Văn Thiêm, Nhân Chính, Thanh Xuân, Hà Nội,
          Việt Nam
        </div>
        <div>Hotline: 0853511111</div>
        <div>Email: appffevn@gmail.com</div>
        <div className="fw-semibold pt-1">
          6. Phương tiện và công cụ để người dùng tiếp cận và chỉnh sửa dữ liệu
          cá nhân của mình
        </div>
        <div>
          - mkacoustic.vn không thu thập thông tin khách hàng qua trang web,
          thông tin cá nhân khách hàng được thực hiện thu thập qua email liên hệ
          đặt mua sản phẩm, dịch vụ gửi về hộp mail của chúng tôi:
          appffevn@gmail.com hoặc số điện thoại liên hệ đặt mua sản phẩm gọi về
          0853511111
        </div>
        <div>
          - Bạn có thể liên hệ địa chỉ email cùng số điện thoại trên để yêu cầu
          ffe.vn chỉnh sửa dữ liệu cá nhân của mình.
        </div>
        <div className="fw-semibold pt-1">
          7. Cơ chế tiếp nhận và giải quyết khiếu nại của người tiêu dùng liên
          quan đến việc thông tin cá nhân bị sử dụng sai mục đích hoặc phạm vi
          đã thông báo.
        </div>
        <div>
          Tại ffe.vn, việc bảo vệ thông tin cá nhân của bạn là rất quan trọng,
          bạn được đảm bảo rằng thông tin cung cấp cho chúng tôi sẽ được bảo mật
          ffe.vn cam kết không chia sẻ, bán hoặc cho thuê thông tin cá nhân của
          bạn cho bất kỳ người nào khác. Ffe.vn cam kết chỉ sử dụng các thông
          tin của bạn vào các trường hợp sau:
        </div>
        <div>- Nâng cao chất lượng dịch vụ dành cho khách hàng</div>
        <div>- Giải quyết các tranh chấp, khiếu nại</div>
        <div>- Khi cơ quan pháp luật có yêu cầu.</div>
        <div className="pt-1">
          mkacoustic.vn hiểu rằng quyền lợi của bạn trong việc bảo vệ thông tin
          cá nhân cũng chính là trách nhiệm của chúng tôi nên trong bất kỳ
          trường hợp có thắc mắc, góp ý nào liên quan đến chính sách bảo mật của
          ffe.vn, và liên quan đến việc thông tin cá nhân bị sử dụng sai mục
          đích hoặc phạm vi đã thông báo vui lòng liên hệ qua:
        </div>
        <div>Hotline: 0853511111</div>
        <div>Email: appffevn@gmail.com</div>
      </div>
    </div>
  );
}

export default InformationSecurityPolicy;
