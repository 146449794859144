import { Card } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HomeImages from "src/assets/Images/Home";
import formatNumber from "src/common/FormatNumber";
import { getProducts } from "src/components/Features/home/homeSlice";
import { useAppDispatch } from "src/components/Redux/store";
import { ListTitleProductHome } from "src/constants/Array";
import { API_URL } from "src/controllers/Client";
import NavBar from "../Navbar";

function AllProduct() {
  const { state } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [dataProducts, setDataProducts] = useState([]);
  const [currentListProduct, setCurrentListProduct] = useState(state?.index);

  useEffect(() => {
    getProduct(state?.productType);
  }, []);

  const getProduct = async (type: string) => {
    const params = { limit: 1000 };
    const paramsSearch = {
      search: {
        productType: type,
      },
      limit: 1000,
    };
    const resp: any = await dispatch(getProducts(type ? paramsSearch : params));
    console.log("resp product 123---", resp);
    const data = resp?.payload?.data;
    setDataProducts(data);
  };

  return (
    <div className="container-home">
      <NavBar />
      <div className="container-xxl d-flex gap-2 container-body-product">
        <div
          className="bg-white p-3 rounded d-none d-sm-block"
          style={{ height: 350 }}
        >
          <div className="txt-title-left">Danh mục</div>
          <div className="d-flex flex-column align-items-start">
            {ListTitleProductHome.map((item, index) => {
              let checked = false;
              if (index === currentListProduct) {
                checked = true;
              }
              return (
                <button
                  onClick={() => {
                    getProduct(item?.type);
                    setCurrentListProduct(index);
                  }}
                  className={
                    checked ? "btn-left-product" : "btn-left-block-product"
                  }
                  key={item?.id}
                >
                  {item?.text}
                </button>
              );
            })}
          </div>
        </div>
        <div className="bg-white pb-3 rounded w-100 mb-5">
          <div className="p-3">
            <div className="txt-title-left">
              {ListTitleProductHome[currentListProduct]?.text}
            </div>
          </div>
          {dataProducts.map((item: any, index) => {
            const findVideo = item?.images?.find(
              (x: { includes: (arg0: string) => any }) => !x?.includes(".mp4")
            );
            return (
              <Card
                key={item?.productID}
                onClick={() =>
                  navigate(`/product/detail?id=${item?.productID}`, {
                    state: { productID: item?.productID },
                  })
                }
                hoverable
                className=" btn mt-4 p-0 card-items shadow-sm"
                cover={
                  <img
                    src={
                      findVideo
                        ? `${API_URL}${findVideo}`
                        : HomeImages.logo_product
                    }
                    className="img-product"
                  />
                }
              >
                <div
                  className="txt-item-product d-block d-md-none"
                  style={{
                    fontSize: 14,
                  }}
                >
                  {item?.productName.substring(0, 14) + "..."}
                </div>
                <div className="txt-item-product d-none d-md-block">
                  {item?.productName.substring(0, 30) + "..."}
                </div>
                {item?.priceCompare > 0 && (
                  <div className="txt-price-compare">
                    {formatNumber(item?.priceCompare)}đ
                  </div>
                )}
                <div className="txt-price">
                  {formatNumber(item?.price + item?.priceVocher)}đ
                </div>
                <div className="txt-sold">Đã bán: {item?.sold}</div>
              </Card>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default AllProduct;
