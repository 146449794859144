import React from "react";
import NavBar from "../Navbar";
import { useSelector } from "react-redux";
import { userInfor } from "src/components/Redux/selector";

function ShippingPolicy() {
  const user = useSelector(userInfor);

  return (
    <div>
      {!user && <NavBar />}
      <div className="container bg-white rounded-1 p-3 d-flex flex-column">
        <div className="py-3 fw-semibold fs-4 text-center">
          Chính sách vận chuyển
        </div>
        <div className="fw-semibold">1. Đối tượng áp dụng chính sách</div>
        <div>
          Chính sách giao hàng và lắp đặt được áp dụng đối với tất cả các khách
          hàng mua hàng tại: Công Ty Cổ Phần Sản Xuất Và Thương Mại Ffe Grou
        </div>

        <div className="pt-1 fw-semibold">2. Thời gian giao hàng</div>
        <div>
          - Đơn hàng trong nội tỉnh: vận chuyển và giao hàng trong vòng 48 giờ
          hoặc theo yêu cầu của khách hàng.
        </div>
        <div>
          - Chính sách áp dụng khi đặt hàng từ 8 – 15h. Trường hợp đặt hàng sau
          17H, chúng tôi sẽ chuyển hàng vào ngày hôm sau hoặc theo yêu cầu của
          khách hàng
        </div>
        <div>
          - Bộ phận Giao vận sẽ liên lạc trước để Quý khách sắp xếp thời gian,
          địa điểm cụ thể để giao hàng cho Quý khách.
        </div>
        <div className="pt-1 fw-semibold">
          3. Trách nhiệm với hàng hóa vận chuyển
        </div>
        <div>
          - Quý khách vui lòng trực tiếp kiểm tra kỹ hàng hoá ngay khi nhận hàng
          từ nhân viên giao hàng, nếu có vấn đề liên quan tới chủng loại, mẫu
          mã, chất lượng, số lượng hàng hoá không đúng như trong đơn đặt hàng,
          Quý khách vui lòng báo ngay cho chúng tôi để phối hợp xử lý. Nếu không
          có bất cứ vấn đề gì, Quý khách vui lòng nhận hàng và ký phiếu giao
          hàng.
        </div>
        <div>
          - Sau khi quý khách đã ký nhận bàn giao hàng mà không ghi chú hoặc có
          ý kiến về hàng hóa, công ty sẽ không có trách nhiệm với những yêu cầu
          đổi trả vì hư hỏng, trầy xước, bể vỡ, mốp méo, sai hàng hóa, vv… từ
          quý khách sau này.
        </div>
        <div>
          - Quý khách nhận hàng, ký vào phiếu giao hàng và thanh toán cho nhân
          viên giao nhận toàn bộ hoặc một phần (nếu đã đặt cọc) giá trị hàng hóa
          đã mua (bao gồm giá trị tiền hàng + phí vận chuyển (nếu có) + phí lắp
          đặt (nếu có)). Sau khi quý khách đã ký nhận bàn giao hàng mà không ghi
          chú hoặc có ý kiến về hàng hóa, Công Ty sẽ không có trách nhiệm với
          những yêu cầu đổi trả vì hư hỏng, trầy xước, bể vỡ, mốp méo, sai hàng
          hóa, vv… từ quý khách sau này.
        </div>
      </div>
    </div>
  );
}

export default ShippingPolicy;
