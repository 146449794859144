import React, { memo, useEffect, useState } from "react";
import {
  cartUpdate,
  getCardList,
  orderCreate,
} from "src/components/Features/cart/cartSlice";
import { useAppDispatch } from "src/components/Redux/store";
import "./BuyProduct.css";
import { getAddress } from "src/components/Features/user/userSlice";
import formatNumber from "src/common/FormatNumber";
import ProductImages from "src/assets/Images/Product";
import { listBankDeposit } from "src/components/Features/wallet/walletSlice";
import useGetProfile from "src/hooks/useGetProfile";
import { toast } from "react-toastify";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  ExclamationOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import ModalAddress from "src/components/Modals/ModalAddress";
import ModalAddAddress from "src/components/Modals/ModalAddAddress";
import { API_URL } from "src/controllers/Client";
import { CartProps } from "src/interface";

const BuyProduct = () => {
  const { state } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userInfo: any = useGetProfile();
  const [dataCart, setDataCart] = useState<any>([]);
  const [totalMoney, setTotalMoney] = useState(0);
  const [defaultAddress, setDefaultAddress] = useState<any>(null);
  const [dataBank, setDataBank] = useState<any>([]);
  const [typePayment, setTypePayment] = useState("1");
  const [totalNumberCart, setTotalNumberCart] = useState(0);
  const [shippingNote, setShippingNote] = useState("");
  const [openAddress, setOpenAddress] = useState(false);
  const [openAddAddress, setOpenAddAddress] = useState(false);
  const [chooseProvince, setChooseProvince] = useState<any>(null);
  const [chooseDistrict, setChooseDistrict] = useState<any>(null);
  const [chooseWard, setChooseWard] = useState<any>(null);
  const [address, setAddress] = useState("");
  const [numberProduct, setNumberProduct] = useState(state?.numberProduct);

  useEffect(() => {
    if (!state?.item) {
      getData();
    } else {
      setDataCart([state?.item]);
    }
  }, []);

  useEffect(() => {
    getBank();
    getUserAddress();
  }, []);

  const getData = async () => {
    const resp: any = await dispatch(getCardList());
    console.log("resp all cart---", resp);
    if (resp.payload.status === true) {
      const data = resp?.payload?.carts?.products;
      const filterByTotalMoney = data
        .map(
          (i: any) =>
            (i?.product_id?.price + i?.product_id?.priceVocher) * i?.quantity
        )
        .reduce((a: number, c: number) => a + c);

      const filterByTotalNumberCart = data
        .map((i: any) => i?.quantity)
        .reduce((a: number, c: number) => a + c);
      setTotalNumberCart(filterByTotalNumberCart);
      setTotalMoney(filterByTotalMoney);
      setDataCart(data);
    }
  };

  const getUserAddress = async () => {
    const resp: any = await dispatch(getAddress());
    if (resp.payload.status === true) {
      const data = resp.payload.data || [];
      if (data.length === 0) {
        setDefaultAddress(null);
      } else {
        console.log("resp address---", resp);
        const findByAddress = data.find(
          (address: { isDefault: boolean }) => address.isDefault === true
        );
        setDefaultAddress(findByAddress || data[0]);
      }
    }
  };

  const getBank = async () => {
    const resp: any = await dispatch(listBankDeposit({}));
    console.log("resp bank----", resp);
    if (resp.payload.status === true) {
      const data = resp.payload.banks || [];
      setDataBank(data);
    }
  };

  const onSubmit = async () => {
    if (!defaultAddress) {
      toast.error("Vui lòng thêm địa chỉ nhận hàng !");
    } else {
      const buyData: any = [
        {
          productID: state?.item?.productID,
          productName: state?.item?.productName,
          price: state?.item?.price + state?.item?.priceVocher,
          quantity: numberProduct,
          discount: 0,
        },
      ];

      const orderData = state?.item
        ? buyData
        : dataCart.map((i: any) => {
            return {
              productID: i?.product_id?.productID,
              productName: i?.product_id?.productName,
              price: i?.product_id?.price + i?.product_id?.priceVocher,
              quantity: i?.quantity,
              discount: 0,
            };
          });
      const params = {
        coupon: "",
        shippingName: defaultAddress?.name,
        shippingPhoneNumber: defaultAddress?.phone,
        shippingAddress: defaultAddress?.address,
        shippingProvinceName: defaultAddress?.provinceName,
        shippingDistrictName: defaultAddress?.districtName,
        shippingWardName: defaultAddress?.wardName,
        shippingNote: shippingNote,
        paymentMethod: typePayment === "1" ? "Wallet" : "Bank",
        fee: 0,
        orderDetails: orderData,
        bankConfigID: dataBank[0]?.bankConfigID,
      };
      dispatch(orderCreate(params))
        .then((resp: any) => {
          // console.log("resp buy product----", resp);
          if (resp.payload.status) {
            if (typePayment === "2") {
              navigate("/qr-payment", { state: { data: resp?.payload } });
            } else {
              navigate("/payment-success");
            }
          }
        })
        .catch((err: any) => {});
    }
  };

  const onUpdate = async (type: string, item: CartProps) => {
    const params = {
      productID: item?.productID,
      quantity: type === "minus" ? item?.quantity - 1 : item?.quantity + 1,
    };
    const resp: any = await dispatch(cartUpdate(params));
    // console.log('resp update---', resp);
    if (resp.payload.status) {
      getData();
      if (dataCart.length === 1) {
        setDataCart([]);
        setTotalNumberCart(0);
        setTotalMoney(0);
      }
    }
  };
  return (
    <div className="container-home">
      <div className="container-xxl pt-5">
        <div className="col col-lg-12 row gap-2">
          <div className=" col-lg-8 bg-white p-3 rounded-1 mb-5">
            <div className="txt-user-location">Địa chỉ nhận hàng</div>
            {defaultAddress ? (
              <div className="py-4 d-flex justify-content-between">
                <div>
                  <div className="d-flex align-items-center">
                    <div className="txt-name-address">
                      {defaultAddress?.name}
                    </div>
                    <div className="txt-name-address mx-1">•</div>
                    <div className="txt-name-address">
                      {defaultAddress?.phone}
                    </div>
                  </div>
                  <div className="txt-des-address pt-1">
                    {defaultAddress?.address},{defaultAddress?.wardName},
                    {defaultAddress?.districtName},
                    {defaultAddress?.provinceName}
                  </div>
                </div>
                <button
                  onClick={() => setOpenAddress(true)}
                  className="btn-edit-address"
                >
                  Sửa
                </button>
              </div>
            ) : (
              <button
                className="py-4 btn border border-0 p-0 fs-5 fw-semibold text-danger"
                onClick={() => setOpenAddAddress(true)}
              >
                Thiết lập địa chỉ <ArrowRightOutlined />
              </button>
            )}
            <div className="w-100 border" />
            <div className="txt-user-location py-3">Sản phẩm</div>
            <div className="w-100 border" />
            <div className="d-none d-md-block">
              <div className="py-3 row col col-lg-12">
                <div className="txt-ul col col-lg-4">Sản phẩm</div>
                <div className="txt-ul col col-lg-3">Đơn giá</div>
                <div className="txt-ul col col-lg-3 text-center">Số lượng</div>
                <div className="txt-ul col col-lg-2">Thành tiền</div>
              </div>
              {dataCart.map((item: any) => {
                const findVideo = item?.product_id
                  ? item?.product_id?.images?.find(
                      (x: { includes: (arg0: string) => any }) =>
                        !x?.includes(".mp4")
                    )
                  : item?.images?.find(
                      (x: { includes: (arg0: string) => any }) =>
                        !x?.includes(".mp4")
                    );
                return (
                  <div className="mb-2 row col col-lg-12" key={item?.productID}>
                    <div className="d-flex col col-lg-4">
                      <img
                        src={`${API_URL}${findVideo}`}
                        width={92}
                        height={92}
                        style={{
                          minWidth: 92,
                        }}
                      />
                      <div className="ps-3 d-none d-md-block txt-product-name">
                        {item?.product_id?.productName
                          ? item?.product_id?.productName
                          : item?.productName}
                      </div>
                    </div>
                    <div className="col col-lg-3">
                      <div>
                        {item?.product_id
                          ? formatNumber(
                              item?.product_id?.price +
                                item?.product_id?.priceVocher
                            )
                          : formatNumber(item?.price + item?.priceVocher)}
                        đ
                      </div>
                      <div className="text-warning">
                        {item?.product_id
                          ? `${formatNumber(
                              item?.product_id?.priceVocher
                            )} đ (giá Voucher)`
                          : `${formatNumber(
                              item?.priceVocher
                            )} đ (giá Voucher)`}
                      </div>
                    </div>
                    {/* <div className="col col-lg-2">{item?.quantity}</div> */}
                    <div className="d-flex justify-content-center align-items-center gap-2 col col-lg-3">
                      <button
                        className="btn border border-0 p-0 fw-semibold fs-3"
                        onClick={() => {
                          item?.quantity
                            ? onUpdate("minus", item)
                            : setNumberProduct(+numberProduct - 1);
                        }}
                        disabled={item?.quantity ? false : numberProduct === 1}
                      >
                        -
                      </button>
                      <input
                        type="text"
                        className="input-add-number"
                        value={item?.quantity ? item?.quantity : numberProduct}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        style={{
                          height: 36,
                        }}
                      />
                      <button
                        className="btn border border-0 p-0 fw-semibold fs-3"
                        onClick={() => {
                          item?.quantity
                            ? onUpdate("add", item)
                            : setNumberProduct(+numberProduct + 1);
                        }}
                      >
                        +
                      </button>
                    </div>
                    <div className="col col-lg-2 txt-total-price">
                      {item?.product_id
                        ? `${formatNumber(
                            (item?.product_id?.price +
                              item?.product_id?.priceVocher) *
                              item?.quantity
                          )}đ`
                        : `${formatNumber(
                            (item?.price + item?.priceVocher) * numberProduct
                          )}đ`}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="d-block d-md-none">
              <div>
                {dataCart.map((item: any, index: number) => {
                  const findVideo = item?.product_id
                    ? item?.product_id?.images?.find(
                        (x: { includes: (arg0: string) => any }) =>
                          !x?.includes(".mp4")
                      )
                    : item?.images?.find(
                        (x: { includes: (arg0: string) => any }) =>
                          !x?.includes(".mp4")
                      );
                  return (
                    <div key={`${index}-${item?.productID}`}>
                      <div className="d-flex justify-content-between pt-2">
                        <div className="txt-ul">Sản phẩm:</div>
                        <div className="d-flex flex-column align-items-end">
                          <img
                            src={`${API_URL}${findVideo}`}
                            width={92}
                            height={92}
                            style={{
                              minWidth: 92,
                            }}
                          />
                          <div className="ps-3 txt-product-name pt-1">
                            {item?.product_id?.productName
                              ? item?.product_id?.productName
                              : item?.productName}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between pt-2">
                        <div className="txt-ul col col-lg-2">Đơn giá:</div>
                        <div className="txt-ul">
                          {item?.product_id
                            ? formatNumber(
                                item?.product_id?.price +
                                  item?.product_id?.priceVocher
                              )
                            : formatNumber(item?.price + item?.priceVocher)}
                          đ
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center pt-2">
                        <div className="txt-ul col col-lg-3">Số lượng:</div>
                        <div className="d-flex align-items-center gap-2 mt-2">
                          <button
                            className="btn border border-0 p-0 fw-semibold fs-3"
                            onClick={() => {
                              item?.quantity
                                ? onUpdate("minus", item)
                                : setNumberProduct(+numberProduct - 1);
                            }}
                            disabled={
                              item?.quantity ? false : numberProduct === 1
                            }
                          >
                            -
                          </button>
                          <input
                            type="text"
                            className="input-add-number"
                            value={
                              item?.quantity ? item?.quantity : numberProduct
                            }
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            style={{
                              height: 36,
                            }}
                          />
                          <button
                            className="btn border border-0 p-0 fw-semibold fs-3"
                            onClick={() => {
                              item?.quantity
                                ? onUpdate("add", item)
                                : setNumberProduct(+numberProduct + 1);
                            }}
                          >
                            +
                          </button>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between pt-2">
                        <div className="txt-ul col col-lg-2">Thành tiền:</div>
                        <div className="txt-total-price">
                          {item?.product_id
                            ? `${formatNumber(
                                (item?.product_id?.price +
                                  item?.product_id?.priceVocher) *
                                  item?.quantity
                              )}đ`
                            : `${formatNumber(
                                (item?.price + item?.priceVocher) *
                                  numberProduct
                              )}đ`}
                        </div>
                      </div>
                      <div className="w-100 border my-2" />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="w-100 border" />
            <div className="txt-user-location py-3">Phương thức thanh toán</div>
            <div className="w-100 border" />
            <div className="py-3 row gap-3 px-2">
              <button
                className="col btn-choose-wallet d-flex flex-column"
                onClick={() => setTypePayment("1")}
              >
                <div className="d-flex align-items-center">
                  <div className="div-circle border border-dark">
                    {typePayment === "1" && (
                      <div className="div-inner-circle" />
                    )}
                  </div>
                  <div className="d-flex ps-3">
                    <img
                      src={ProductImages.account_balance_wallet}
                      width={24}
                      height={24}
                      alt=""
                    />
                    <div className="txt-title-choose-button">
                      Thanh toán bằng ví cá nhân
                    </div>
                  </div>
                </div>

                <div className="d-flex align-items-center pt-2">
                  <div>Số dư:</div>
                  <div className="txt-total-wallet-money">
                    {formatNumber(userInfo?.data?.walletInfo?.accountBalance)}đ
                  </div>
                </div>
              </button>
              {/* <button
                className="col btn-choose-wallet"
                onClick={() => setTypePayment("2")}
              >
                <div className="d-flex align-items-center">
                  <div className="div-circle border border-dark">
                    {typePayment === "2" && (
                      <div className="div-inner-circle" />
                    )}
                  </div>
                  <div className="d-flex ps-3">
                    <img
                      src={ProductImages.account_balance}
                      width={24}
                      height={24}
                      alt=""
                    />
                    <div className="txt-title-choose-button">
                      Thanh toán qua chuyển khoản ngân hàng
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center  p-3">
                  <img src={dataBank[0]?.logo} width={80} height={30} />
                  <div>
                    <div className="txt-bank-name">
                      {dataBank[0]?.bankShortName}
                    </div>
                    <div className="txt-bank-code">{dataBank[0]?.bankName}</div>
                  </div>
                </div>
              </button> */}
            </div>
            <div className="w-100 border" />
            <div className="txt-user-location py-3">Ghi chú</div>
            <div className="w-100 border" />
            <input
              type="text"
              className="w-100 border my-4 rounded-1 ps-2 py-4"
              placeholder="Ghi chú cho công ty"
              value={shippingNote}
              onChange={(text) => setShippingNote(text.target.value)}
            />
          </div>
          <div
            className=" col-lg-3 bg-white p-3 rounded-1"
            style={{
              height: 370,
            }}
          >
            <div className="txt-user-location">Tạm tính</div>
            <div className="txt-number-product-right pt-4">Số sản phẩm</div>
            <div className="txt-number-right">{dataCart?.length}</div>
            <div className="txt-number-product-right pt-3">Tổng số lượng</div>
            <div className="txt-number-right">
              {totalNumberCart ? totalNumberCart : numberProduct}
            </div>
            <div className="txt-number-product-right pt-3">Tổng thanh toán</div>
            <div className="txt-total-all-price">
              {totalMoney
                ? `${formatNumber(totalMoney)}đ`
                : `${formatNumber(
                    (state?.item?.price + state?.item?.priceVocher) *
                      numberProduct
                  )}`}
            </div>
            <button className="btn-buy-product w-100 mt-5" onClick={onSubmit}>
              Đặt hàng
            </button>
          </div>
        </div>
      </div>

      <ModalAddress
        isOpen={openAddress}
        isClose={() => setOpenAddress(false)}
        onAddAddress={() => {
          setOpenAddress(false);
          setOpenAddAddress(true);
        }}
        chooseAddress={(value: any) => {
          setDefaultAddress(value);
          setOpenAddress(false);
        }}
        isSubmit={() => getUserAddress()}
      />

      <ModalAddAddress
        isOpen={openAddAddress}
        isClose={() => setOpenAddAddress(false)}
        province={(value: any) => setChooseProvince(value)}
        district={(value: any) => setChooseDistrict(value)}
        ward={(value: any) => setChooseWard(value)}
        chooseAddress={(text: string) => setAddress(text)}
        isSubmit={() => getUserAddress()}
      />
    </div>
  );
};

export default memo(BuyProduct);
