import { Input, Modal, Select } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import formatNumber, { formatMoneyText } from "src/common/FormatNumber";
import { accumulativeCreate } from "src/components/Features/accumulative/accumulativeSlice";
import { setNavigateProfile } from "src/components/Features/user/userSlice";
import { useAppDispatch } from "src/components/Redux/store";
import useGetProfile from "src/hooks/useGetProfile";

const CreateAccmulation = (props: any) => {
  const { isOpen, isClose, data, onUpdate } = props;
  const isUserData: any = useGetProfile();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [chooseAccumulation, setChooseAccumulation] = useState<any>(null);
  const [money, setMoney] = useState("");
  const mapData = data?.map(
    (data: { _id: string; mounth: number; interestRate: number }) => {
      return {
        value: data?._id,
        label:
          data?.mounth +
          " " +
          "tháng" +
          " " +
          data?.interestRate +
          "%" +
          "/" +
          "tháng",
        ...data,
      };
    }
  );

  const onSubmit = async () => {
    const params = {
      interestRateConfigID: chooseAccumulation?.interestRateConfigID,
      amount: money.split(".").join(""),
      autoRenew: true,
    };
    const resp: any = await dispatch(accumulativeCreate(params));
    console.log("resp tich luy---", resp);

    if (resp.payload.status === true) {
      onUpdate();
      toast.success("Tạo tích luỹ thành công !");
    }
  };

  return (
    <Modal
      title="Tạo tích lũy"
      open={isOpen}
      footer={null}
      closeIcon={null}
      onCancel={() => isClose()}
      width={480}
    >
      <div className="title-input pt-3 d-flex">
        Chọn ngân hàng của bạn <div className="text-danger ps-1"> *</div>
      </div>
      <Select
        showSearch={false}
        style={{ width: "100%", height: 40 }}
        className="mt-1"
        placeholder={"Chọn kỳ hạn"}
        optionFilterProp="children"
        onChange={(value, opt) => setChooseAccumulation(opt)}
        filterOption={(input, option: any) =>
          (option?.label ?? "").includes(input)
        }
        filterSort={(optionA: any, optionB: any) =>
          (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase())
        }
        options={mapData}
      />

      <div className="title-input pt-3 d-flex">
        Số tiền tích lũy <div className="text-danger ps-1"> *</div>
      </div>
      <Input
        placeholder={"Nhập số tiền"}
        className="mt-1"
        value={money}
        onChange={(e) => setMoney(formatMoneyText(e.target.value))}
        style={{
          height: 40,
        }}
      />

      <div
        className="p-3 rounded-3 mt-3"
        style={{
          background: "#FDEDEA",
        }}
      >
        <div className="d-flex gap-2">
          <div className="fw-semibold">Số dư ví khả dụng</div>
          <div className="text-danger fw-semibold">
            {formatNumber(isUserData?.data?.walletInfo?.accountBalance)}đ
          </div>
        </div>
        <button
          className="btn border border-0 p-0 text-primary text-decoration-underline"
          onClick={() => {
            dispatch(setNavigateProfile(false));
            navigate("/guide");
          }}
        >
          Hướng dẫn nạp - rút tiền tích lũy & lợi nhuận
        </button>
      </div>
      <div className="d-flex justify-content-end mt-4 gap-2">
        <button className="btn-close-recharge" onClick={() => isClose()}>
          Hủy
        </button>
        <button className="btn-submit-recharge" onClick={onSubmit}>
          Tạo tích luỹ
        </button>
      </div>
    </Modal>
  );
};

export default CreateAccmulation;
