import { CartProps } from 'src/interface';
import client from './Client';
import { toast } from 'react-toastify';

const Cart = {
  async cartAdd(params: CartProps) {
    try {
      const res = await client.post(`/api/cart-add`, params);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async getCardList() {
    try {
      const res = await client.get(`/api/cart-list`);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async cartUpdate(params: CartProps) {
    try {
      const res = await client.post(`/api/cart-update`, params);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async orderCreate(params: any) {
    try {
      const res = await client.post(`/api/order/create`, params);
      return res;
    } catch (err) {
      toast.error(err?.message);
    }
  },
};

export default Cart;
