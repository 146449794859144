import React from "react";
import "./Footer.css";
import FooterImages from "src/assets/Images/Footer";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "src/components/Redux/store";
import { setNavigateProfile } from "src/components/Features/user/userSlice";

const Footer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  return (
    <div className="border-top border-2 border-danger">
      <div className="container-xxl">
        <div className="row col-md-12 py-5">
          <div className="col-md-4 item-footer">
            <div className="txt-title pt-2">Liên hệ</div>
            <div className="txt-title py-3">
              CÔNG TY CỔ PHẦN SẢN XUẤT VÀ THƯƠNG MẠI FFE GROUP
            </div>
            <div className="txt-content">Giấy phép ĐKKD số 0110250059</div>
            <div className="txt-content py-2">Cấp ngày: 13/02/2023</div>
            <div className="txt-content">
              A11 Ngõ 17 P. Lê Văn Thiêm, Nhân Chính, Thanh Xuân, Hà Nội, Việt
              Nam
            </div>
            <div className="txt-content py-2">Email: appffevn@gmail.com</div>
            <div className="txt-content">Hotline: 085 3511 111</div>
          </div>
          <div className="col-md-2 d-flex flex-column align-items-start item-footer">
            <div className="txt-title pt-2">Về FFE</div>
            <button className="txt-content pt-2">Liên hệ với FFE</button>
            {/* <button
              className="txt-content py-2"
              onClick={() => {
                dispatch(setNavigateProfile(false));
                navigate("/guide-new");
              }}
            >
              Hướng dẫn người mới
            </button>
            <button
              className="txt-content"
              onClick={() => {
                dispatch(setNavigateProfile(false));
                navigate("/guide");
              }}
            >
              Hướng dẫn nạp và rút tiền
            </button> */}
            <div className="txt-title py-3">Theo dõi chúng tôi</div>
            <button className="txt-content">Facebook</button>
            <button className="txt-content pt-2">Youtube</button>
          </div>
          <div className="col-md-3 item-footer d-flex flex-column align-items-start">
            <div className="txt-title pt-2">Chính sách</div>
            <button
              onClick={() => navigate("/complaint-policy")}
              className="txt-content pt-2"
            >
              Chính sách xử lý khiếu nại
            </button>
            <button
              onClick={() => navigate("/warranty-policy")}
              className="txt-content py-2"
            >
              Chính sách bảo hành
            </button>
            <button
              onClick={() => navigate("/return-policy")}
              className="txt-content"
            >
              Chính sách đổi trả hàng
            </button>
            <button
              onClick={() => navigate("/payment-policy")}
              className="txt-content py-2"
            >
              Chính sách thanh toán
            </button>
            <button
              onClick={() => navigate("/shipping-policy")}
              className="txt-content"
            >
              Chính sách vận chuyển
            </button>
            <button
              onClick={() => navigate("/information-security-policy")}
              className="txt-content pt-2"
            >
              Chính sách bảo mật thông tin
            </button>
          </div>
          <div className="col-md-3 item-footer">
            <div className="txt-title pt-2">Tải ứng dụng FFE</div>
            <button
              className="btn border border-0 p-0 me-2"
              onClick={() =>
                window
                  .open(
                    `https://apps.apple.com/vn/app/ff-ecom/id6479212468`,
                    "_blank"
                  )
                  ?.focus()
              }
            >
              <img
                src={FooterImages.apple}
                className="my-2"
                width={180}
                height={52}
              />
            </button>
            <button
              className="btn border border-0 p-0"
              onClick={() =>
                window
                  .open(
                    `https://drive.google.com/file/d/1oykjKuYDS_mHmARAsOeCxk1Q5e_oAgUx/view`,
                    "_blank"
                  )
                  ?.focus()
              }
            >
              <img src={FooterImages.google} width={180} height={52} />
            </button>
          </div>
        </div>
        {/* <div>
          <div className="txt-title">
            Sàn thương mại điện tử – Khởi nghiệp cùng FFE
          </div>
          <div className="txt-content pt-2">
            FFE Group là Công ty được thành lập bởi các thành viên có kinh
            nghiệm nhiều năm trong ngành Tư vấn – Bất động sản – Tài chính - Sức
            khỏe và Làm đẹp. FFE Group đã phát triển từ một văn phòng nhỏ thành
            một chuỗi các văn phòng, đội nhóm trên toàn quốc, hỗ trợ nhiều Nhà
            phân phối và các Nhà đầu tư các dự án uy tín. Từ những kinh nghiệm
            đó, FFE Group luôn có những sản phẩm và dịch vụ tốt nhất tư vấn, hỗ
            trợ cho các đối tác, khách hàng đạt được mục tiêu cả về tài chính và
            các giá trị tinh thần lâu dài.
          </div>
          <div className="text-center txt-content py-5">
            Copyright © 2023 by FFE. All rights reserved.
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Footer;
