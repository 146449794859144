import client from './Client';

const Product = {
  async getProductDetail(id: number | string) {
    try {
      const res = await client.get(`/api/products/detail/${id}`);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async getListOrder(params: any) {
    try {
      const res = await client.post(`/api/order/list`, params);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async orderCancel(params: any) {
    try {
      const res = await client.post(`/api/order/cancel`, params);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },
};

export default Product;
