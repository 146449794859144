import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HomeImages from "src/assets/Images/Home";
import { userInfor } from "src/components/Redux/selector";
import { FuncProfile } from "src/constants/Array";
import useGetProfile from "src/hooks/useGetProfile";

const ListLeftProfile = () => {
  const navigate = useNavigate();
  const isUserData: any = useGetProfile();
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNavigate = (id: number) => {
    setCurrentIndex(id);
    switch (id) {
      case 0:
        navigate("/profile");
        break;
      case 1:
        navigate("/my-bank");
        break;
      case 2:
        navigate("/my-address");
        break;
      case 3:
        navigate("/list-order");
        break;
      case 4:
        navigate("/wallet");
        break;
      // case 5:
      //   navigate("/accumulation");
      //   break;
      case 5:
        navigate("/share");
        break;
      default:
        break;
    }
  };

  return (
    <div
      className="bg-white py-4 px-3 d-flex flex-column align-items-center col-md-3 rounded mt-5"
      style={{
        height: 600,
      }}
    >
      <img
        src={HomeImages.logo_product}
        width={80}
        height={80}
        style={{ borderRadius: 80 }}
      />
      <div className="txt-name pt-2 pb-3">
        Xin chào, {isUserData?.data?.fullName}
      </div>
      {FuncProfile.map((item, index) => {
        let checked = false;
        if (currentIndex === index) {
          checked = true;
        }
        return (
          <button
            key={item.id}
            className={checked ? "btn-item py-3" : "btn-item-choose py-3"}
            onClick={() => handleNavigate(index)}
          >
            <img src={item.icon} width={24} height={24} className="me-2" />
            {item.text}
          </button>
        );
      })}
    </div>
  );
};

export default ListLeftProfile;
