import { User } from "src/interface";
import client from "./Client";
import { toast } from "react-toastify";

const Users = {
  async login(params: User) {
    try {
      const res = await client.post(`/api/user/login`, params);
      return res;
    } catch (err) {
      toast.error(err?.message);
    }
  },

  async register(params: User) {
    try {
      const res = await client.post(`/api/user/sign-in`, params);
      return res;
    } catch (err) {
      toast.error(err?.message);
    }
  },

  async deleteAccount(params: User) {
    try {
      const res = await client.post(`api/user/delete-account`, params);
      return res;
    } catch (err) {
      toast.error(err?.message);
    }
  },

  async verifyEmail(params: User) {
    try {
      const res = await client.post(`/api/user/verify-email-sign-in`, params);
      return res;
    } catch (err) {
      toast.error(err?.message);
    }
  },

  async verifyOTP(params: User) {
    try {
      const res = await client.post(`/api/user/confirm-otp`, params);
      return res;
    } catch (err) {
      toast.error(err?.message);
    }
  },

  async restPassword(params: User) {
    try {
      const res = await client.post(`/api/user/verify-email`, params);
      return res;
    } catch (err) {
      toast.error(err?.message);
    }
  },

  async addAddress(params: User) {
    try {
      const res = await client.post(`/users/add-address`, params);
      return res;
    } catch (err) {
      toast.error(err?.message);
    }
  },

  async getProfile() {
    try {
      const res = await client.get(`api/user/profile-detail`);
      return res;
    } catch (err) {
      toast.error(err?.message);
    }
  },

  async updateProfile(params: User) {
    try {
      const res = await client.post(`/api/user/profile-update`, params);
      return res;
    } catch (err) {
      toast.error(err?.message);
    }
  },

  async changePassword(params: User) {
    try {
      const res = await client.post(`/api/user/change-password`, params);
      return res;
    } catch (err) {
      toast.error(err?.message);
    }
  },

  async resetPassword(params: User) {
    try {
      const res = await client.post(`/api/user/change-password`, params);
      return res;
    } catch (err) {
      toast.error(err?.message);
    }
  },

  async getAddress() {
    try {
      const res = await client.get(`/api/user/address-list`);
      return res;
    } catch (err) {
      toast.error(err?.message);
    }
  },

  async createAddress(params: any) {
    try {
      const res = await client.post(`/api/user/address-create`, params);
      return res;
    } catch (err) {
      toast.error(err?.message);
    }
  },

  async getListBank() {
    try {
      const res = await client.get(`/api/user/bank-list`);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async createBank(params: any) {
    try {
      const res = await client.post(`/api/user/bank-create`, params);
      return res;
    } catch (err) {
      toast.error(err?.message);
    }
  },

  async attendance() {
    try {
      const res = await client.post(`/api/user/daily-roll-call`);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async miningCoinStart() {
    try {
      const res = await client.post(`/api/mining-coin/start`);
      return res;
    } catch (err) {
      toast.error(err?.message);
    }
  },

  async miningCoinEnd() {
    try {
      const res = await client.post(`/api/mining-coin/end`);
      return res;
    } catch (err) {
      toast.error(err?.message);
    }
  },

  async reportGereral() {
    try {
      const res = await client.post(`/api/report/general`);
      return res;
    } catch (err) {
      toast.error(err?.message);
    }
  },

  async miningCoinList() {
    try {
      const res = await client.post(`/api/mining-coin/list`);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async teamModel() {
    try {
      const res = await client.post(`/api/user/team-model`);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async uploadImage(params: any) {
    try {
      const res = await client.post(`/upload/image`, params, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res;
    } catch (err) {
      toast.error(err?.message);
    }
  },

  async updateBank(params: any) {
    try {
      const res = await client.post(`/api/user/bank-update`, params);
      return res;
    } catch (err) {
      toast.error(err?.message);
    }
  },

  async deleteBank(id: string | number) {
    try {
      const res = await client.post(`/api/user/bank-delete`, {
        userBankID: id,
      });
      return res;
    } catch (err) {
      toast.error(err?.message);
    }
  },
};

export default Users;
