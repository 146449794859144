import React from "react";
import NavBar from "../Navbar";
import { useSelector } from "react-redux";
import { userInfor } from "src/components/Redux/selector";

function PaymentPolicy() {
  const user = useSelector(userInfor);

  return (
    <div>
      {!user && <NavBar />}
      <div className="container bg-white rounded-1 p-3 d-flex flex-column">
        <div className="py-3 fw-semibold fs-4 text-center">
          Chính sách thanh toán
        </div>
        <div>
          Có 2 hình thức thanh toán, khách hàng có thể lựa chọn hình thức thuận
          tiện và phù hợp với mình nhất:
        </div>
        <div className="pt-1 fw-semibold">
          I – Thanh toán khi nhận hàng, khách hàng xem hàng tại nhà, thanh toán
          tiền mặt cho nhân viên giao nhận hàng.
        </div>
        <div className="pt-1 fw-semibold">
          II – Chuyển khoản trước. Quý khách chuyển khoản trước, sau đó chúng
          tôi tiến hành giao hàng theo thỏa thuận hoặc hợp đồng với Quý khách.
        </div>
        <div className="pt-1 fw-semibold">Thông tin chuyển khoản:</div>
        <div className="fw-semibold">
          Chủ tài khoản: CTY CP SX VA THUONG MAI FFE GROUP
        </div>
        <div className="fw-semibold">Số tài khoản: 113600337666</div>
        <div className="fw-semibold">
          Ngân hàng Chi nhánh: Ngân hàng TMCP Công thương Việt Nam CN BA DINH -
          PGD CUA NAM- Hà Nội
        </div>
        <div className="pt-1 fw-semibold">Lưu ý:</div>
        <div>
          - Nội dung chuyển khoản ghi rõ họ tên và chuyển cho món hàng nào. Sau
          khi chuyển khoản, chúng tôi sẽ liên hệ xác nhận và tiến hành giao
          hàng. Nếu sau thời gian thỏa thuận mà chúng tôi không giao hàng hoặc
          không phản hồi lại, quý khách có thể gửi khiếu nại trực tiếp về địa
          chỉ trụ sở và yêu cầu bồi thường nếu chứng minh được sự chậm trễ làm
          ảnh hưởng đến kinh doanh của quý khách. Đối với khách hàng có nhu cầu
          mua số lượng lớn để kinh doanh hoặc buôn sỉ vui lòng liên hệ trực tiếp
          với chúng tôi để có chính sách giá cả hợp lý. Và việc thanh toán sẽ
          được thực hiện theo hợp đồng.
        </div>
        <div>
          - Chúng tôi cam kết kinh doanh minh bạch, hợp pháp, bán hàng chất
          lượng, có nguồn gốc
        </div>
      </div>
    </div>
  );
}

export default PaymentPolicy;
