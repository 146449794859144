import { Input, Modal, Select } from "antd";
import axios from "axios";
import React, { memo, useEffect, useState } from "react";
import "./Modal.css";
import { toast } from "react-toastify";
import { useAppDispatch } from "src/components/Redux/store";
import { updateBank } from "src/components/Features/user/userSlice";
import { useSelector } from "react-redux";
import { selectLoading } from "src/components/Redux/selector";

const ModalEditBank = (props: any) => {
  const { isOpen, isClose, currentBank, onUpdate } = props;
  const dispatch = useAppDispatch();
  const isLoading = useSelector(selectLoading);
  const [data, setData] = useState([]);
  const [chooseBank, setChooseBank] = useState<any>(null);
  const [bankNumber, setBankNumber] = useState("");
  const [bankBranch, setBankBranch] = useState("");
  const [nameOfBank, setNameOfBank] = useState("");

  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    axios.get(`https://api.vietqr.io/v2/banks`).then((res) => {
      //   console.log("res list all bank----", res);
      const data = res?.data?.data || [];
      const mapData = data.map(
        (bank: { id: number; name: string; shortName: string }) => {
          return {
            value: bank?.id,
            label: bank?.name + " - " + bank?.shortName,
            ...bank,
          };
        }
      );
      setData(mapData);
    });
  };

  const onSubmit = async () => {
    const params = {
      userBankID: currentBank?.userBankID,
      bankCode: chooseBank?.bankCode
        ? chooseBank?.bankCode
        : currentBank?.bankCode,
      bankName: chooseBank?.shortName
        ? chooseBank?.shortName
        : currentBank?.bankName,
      bankNumber: bankNumber ? bankNumber : currentBank?.bankNumber,
      BankBranch: bankBranch ? bankBranch : currentBank?.BankBranch,
      nameOfBankCardHolder: nameOfBank
        ? nameOfBank
        : currentBank?.nameOfBankCardHolder,
      logo: chooseBank ? chooseBank?.logo : currentBank?.logo,
    };

    const resp: any = await dispatch(updateBank(params));
    if (resp.payload.status === true) {
      toast.success("Sửa ngân hàng thành công");
      isClose();
      onUpdate();
    }
  };

  return (
    <Modal
      title={null}
      open={isOpen}
      footer={null}
      closeIcon={null}
      onCancel={() => isClose()}
      width={720}
    >
      <div className="fw-semibold fs-5">Chỉnh sửa tài khoản ngân hàng</div>
      <div className="title-input pt-4 d-flex">
        Ngân hàng <div className="text-danger ps-1"> *</div>
      </div>
      <Select
        showSearch
        style={{ width: "100%", height: 40 }}
        className="mt-1 input-bank"
        placeholder={currentBank?.bankName}
        optionFilterProp="children"
        onChange={(value, opt) => setChooseBank(opt)}
        filterOption={(input, option: any) =>
          (option?.label ?? "").includes(input)
        }
        filterSort={(optionA: any, optionB: any) =>
          (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase())
        }
        options={data}
      />
      <div className="title-input pt-4 d-flex">
        Số tài khoản <div className="text-danger ps-1"> *</div>
      </div>
      <Input
        placeholder={currentBank?.bankNumber}
        className="mt-1 input-bank"
        value={bankNumber}
        onChange={(e) => setBankNumber(e.target.value)}
        style={{
          height: 40,
        }}
      />
      <div className="title-input pt-4 d-flex">
        Chi nhánh <div className="text-danger ps-1"> *</div>
      </div>
      <Input
        placeholder={currentBank?.BankBranch}
        className="mt-1 input-bank"
        value={bankBranch}
        onChange={(e) => setBankBranch(e.target.value)}
        style={{
          height: 40,
        }}
      />
      <div className="title-input pt-4 d-flex">
        Chủ tài khoản <div className="text-danger ps-1"> *</div>
      </div>
      <Input
        placeholder={currentBank?.nameOfBankCardHolder}
        className="mt-1 input-bank"
        value={nameOfBank}
        onChange={(e) => setNameOfBank(e.target.value)}
        style={{
          height: 40,
        }}
      />
      <div className="d-flex justify-content-end pt-4">
        <div className="d-flex gap-2">
          <button className="btn-modal-close" onClick={() => isClose()}>
            Hủy
          </button>
          <button className="btn-submit" onClick={onSubmit}>
            {isLoading ? (
              <div
                className="spinner-border spinner-border-sm text-danger"
                role="status"
              ></div>
            ) : (
              `Cập nhật`
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default memo(ModalEditBank);
