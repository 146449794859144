import client from './Client';

const Homes = {
  async getHomePage() {
    try {
      const res = await client.get(`/api/home-page`);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async getProducts(params: any) {
    try {
      const res = await client.post(`/api/products/list`, params);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async getNews(params: any) {
    try {
      const res = await client.post(`/api/news/list`, params);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async getNewDetail(id: string) {
    try {
      const res = await client.get(`/api/news/detail/${id}`);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },
};

export default Homes;
