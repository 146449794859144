import { CaretDownOutlined } from "@ant-design/icons";
import { Dropdown, MenuProps } from "antd";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import HomeImages from "src/assets/Images/Home";
import NavbarImages from "src/assets/Images/Navbar";
import {
  logOut,
  setNavigateProfile,
} from "src/components/Features/user/userSlice";
import { useAppDispatch } from "src/components/Redux/store";
import Types from "src/constants/Types";
import useGetProfile from "src/hooks/useGetProfile";
import "src/pages/Navbar/Navbar.css";

const NavBar = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isUserData: any = useGetProfile();
  const [searchQuery, setSearchQuery] = useState("");

  const onLogout = async () => {
    Cookies.remove(Types.ACCESS_TOKEN);
    dispatch(logOut());
    setTimeout(() => {
      navigate("/");
    }, 500);
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div
          className="txt-account d-flex align-items-center gap-2 mt-2"
          onClick={() => {
            dispatch(setNavigateProfile(true));
            navigate("/profile");
          }}
        >
          <img src={NavbarImages.person} width={24} height={24} />
          Thông tin tài khoản
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          className="txt-account d-flex align-items-center gap-2 mt-2"
          onClick={() => {
            dispatch(setNavigateProfile(true));
            navigate("/my-bank");
          }}
        >
          <img src={NavbarImages.account_balance} width={24} height={24} />
          Ngân hàng của tôi
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div
          className="txt-account d-flex align-items-center gap-2 mt-2"
          onClick={() => {
            dispatch(setNavigateProfile(true));
            navigate("/my-address");
          }}
        >
          <img src={NavbarImages.home_pin} width={24} height={24} />
          Sổ địa chỉ
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <div
          className="txt-account d-flex align-items-center gap-2 mt-2"
          onClick={() => {
            dispatch(setNavigateProfile(true));
            navigate("/list-order");
          }}
        >
          <img src={NavbarImages.shopping_cart} width={24} height={24} />
          Đơn hàng
        </div>
      ),
    },
    {
      key: "5",
      label: (
        <div
          className="txt-account d-flex align-items-center gap-2 mt-2"
          onClick={() => {
            dispatch(setNavigateProfile(true));
            navigate("/wallet");
          }}
        >
          <img
            src={NavbarImages.account_balance_wallet}
            width={24}
            height={24}
          />
          Ví cá nhân
        </div>
      ),
    },
    // {
    //   key: "6",
    //   label: (
    //     <div
    //       className="txt-account d-flex align-items-center gap-2 mt-2"
    //       onClick={() => {
    //         dispatch(setNavigateProfile(true));
    //         navigate("/Accumulation");
    //       }}
    //     >
    //       <img src={NavbarImages.savings} width={24} height={24} />
    //       Ví tích lũy
    //     </div>
    //   ),
    // },
    {
      key: "7",
      label: (
        <div
          className="txt-account d-flex align-items-center gap-2 mt-2"
          onClick={() => {
            dispatch(setNavigateProfile(true));
            navigate("/share");
          }}
        >
          <img src={NavbarImages.share} width={24} height={24} />
          Mời bạn bè
        </div>
      ),
    },
    {
      key: "8",
      label: (
        <div
          onClick={onLogout}
          className="txt-account d-flex align-items-center gap-2 mt-2 btn border border-0 p-0"
        >
          <img src={NavbarImages.logout} width={24} height={24} />
          Đăng xuất
        </div>
      ),
    },
  ];

  const onSearch = () => {
    navigate(`/product?search=${searchQuery}`, {
      state: { searchQuery },
    });
    setSearchQuery("");
  };

  return (
    <nav className="navbar navbar-expand-lg container-navbar">
      <div className="container-xxl d-flex flex-column py-2">
        <div className="d-flex align-items-center justify-content-between w-100">
          <button
            className="btn border border-0 p-0 d-block d-sm-none"
            onClick={() => {
              dispatch(setNavigateProfile(false));
              navigate("/");
            }}
          >
            <img src={HomeImages.logo} width={150} height={44} />
          </button>
          <div className="d-flex justify-content-end align-items-center w-100 gap-2">
            <div className="d-none d-md-block">
              <div className="d-flex gap-2">
                <button
                  className="d-flex justify-content-center gap-1 btn-click-tab"
                  onClick={() => {
                    dispatch(setNavigateProfile(false));
                    navigate("/");
                  }}
                >
                  <img src={HomeImages.home} width={20} height={20} />
                  <div className="txt-click-tab">Trang chủ</div>
                </button>
                <button
                  className="d-flex justify-content-center gap-1 btn-click-tab"
                  onClick={() => {
                    if (!isUserData?.data?.fullName) {
                      toast.error(
                        "Vui lòng đăng nhập để sử dụng chức năng này"
                      );
                      navigate("/sign-in");
                    } else {
                      navigate("/notifications");
                    }
                  }}
                >
                  <img src={HomeImages.notifications} width={20} height={20} />
                  <div className="txt-click-tab">Thông báo</div>
                </button>
              </div>
            </div>
            {isUserData?.data?.fullName ? (
              <Dropdown menu={{ items }} placement="bottomRight">
                <button
                  className="d-flex justify-content-center align-items-center gap-1 btn-click-tab"
                  onClick={() => navigate("/sign-in")}
                >
                  <img
                    src={HomeImages.logo_product}
                    width={24}
                    height={24}
                    style={{ borderRadius: 80 }}
                  />
                  <div className="txt-click-tab">
                    {isUserData?.data?.fullName}
                  </div>
                  <CaretDownOutlined className="text-white" />
                </button>
              </Dropdown>
            ) : (
              <>
                <button
                  className="d-flex justify-content-center gap-1 btn-click-tab"
                  onClick={() => navigate("/sign-in")}
                >
                  <img src={HomeImages.login} width={20} height={20} />
                  <div className="txt-click-tab">Đăng nhập</div>
                </button>
                <button
                  className="d-flex justify-content-center gap-1 btn-click-tab"
                  onClick={() => navigate("/sign-up")}
                >
                  <img src={HomeImages.person_add} width={20} height={20} />
                  <div className="txt-click-tab">Đăng ký</div>
                </button>
              </>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center w-100 pt-2">
          <button
            className="btn border border-0 p-0"
            onClick={() => {
              dispatch(setNavigateProfile(false));
              navigate("/");
            }}
          >
            <img
              src={HomeImages.logo}
              width={150}
              height={44}
              className="d-none d-md-block"
            />
          </button>
          <div className="container-search">
            <input
              type="text"
              className="input-search border border-0 ps-3"
              placeholder="Tìm kiếm sản phẩm"
              value={searchQuery}
              onChange={(text) => setSearchQuery(text.target.value)}
              onKeyDown={(e) => (e.key === "Enter" ? onSearch() : "")}
            />
            <button
              className="btn border border-0 p-0 m-1"
              onClick={() => {
                if (!searchQuery) {
                  return;
                } else {
                  onSearch();
                }
              }}
            >
              <img src={HomeImages.icon_search} width={40} height={40} alt="" />
            </button>
          </div>
          <button
            className="btn border border-0 p-0"
            onClick={() => {
              if (!isUserData?.data?.fullName) {
                toast.error("Vui lòng đăng nhập để sử dụng chức năng này");
                navigate("/sign-in");
              } else {
                dispatch(setNavigateProfile(false));
                navigate("/cart");
              }
            }}
          >
            <img src={HomeImages.icon_cart} width={52} height={52} />
          </button>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
