import rootReducer, { RootState } from "./rootReducer";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk, { ThunkAction } from "redux-thunk";
import { useDispatch } from "react-redux";
import { Action } from "@reduxjs/toolkit";

const composedEnhancers = composeWithDevTools();
const store = createStore(
    rootReducer,
    applyMiddleware(thunk),
    composedEnhancers
);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<any>();

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
export { store };
