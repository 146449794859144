function formatNumber(x: any) {
    if (x) {
        x = Math.round(x);
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    } else {
        return '0';
    }

}

export function formatMoneyText(x: any) {
    if (x) {
        try {
            // x = parseInt(x) + ''
            x = x.replace(/^0+|,|-|\.| /g, '');
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        } catch (e) {
            return '';
        }
    } else {
        return '';
    }
}

export default formatNumber