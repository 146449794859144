const Colors = {
    ORANGE_1: '#fdf3e9',
    ORANGE_2: '#f9e1cb',
    ORANGE_3: '#f5caa2',
    ORANGE_4: '#f0b176',
    ORANGE_5: '#eb994d',
    ORANGE_6: '#e78326',
    ORANGE_7: '#c46f20',
    ORANGE_8: '#a45d1b',
    ORANGE_9: '#844b16',
    ORANGE_10: '#683b11',
    GREEN_1: '#eff8e9',
    GREEN_2: '#d8efcb',
    GREEN_3: '#bae2a1',
    GREEN_4: '#9ad476',
    GREEN_5: '#7bc74c',
    GREEN_6: '#5ebb25',
    GREEN_7: '#509f1f',
    GREEN_8: '#43851a',
    GREEN_9: '#366b15',
    GREEN_10: '#2a5411',
    PRIMARY_1: '#fdedea',
    PRIMARY_2: '#fbd4cd',
    PRIMARY_3: '#f8b2a5',
    PRIMARY_4: '#f48f7b',
    PRIMARY_5: '#f16d53',
    PRIMARY_6: '#ee4d2d',
    PRIMARY_7: '#ca4126',
    PRIMARY_8: '#a93720',
    PRIMARY_9: '#882c1a',
    PRIMARY_10: '#6b2314',
    GREY_1: '#ececec',
    GREY_2: '#d1d1d1',
    GREY_3: '#adadad',
    GREY_4: '#878787',
    GREY_5: '#626262',
    GREY_6: '#404040',
    GREY_7: '#363636',
    GREY_8: '#2d2d2d',
    GREY_9: '#242424',
    GREY_10: '#1d1d1d',
    NEUTRAL_1: '#f5f5f5',
    NEUTRAL_2: '#e6e6e6',
    NEUTRAL_3: '#d2d2d2',
    NEUTRAL_4: '#bdbdbd',
    NEUTRAL_5: '#a9a9a9',
    NEUTRAL_6: '#969696',
    NEUTRAL_7: '#808080',
    NEUTRAL_8: '#6b6b6b',
    NEUTRAL_9: '#565656',
    NEUTRAL_10: '#444444',
    C_BLUE: "#2254F6",
    GREY: "#A1A1A1",
    ORANGE: "#EF924C",
    RED: "#E5000E",
    BLUE_4: '#0209C5',
    BLACK: '#2A3447',
    PRIMARY: '#EE4D2D',
}

export default Colors