import { Modal } from "antd";
import React from "react";
import ProfileImages from "src/assets/Images/Profile";
import "./Modal.css";
import { useAppDispatch } from "src/components/Redux/store";
import { toast } from "react-toastify";
import { deleteBank } from "src/components/Features/user/userSlice";

const ModalDeleteBank = (props: any) => {
  const dispatch = useAppDispatch();
  const { isOpen, isClose, userBankID, onUpdate } = props;

  const onDelete = async () => {
    const resp: any = await dispatch(deleteBank(userBankID));
    if (resp?.payload?.status === true) {
      toast.success("Xóa ngân hàng thành công");
      isClose();
      onUpdate();
    }
  };
  return (
    <Modal
      title={null}
      open={isOpen}
      footer={null}
      closeIcon={null}
      onCancel={() => isClose()}
      width={350}
    >
      <div className="d-flex flex-column align-items-center">
        <img src={ProfileImages.icon_warning} width={80} height={80} />
        <div className="fw-semibold fs-5 pt-3">Xóa tài khoản</div>
        <div className="txt-content-modal">
          Bạn muốn xóa tài khoản ngân hàng này?
        </div>
        <div className="d-flex gap-2 w-100 pt-3">
          <button className="btn-close-modal" onClick={() => isClose()}>
            Hủy
          </button>
          <button className="btn-submit-modal" onClick={() => onDelete()}>
            Xóa
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalDeleteBank;
