import React from "react";
import { useSelector } from "react-redux";
import { userInfor } from "../Redux/selector";
import { setClientToken } from "src/controllers/Client";
import MainNavigator from "./MainNavigator";
import AuthNavigator from "./AuthNavigator";
import Cookies from "js-cookie";
import Types from "src/constants/Types";
const Navigation = () => {
  const userData = useSelector(userInfor);
  const accessToken = Cookies.get(Types.ACCESS_TOKEN);

  if (accessToken) {
    setClientToken(accessToken);
  }
  return userData?.accessToken || accessToken ? (
    <MainNavigator />
  ) : (
    <AuthNavigator />
  );
};

export default Navigation;
