import React from "react";
import NavBar from "../Navbar";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { userInfor } from "src/components/Redux/selector";

function ComplaintPolicy() {
  const navigate = useNavigate();
  const user = useSelector(userInfor);

  return (
    <div>
      {!user && <NavBar />}
      <div className="container bg-white rounded-1 p-3 d-flex flex-column">
        <div className="py-3 fw-semibold fs-4 text-center">
          Chính sách xử lý khiếu nại
        </div>
        <div className="d-flex gap-1 align-items-end">
          Tiếp nhận mọi khiếu nại của khách hàng liên quan đến việc mua sản phẩm
          của website: ffe.vn
        </div>

        <div className="pt-1">
          Tất cả mọi trường hơp đổi trả và chăm sóc khách hàng, người mua có thể
          liên hệ với website: ffe.vn theo những thông tin được niêm yết công
          khai trên website. Thời gian giải quyết khiếu nại trong thời hạn tối
          đa là 07 ngày làm việc kể từ khi nhận được khiếu nại của khách hàng.
          Trong trường hợp bất khả kháng 2 bên sẽ tự thương lượng. Mọi vấn đề về
          thương lượng, giải quyết vấn đề đều dựa trên cơ sở thỏa thuận và tôn
          trọng lẫn nhau, đúng các quy định của pháp luật. Khách hàng có các vấn
          đề về khiếu nại có thể liên hệ trực tiếp với chúng tôi theo địa chỉ
          sau: A11 Ngõ 17 P. Lê Văn Thiêm, Nhân Chính, Thanh Xuân, Hà Nội, Việt
          Nam
        </div>
      </div>
    </div>
  );
}

export default ComplaintPolicy;
