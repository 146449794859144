import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ProductImages from "src/assets/Images/Product";
import formatNumber from "src/common/FormatNumber";
import {
  cartUpdate,
  getCardList,
} from "src/components/Features/cart/cartSlice";
import { selectLoading } from "src/components/Redux/selector";
import { useAppDispatch } from "src/components/Redux/store";
import { CartProps } from "src/interface";
import "./Cart.css";
import { API_URL } from "src/controllers/Client";
import { useNavigate } from "react-router-dom";

const Cart = () => {
  const isLoading = useSelector(selectLoading);
  const dispatch = useAppDispatch();
  const [dataCart, setDataCart] = useState([]);
  const [totalMoney, setTotalMoney] = useState(0);
  const [totalNumberCart, setTotalNumberCart] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const resp: any = await dispatch(getCardList());
    console.log("resp all cart---", resp);
    if (resp.payload.status === true) {
      const data = resp?.payload?.carts?.products;
      const filterByTotalMoney =
        data
          .map(
            (i: any) =>
              (i?.product_id?.price + i?.product_id?.priceVocher) * i?.quantity
          )
          .reduce((a: number, c: number) => a + c) ?? [];
      const filterByTotalNumberCart =
        data
          .map((i: any) => i?.quantity)
          .reduce((a: number, c: number) => a + c) ?? [];
      setTotalNumberCart(filterByTotalNumberCart);
      setTotalMoney(filterByTotalMoney);
      setDataCart(data);
    }
  };

  const onUpdate = async (type: string, item: CartProps) => {
    const params = {
      productID: item?.productID,
      quantity: type === "minus" ? item?.quantity - 1 : item?.quantity + 1,
    };
    const resp: any = await dispatch(cartUpdate(params));
    // console.log('resp update---', resp);
    if (resp.payload.status === true) {
      getData();
      if (dataCart.length === 1) {
        setDataCart([]);
        setTotalNumberCart(0);
        setTotalMoney(0);
      }
    }
  };

  const onDetele = async (productID: number) => {
    const params = {
      productID: productID,
      quantity: 0,
    };
    const resp: any = await dispatch(cartUpdate(params));
    // console.log('resp update---', resp);
    if (resp.payload.status === true) {
      getData();
      if (dataCart.length === 1) {
        setDataCart([]);
        setTotalNumberCart(0);
        setTotalMoney(0);
      }
    }
  };

  const onSubmit = async () => {
    navigate(`/buy-product`);
  };

  return (
    <div className="container-home">
      <div className="container-xxl py-5">
        <div className="col col-lg-12 row gap-2">
          <div className="col col-lg-8 bg-white p-3 rounded-1 mb-5">
            <div className="txt-user-location">Giỏ hàng</div>
            {!dataCart.length ? (
              <>
                <div>Giỏ hàng trống</div>
              </>
            ) : (
              <>
                <div className="d-none d-md-block">
                  <div className="w-100 border mt-3 mb-2" />
                  <div className="py-3 row col col-lg-12">
                    <div className="txt-ul col col-lg-4">Sản phẩm</div>
                    <div className="txt-ul col col-lg-2">Đơn giá</div>
                    <div className="txt-ul col col-lg-3">Số lượng</div>
                    <div className="txt-ul col col-lg-2">Thành tiền</div>
                  </div>
                  {dataCart.map((item: any) => {
                    const findVideo = item?.product_id?.images?.find(
                      (x: { includes: (arg0: string) => any }) =>
                        !x?.includes(".mp4")
                    );
                    return (
                      <div
                        className="mb-3 row col col-lg-12"
                        key={item?.productID}
                      >
                        <div className="d-flex col col-lg-4">
                          <img
                            src={`${API_URL}${findVideo}`}
                            width={92}
                            height={92}
                            style={{
                              minWidth: 92,
                            }}
                          />
                          <div className="ps-3 txt-product-name">
                            {item?.product_id?.productName}
                          </div>
                        </div>
                        <div className="col col-lg-2 d-flex align-items-center">
                          {formatNumber(item?.product_id?.price)}đ
                        </div>
                        <div className="col col-lg-3 d-flex align-items-center">
                          <div className="d-flex align-items-center gap-2 mt-2">
                            <button
                              className="btn border border-0 p-0 fw-semibold fs-3"
                              onClick={() => onUpdate("minus", item)}
                              // disabled={numberProduct === 1}
                            >
                              -
                            </button>
                            <input
                              type="text"
                              className="input-add-number"
                              value={item?.quantity}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              // onChange={(text) => {
                              //   if (Number.isNaN(text.target.valueAsNumber)) {
                              //     setNumberProduct(1);
                              //   } else {
                              //     setNumberProduct(text.target.valueAsNumber);
                              //   }
                              // }}
                            />
                            <button
                              className="btn border border-0 p-0 fw-semibold fs-3"
                              onClick={() => onUpdate("add", item)}
                            >
                              +
                            </button>
                          </div>
                        </div>
                        <div className="col col-lg-2  txt-total-price d-flex align-items-center">
                          {formatNumber(
                            item?.product_id?.price * item?.quantity
                          )}
                          đ
                        </div>
                        <div
                          onClick={() => onDetele(item?.productID)}
                          className="btn border border-0 p-0  col col-lg-1 d-flex align-items-center"
                        >
                          <img
                            src={ProductImages.icon_delete_grey}
                            width={40}
                            height={40}
                            className="img-delete"
                            alt=""
                          />
                        </div>
                        <div className="w-100 border my-2" />
                      </div>
                    );
                  })}
                </div>
                <div className="d-block d-md-none">
                  <div>
                    {dataCart.map((item: any) => {
                      const findVideo = item?.product_id?.images?.find(
                        (x: { includes: (arg0: string) => any }) =>
                          !x?.includes(".mp4")
                      );
                      return (
                        <div>
                          <div className="d-flex justify-content-between pt-2">
                            <div className="txt-ul">Sản phẩm:</div>
                            <div className="d-flex flex-column align-items-end">
                              <img
                                src={`${API_URL}${findVideo}`}
                                width={92}
                                height={92}
                                style={{
                                  minWidth: 92,
                                }}
                              />
                              <div className="ps-3 txt-product-name pt-1">
                                {item?.product_id?.productName}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between pt-2">
                            <div className="txt-ul col col-lg-2">Đơn giá:</div>
                            <div className="txt-ul">
                              {formatNumber(item?.product_id?.price)}đ
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center pt-2">
                            <div className="txt-ul col col-lg-3">Số lượng:</div>
                            <div className="d-flex align-items-center gap-2 mt-2">
                              <button
                                className="btn border border-0 p-0 fw-semibold fs-3"
                                onClick={() => onUpdate("minus", item)}
                                // disabled={numberProduct === 1}
                              >
                                -
                              </button>
                              <input
                                type="text"
                                className="input-add-number"
                                value={item?.quantity}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                style={{
                                  height: 36,
                                }}
                              />
                              <button
                                className="btn border border-0 p-0 fw-semibold fs-3"
                                onClick={() => onUpdate("add", item)}
                              >
                                +
                              </button>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between pt-2">
                            <div className="txt-ul col col-lg-2">
                              Thành tiền:
                            </div>
                            <div className="txt-total-price">
                              {formatNumber(
                                item?.product_id?.price * item?.quantity
                              )}
                              đ
                            </div>
                          </div>
                          <div className="w-100 border my-2" />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            className="col-lg-3 p-3 rounded-1 bg-white"
            style={{
              height: 370,
            }}
          >
            <div className="txt-user-location">Thông tin đơn hàng</div>
            <div className="txt-number-product-right pt-4">Số sản phẩm</div>
            <div className="txt-number-right">{dataCart?.length}</div>
            <div className="txt-number-product-right pt-3">Tổng số lượng</div>
            <div className="txt-number-right">{totalNumberCart}</div>
            <div className="txt-number-product-right pt-3">Tổng thanh toán</div>
            <div className="txt-total-all-price">
              {formatNumber(totalMoney)}đ
            </div>
            <button className="btn-buy-product w-100 mt-5" onClick={onSubmit}>
              Mua ngay
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Cart);
