import { CopyOutlined } from "@ant-design/icons";
import { Input, Modal, Tooltip } from "antd";
import React from "react";

const RechargeStep2 = (props: any) => {
  const { isOpen, isClose, dataStep1, onSubmit } = props;

  return (
    <Modal
      title={"Nạp tiền vào ví"}
      open={isOpen}
      footer={null}
      closeIcon={null}
      onCancel={() => isClose()}
      width={795}
      maskClosable={false}
    >
      <div className="row">
        <div className="col-lg-6 d-flex justify-content-center">
          <img src={dataStep1?.url} height={336} alt="" />
        </div>
        <div className="col-lg-6">
          <div className="fw-semibold pb-1">Tên ngân hàng</div>
          <Input
            value={dataStep1?.bankCode}
            className="input-email"
            suffix={
              <button
                className="btn border border-0 p-0"
                onClick={() => {
                  navigator.clipboard.writeText(dataStep1?.bankCode);
                }}
              >
                <Tooltip title="Coppy">
                  <CopyOutlined style={{ color: "#626262" }} />
                </Tooltip>
              </button>
            }
          />
          <div className="fw-semibold pb-1 pt-2">Số tài khoản</div>
          <Input
            value={dataStep1?.bankNumber}
            className="input-email"
            suffix={
              <button
                className="btn border border-0 p-0"
                onClick={() => {
                  navigator.clipboard.writeText(dataStep1?.bankNumber);
                }}
              >
                <Tooltip title="Coppy">
                  <CopyOutlined style={{ color: "#626262" }} />
                </Tooltip>
              </button>
            }
          />
          <div className="fw-semibold pb-1 pt-2">Tên người hưởng thụ</div>
          <Input
            value={dataStep1?.accountName}
            className="input-email"
            suffix={
              <button
                className="btn border border-0 p-0"
                onClick={() => {
                  navigator.clipboard.writeText(dataStep1?.accountName);
                }}
              >
                <Tooltip title="Coppy">
                  <CopyOutlined style={{ color: "#626262" }} />
                </Tooltip>
              </button>
            }
          />
          <div className="fw-semibold pb-1 pt-2">Nội dung chuyển khoản</div>
          <Input
            value={dataStep1?.description}
            className="input-email"
            suffix={
              <button
                className="btn border border-0 p-0"
                onClick={() => {
                  navigator.clipboard.writeText(dataStep1?.description);
                }}
              >
                <Tooltip title="Coppy">
                  <CopyOutlined style={{ color: "#626262" }} />
                </Tooltip>
              </button>
            }
          />
          <div className="fw-semibold pb-1 pt-2">Số tiền</div>
          <Input
            value={dataStep1?.total}
            className="input-email"
            suffix={
              <button
                className="btn border border-0 p-0"
                onClick={() => {
                  navigator.clipboard.writeText(dataStep1?.total);
                }}
              >
                <Tooltip title="Coppy">
                  <CopyOutlined style={{ color: "#626262" }} />
                </Tooltip>
              </button>
            }
          />
        </div>
      </div>
      <div className="d-flex justify-content-end mt-4 gap-2">
        <button className="btn-close-recharge" onClick={() => isClose()}>
          Hủy
        </button>
        <button className="btn-submit-recharge" onClick={onSubmit}>
          Tiếp tục
        </button>
      </div>
    </Modal>
  );
};

export default RechargeStep2;
