import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import Product from 'src/controllers/Product';

const productInitialState = {};

export const getProductDetail = createAsyncThunk(
  'product/getProductDetail',
  async (id: string | number) => {
    const resp = await Product.getProductDetail(id);
    return resp;
  },
);

export const getListOrder = createAsyncThunk(
  'product/getListOrder',
  async (params: any) => {
    const resp = await Product.getListOrder(params);
    return resp;
  },
);

export const orderCancel = createAsyncThunk(
  'product/orderCancel',
  async (params: any) => {
    const resp = await Product.orderCancel(params);
    return resp;
  },
);

const product = createSlice({
  name: 'product',
  initialState: productInitialState,
  reducers: {},
});

const { actions, reducer } = product;
export default reducer;
