import React, { memo, useState } from "react";
import AuthImages from "src/assets/Images/Auth";
import HomeImages from "src/assets/Images/Home";
import "./Auth.css";
import { Input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useAppDispatch } from "src/components/Redux/store";
import { resetPassword } from "src/components/Features/user/userSlice";
import { useSelector } from "react-redux";
import { selectLoading } from "src/components/Redux/selector";
import { useNavigate } from "react-router-dom";

const NewPassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector(selectLoading);
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");

  const onLogin = async () => {
    const params = {
      newPassword: password,
      repeatNewPassword: rePassword,
    };
    const resp: any = await dispatch(resetPassword(params));
    if (resp?.payload?.status === true) {
      navigate("/");
    }
  };

  return (
    <div>
      <div className="container-xxl py-4">
        <button
          className="btn border border-0 p-0"
          onClick={() => navigate("/")}
        >
          <img src={AuthImages.logo} width={154} height={48} />
        </button>
      </div>
      <div className="main py-5">
        <div className="container-xxl d-none d-sm-block">
          <div className="row align-items-center">
            <div className="col-md-7">
              {/* <div className="txt-title-auth">
                Sàn thương mại điện tử khởi nghiệp
              </div> */}
              <img src={HomeImages.logo} width={386} height={120} />
            </div>
            <div className="rounded p-4 bg-white col-md-4">
              <div className="txt-title-right">Thay đổi mật khẩu</div>
              <div className="title-input pt-4">Mật khẩu mới</div>
              <Input.Password
                placeholder="*******"
                className="mt-1"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{
                  height: 40,
                }}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
              <div className="title-input pt-3">Nhập lại mật khẩu mới</div>
              <Input.Password
                placeholder="********"
                className="mt-1"
                value={rePassword}
                style={{
                  height: 40,
                }}
                onChange={(e) => setRePassword(e.target.value)}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />

              <button className="btn-login" onClick={onLogin}>
                {isLoading ? (
                  <div
                    className="spinner-border spinner-border-sm text-light"
                    role="status"
                  ></div>
                ) : (
                  `Xác nhận`
                )}
              </button>
            </div>
          </div>
        </div>

        <div className="d-block d-sm-none d-flex flex-column align-items-center">
          <img src={HomeImages.logo} width={160} height={50} />
          <div className="rounded p-4 w-100">
            <div className="title-input-sm">Mật khẩu mới</div>
            <Input.Password
              placeholder="*******"
              className="mt-1"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{
                height: 40,
              }}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
            <div className="title-input-sm pt-3">Nhập lại mật khẩu mới</div>
            <Input.Password
              placeholder="*******"
              className="mt-1"
              value={rePassword}
              style={{
                height: 40,
              }}
              onChange={(e) => setRePassword(e.target.value)}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />

            <button className="btn-login-sm" onClick={onLogin}>
              {isLoading ? (
                <div
                  className="spinner-border spinner-border-sm text-danger"
                  role="status"
                ></div>
              ) : (
                `Xác nhận`
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(NewPassword);
