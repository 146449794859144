import Colors from "src/common/Colors";

const Types = {
  ACCESS_TOKEN: "access_token",
  USER_DATA: "user_data",
};

export const GetMemberName = (type: string) => {
  switch (type) {
    case "member":
      return "Thành viên";
    case "collaborator":
      return "Cộng tác viên";
    case "start_up":
      return "Khởi nghiệp";
    case "deputy_business":
      return "Phó phòng kinh doanh";
    case "sales_manager":
      return "Trưởng phòng kinh doanh";
    case "business_director":
      return "Giám đốc kinh doanh";
    case "deputy_general_manager":
      return "Phó tổng giám đốc";
    default:
      return "Chưa xác định";
  }
};

export const GetMemberColor = (type: string) => {
  switch (type) {
    case "member":
      return "#000000";
    case "collaborator":
      return "brown";
    case "start_up":
      return "purple";
    case "deputy_business":
      return Colors.C_BLUE;
    case "sales_manager":
      return "orange";
    case "business_director":
      return "gold";
    case "deputy_general_manager":
      return Colors.RED;
    default:
      return Colors.GREY;
  }
};

export default Types;
