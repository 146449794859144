import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import Wallet from 'src/controllers/Wallet';
import { WalletProps } from 'src/interface';

const walletInitialState = {};

export const listBankDeposit = createAsyncThunk(
  'wallet/listBankDeposit',
  async (params: WalletProps) => {
    const resp = await Wallet.listBankDeposit(params);
    return resp;
  },
);

export const depositCreate = createAsyncThunk(
  'wallet/depositCreate',
  async (params: WalletProps) => {
    const resp = await Wallet.depositCreate(params);
    return resp;
  },
);

export const transactionList = createAsyncThunk(
  'wallet/transactionList',
  async (params: any) => {
    const resp = await Wallet.transactionList(params);
    return resp;
  },
);

export const withdrawCreate = createAsyncThunk(
  'wallet/withdrawCreate',
  async (params: WalletProps) => {
    const resp = await Wallet.withdrawCreate(params);
    return resp;
  },
);

export const getUserVoucher = createAsyncThunk(
  'wallet/getUserVoucher',
  async (params: WalletProps) => {
    const resp = await Wallet.getUserVoucher(params);
    return resp;
  },
);

export const transactionCancel = createAsyncThunk(
  'wallet/transactionCancel',
  async (params: WalletProps) => {
    const resp = await Wallet.transactionCancel(params);
    return resp;
  },
);

export const walletImagePayment = createAsyncThunk(
  'wallet/walletImagePayment',
  async (params: WalletProps) => {
    const resp = await Wallet.walletImagePayment(params);
    return resp;
  },
);

export const uploadImage = createAsyncThunk(
  'wallet/uploadImage',
  async (params: WalletProps) => {
    const resp = await Wallet.uploadImage(params);
    return resp;
  },
);

export const walletSendVoucher = createAsyncThunk(
  'wallet/walletSendVoucher',
  async (params: WalletProps) => {
    const resp = await Wallet.walletSendVoucher(params);
    return resp;
  },
);

export const walletVerifyVoucher = createAsyncThunk(
  'wallet/walletVerifyVoucher',
  async (params: WalletProps) => {
    const resp = await Wallet.walletVerifyVoucher(params);
    return resp;
  },
);

const product = createSlice({
  name: 'wallet',
  initialState: walletInitialState,
  reducers: {},
});

const { actions, reducer } = product;
export default reducer;
