import { Input, Modal, Select } from "antd";
import axios from "axios";
import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { createBank } from "src/components/Features/user/userSlice";
import { selectLoading } from "src/components/Redux/selector";
import { useAppDispatch } from "src/components/Redux/store";

const ModalAddBank = (props: any) => {
  const { isOpen, isClose, onUpdate } = props;
  const isLoading = useSelector(selectLoading);
  const dispatch = useAppDispatch();
  const [data, setData] = useState([]);
  const [chooseBank, setChooseBank] = useState<any>(null);
  const [bankNumber, setBankNumber] = useState("");
  const [bankBranch, setBankBranch] = useState("");
  const [nameOfBank, setNameOfBank] = useState("");

  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    axios.get(`https://api.vietqr.io/v2/banks`).then((res) => {
      //   console.log("res list all bank----", res);
      const data = res?.data?.data || [];
      const mapData = data.map(
        (bank: { id: number; name: string; shortName: string }) => {
          return {
            value: bank?.id,
            label: bank?.name + " - " + bank?.shortName,
            ...bank,
          };
        }
      );
      setData(mapData);
    });
  };

  const onSubmit = async () => {
    const params = {
      bankCode: chooseBank?.code,
      bankName: chooseBank?.shortName,
      bankNumber: bankNumber,
      BankBranch: bankBranch,
      nameOfBankCardHolder: nameOfBank,
      logo: chooseBank?.logo,
    };

    const resp: any = await dispatch(createBank(params));
    if (resp.payload.status === true) {
      toast.success("Tạo ngân hàng thành công");
      isClose();
      onUpdate();
    }
  };

  return (
    <Modal
      title={null}
      open={isOpen}
      footer={null}
      closeIcon={null}
      onCancel={() => isClose()}
      width={720}
    >
      <div className="fw-semibold fs-5">Thêm tài khoản ngân hàng</div>
      <div className="title-input pt-4 d-flex">
        Ngân hàng <div className="text-danger ps-1"> *</div>
      </div>
      <Select
        showSearch={false}
        style={{ width: "100%", height: 40 }}
        className="mt-1"
        placeholder={"Chọn ngân hàng"}
        optionFilterProp="children"
        onChange={(value, opt) => setChooseBank(opt)}
        filterOption={(input, option: any) =>
          (option?.label ?? "").includes(input)
        }
        filterSort={(optionA: any, optionB: any) =>
          (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase())
        }
        options={data}
      />
      <div className="title-input pt-4 d-flex">
        Số tài khoản <div className="text-danger ps-1"> *</div>
      </div>
      <Input
        placeholder={"Nhập số tài khoản ngân hàng của bạn"}
        className="mt-1"
        value={bankNumber}
        onChange={(e) => setBankNumber(e.target.value)}
        style={{
          height: 40,
        }}
      />
      <div className="title-input pt-4 d-flex">
        Chi nhánh <div className="text-danger ps-1"> *</div>
      </div>
      <Input
        placeholder={"Chọn chi nhánh ngân hàng"}
        className="mt-1"
        value={bankBranch}
        onChange={(e) => setBankBranch(e.target.value)}
        style={{
          height: 40,
        }}
      />
      <div className="title-input pt-4 d-flex">
        Chủ tài khoản <div className="text-danger ps-1"> *</div>
      </div>
      <Input
        placeholder={"Nhập tên chủ tài khoản"}
        className="mt-1"
        value={nameOfBank}
        onChange={(e) => setNameOfBank(e.target.value)}
        style={{
          height: 40,
        }}
      />
      <div className="d-flex justify-content-end pt-4">
        <div className="d-flex gap-2">
          <button className="btn-modal-close" onClick={() => isClose()}>
            Hủy
          </button>
          <button
            className="btn-submit"
            onClick={onSubmit}
            style={{
              width: 120,
            }}
          >
            {isLoading ? (
              <div
                className="spinner-border spinner-border-sm text-danger"
                role="status"
              ></div>
            ) : (
              `Thêm mới`
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default memo(ModalAddBank);
