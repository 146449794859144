import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Users from 'src/controllers/User';
import { User } from 'src/interface';
import { setClientToken } from 'src/controllers/Client';

const userInitialState: User = {
  dataLogin: {},
  isProfile: false,
};

export const login = createAsyncThunk('user/login', async (params: User) => {
  const resp = await Users.login(params);
  return resp;
});

export const register = createAsyncThunk(
  'user/register',
  async (params: User) => {
    const resp = await Users.register(params);
    return resp;
  },
);

export const deleteAccount = createAsyncThunk(
  'user/deleteAccount',
  async (params: User) => {
    const resp = await Users.deleteAccount(params);
    return resp;
  },
);

export const verifyEmail = createAsyncThunk(
  'user/verifyEmail',
  async (params: User) => {
    const resp = await Users.verifyEmail(params);
    return resp;
  },
);

export const verifyOTP = createAsyncThunk(
  'user/verifyOTP',
  async (params: User) => {
    const resp = await Users.verifyOTP(params);
    return resp;
  },
);

export const restPassword = createAsyncThunk(
  'user/restPassword',
  async (params: User) => {
    const resp = await Users.restPassword(params);
    return resp;
  },
);

export const addAddress = createAsyncThunk(
  'user/addAddress',
  async (params: User) => {
    const resp = await Users.addAddress(params);
    return resp;
  },
);

export const getProfile = createAsyncThunk('user/getProfile', async () => {
  const resp = await Users.getProfile();
  return resp;
});

export const updateProfile = createAsyncThunk(
  'user/updateProfile',
  async (params: User) => {
    const resp = await Users.updateProfile(params);
    return resp;
  },
);

export const changePassword = createAsyncThunk(
  'user/changePassword',
  async (params: User) => {
    const resp = await Users.changePassword(params);
    return resp;
  },
);

export const resetPassword = createAsyncThunk(
  'user/resetPassword',
  async (params: User) => {
    const resp = await Users.resetPassword(params);
    return resp;
  },
);

export const getAddress = createAsyncThunk('user/getAddress', async () => {
  const resp = await Users.getAddress();
  return resp;
});

export const createAddress = createAsyncThunk(
  'user/createAddress',
  async (params: any) => {
    const resp = await Users.createAddress(params);
    return resp;
  },
);

export const getListBank = createAsyncThunk('user/getListBank', async () => {
  const resp = await Users.getListBank();
  return resp;
});

export const createBank = createAsyncThunk(
  'user/createBank',
  async (params: any) => {
    const resp = await Users.createBank(params);
    return resp;
  },
);

export const attendance = createAsyncThunk('user/attendance', async () => {
  const resp = await Users.attendance();
  return resp;
});

export const miningCoinStart = createAsyncThunk(
  'user/miningCoinStart',
  async () => {
    const resp = await Users.miningCoinStart();
    return resp;
  },
);

export const miningCoinEnd = createAsyncThunk(
  'user/miningCoinEnd',
  async () => {
    const resp = await Users.miningCoinEnd();
    return resp;
  },
);

export const reportGereral = createAsyncThunk(
  'user/reportGereral',
  async () => {
    const resp = await Users.reportGereral();
    return resp;
  },
);

export const miningCoinList = createAsyncThunk(
  'user/miningCoinList',
  async () => {
    const resp = await Users.miningCoinList();
    return resp;
  },
);

export const teamModel = createAsyncThunk('user/teamModel', async () => {
  const resp = await Users.teamModel();
  return resp;
});

export const uploadImage = createAsyncThunk(
  'user/uploadImage',
  async (params: any) => {
    const resp = await Users.uploadImage(params);
    return resp;
  },
);

export const updateBank = createAsyncThunk(
  'user/updateBank',
  async (params: any) => {
    const resp = await Users.updateBank(params);
    return resp;
  },
);

export const deleteBank = createAsyncThunk(
  'user/deleteBank',
  async (id: string | number) => {
    const resp = await Users.deleteBank(id);
    return resp;
  },
);

const user = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers: {
    setUserLogin(state, payload) {
      state.dataLogin = payload;
    },
    setNavigateProfile(state, payload) {
      state.isProfile = payload
    },
    logOut: state => {
      state.dataLogin = {}
      setClientToken(null);
    },

  },
});

const { actions, reducer } = user;
export const { setUserLogin, logOut, setNavigateProfile } =
  actions;
export default reducer;
