import moment from "moment";
import React from "react";
import { useLocation } from "react-router-dom";

const NewDetail = () => {
  const { state } = useLocation();

  return (
    <div className="container-home">
      <div className="container-xxl py-5">
        <div className="bg-white rounded-1 p-3 d-flex flex-column align-items-center">
          <div className="txt-order-id">{state?.item?.title}</div>
          <div className="text-secondary">
            {moment(state?.item?.createdAt).format("DD/MM/YYYY hh:mm:ss")}
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: state?.item?.content,
            }}
            className="txt-description"
          ></div>
        </div>
      </div>
    </div>
  );
};

export default NewDetail;
