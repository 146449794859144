import { WalletProps } from "src/interface";
import client from "./Client";
import { toast } from "react-toastify";

const Wallet = {
  async listBankDeposit(params: WalletProps) {
    try {
      const res = await client.post(`/api/wallet/deposit-banks`, params);
      return res;
    } catch (err) {
      toast.error(err?.message);
    }
  },

  async depositCreate(params: WalletProps) {
    try {
      const res = await client.post(`/api/wallet/create-deposit`, params);
      return res;
    } catch (err) {
      toast.error(err?.message);
    }
  },

  async transactionList(params: any) {
    try {
      const res = await client.post(`/api/wallet/transaction-list`, params);
      return res;
    } catch (err) {
      toast.error(err?.message);
    }
  },

  async withdrawCreate(params: WalletProps) {
    try {
      const res = await client.post(`/api/wallet/create-withdraw`, params);
      return res;
    } catch (err) {
      toast.error(err?.message);
    }
  },

  async getUserVoucher(params: WalletProps) {
    try {
      const res = await client.get(`/api/wallet/info`);
      return res;
    } catch (err) {
      toast.error(err?.message);
    }
  },

  async transactionCancel(params: WalletProps) {
    try {
      const res = await client.post(`/api/wallet/transaction-cancel`, params);
      return res;
    } catch (err) {
      toast.error(err?.message);
    }
  },

  async walletImagePayment(params: WalletProps) {
    try {
      const res = await client.post(`/api/wallet/image-payment`, params);
      return res;
    } catch (err) {
      toast.error(err?.message);
    }
  },

  async uploadImage(params: WalletProps) {
    try {
      const res = await client.post(`/upload/image`, params, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res;
    } catch (err) {
      toast.error(err?.message);
    }
  },

  async walletSendVoucher(params: WalletProps) {
    try {
      const res = await client.post(`/api/wallet/send-voucher`, params);
      return res;
    } catch (err) {
      toast.error(err?.message);
    }
  },

  async walletVerifyVoucher(params: WalletProps) {
    try {
      const res = await client.post(`/api/wallet/verify-voucher`, params);
      return res;
    } catch (err) {
      toast.error(err?.message);
    }
  },
};

export default Wallet;
