import { CopyOutlined, DownOutlined } from "@ant-design/icons";
import { Collapse, CollapseProps, Input, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Colors from "src/common/Colors";
import { teamModel } from "src/components/Features/user/userSlice";
import { useAppDispatch } from "src/components/Redux/store";
import useGetProfile from "src/hooks/useGetProfile";

const Share = () => {
  const isUserData: any = useGetProfile();
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    const getTree = async () => {
      const resp: any = await dispatch(teamModel());
      console.log("resp", resp);
      if (resp.payload.status === true) {
        setData(resp?.payload?.data);
      }
    };
    getTree();
  }, []);

  const getColor = (key: string) => {
    switch (key) {
      case "member":
        return Colors.BLACK;
      case "collaborator":
        return "brown";
      case "start_up":
        return "purple";
      case "deputy_business":
        return Colors.BLUE_4;
      case "sales_manager":
        return "orange";
      case "business_director":
        return "gold";
      case "deputy_general_manager":
        return Colors.RED;
      default:
        return Colors.GREY;
    }
  };

  const level = [
    {
      id: 0,
      text: " Thành viên",
      color: Colors.BLACK,
    },
    {
      id: 1,
      text: "Cộng tác viên",
      color: "brown",
    },
    {
      id: 2,
      text: "Khởi nghiệp",
      color: "purple",
    },
    {
      id: 3,
      text: "Phó phòng kinh doanh",
      color: Colors.BLUE_4,
    },
    {
      id: 5,
      text: "Trưởng phòng kinh doanh",
      color: "orange",
    },
    {
      id: 7,
      text: "Giám đốc kinh doanh",
      color: "gold",
    },
    {
      id: 6,
      text: "Phó tổng giám đốc",
      color: Colors.RED,
    },
    {
      id: 8,
      text: "Chưa xác định",
      color: Colors.GREY,
    },
  ];

  const items1: CollapseProps["items"] = data[0]?.childs?.map((i1: any) => {
    return {
      key: i1?._id,
      label: (
        <div
          style={{ color: getColor(i1?.affiliateLevel) }}
          className="d-flex align-items-center justify-content-between"
        >
          <div className="fw-semibold">{i1?.email + " " + i1?.fullName}</div>
          <button
            className="btn border border-0 p-0"
            onClick={async () => {
              if ("clipboard" in navigator) {
                await navigator.clipboard.writeText(i1?.email);
                toast.success("Sao chép thành công !");
              } else {
                document.execCommand("copy", true, i1?.email);
                toast.success("Sao chép thành công !");
              }
            }}
          >
            <CopyOutlined />
          </button>
        </div>
      ),
      children: (
        <Collapse
          items={i1?.childs?.map((i2: any) => {
            return {
              key: i2?._id,
              label: (
                <div
                  style={{ color: getColor(i2?.affiliateLevel) }}
                  className="d-flex align-items-center justify-content-between"
                >
                  <div className="fw-semibold">
                    {i2?.email + " " + i2?.fullName}
                  </div>
                  <button
                    className="btn border border-0 p-0"
                    onClick={async () => {
                      if ("clipboard" in navigator) {
                        await navigator.clipboard.writeText(i2?.email);
                        toast.success("Sao chép thành công !");
                      } else {
                        document.execCommand("copy", true, i2?.email);
                        toast.success("Sao chép thành công !");
                      }
                    }}
                  >
                    <CopyOutlined />
                  </button>
                </div>
              ),
              children: (
                <Collapse
                  items={i2?.childs?.map((i3: any) => {
                    return {
                      key: i3?._id,
                      label: (
                        <div
                          style={{ color: getColor(i3?.affiliateLevel) }}
                          className="d-flex align-items-center justify-content-between"
                        >
                          <div className="fw-semibold">
                            {i3?.email + " " + i3?.fullName}
                          </div>
                          <button
                            className="btn border border-0 p-0"
                            onClick={async () => {
                              if ("clipboard" in navigator) {
                                await navigator.clipboard.writeText(i3?.email);
                                toast.success("Sao chép thành công !");
                              } else {
                                document.execCommand("copy", true, i3?.email);
                                toast.success("Sao chép thành công !");
                              }
                            }}
                          >
                            <CopyOutlined />
                          </button>
                        </div>
                      ),
                      children: (
                        <Collapse
                          items={i3?.childs?.map((i4: any) => {
                            return {
                              key: i4?._id,
                              label: (
                                <div
                                  style={{
                                    color: getColor(i4?.affiliateLevel),
                                  }}
                                  className="d-flex align-items-center justify-content-between"
                                >
                                  <div className="fw-semibold">
                                    {i4?.email + " " + i4?.fullName}
                                  </div>
                                  <button
                                    className="btn border border-0 p-0"
                                    onClick={async () => {
                                      if ("clipboard" in navigator) {
                                        await navigator.clipboard.writeText(
                                          i4?.email
                                        );
                                        toast.success("Sao chép thành công !");
                                      } else {
                                        document.execCommand(
                                          "copy",
                                          true,
                                          i4?.email
                                        );
                                        toast.success("Sao chép thành công !");
                                      }
                                    }}
                                  >
                                    <CopyOutlined />
                                  </button>
                                </div>
                              ),
                              children: (
                                <Collapse
                                  items={i4?.childs?.map((i5: any) => {
                                    return {
                                      key: i5?._id,
                                      label: (
                                        <div
                                          style={{
                                            color: getColor(i5?.affiliateLevel),
                                          }}
                                          className="d-flex align-items-center justify-content-between"
                                        >
                                          <div className="fw-semibold">
                                            {i5?.email + " " + i5?.fullName}
                                          </div>
                                          <button
                                            className="btn border border-0 p-0"
                                            onClick={async () => {
                                              if ("clipboard" in navigator) {
                                                await navigator.clipboard.writeText(
                                                  i5?.email
                                                );
                                                toast.success(
                                                  "Sao chép thành công !"
                                                );
                                              } else {
                                                document.execCommand(
                                                  "copy",
                                                  true,
                                                  i5?.email
                                                );
                                                toast.success(
                                                  "Sao chép thành công !"
                                                );
                                              }
                                            }}
                                          >
                                            <CopyOutlined />
                                          </button>
                                        </div>
                                      ),
                                      children: (
                                        <Collapse
                                          items={i5?.childs?.map((i6: any) => {
                                            return {
                                              key: i6?._id,
                                              label: (
                                                <div
                                                  style={{
                                                    color: getColor(
                                                      i6?.affiliateLevel
                                                    ),
                                                  }}
                                                  className="d-flex align-items-center justify-content-between"
                                                >
                                                  <div className="fw-semibold">
                                                    {i6?.email +
                                                      " " +
                                                      i6?.fullName}
                                                  </div>
                                                  <button
                                                    className="btn border border-0 p-0"
                                                    onClick={async () => {
                                                      if (
                                                        "clipboard" in navigator
                                                      ) {
                                                        await navigator.clipboard.writeText(
                                                          i6?.email
                                                        );
                                                        toast.success(
                                                          "Sao chép thành công !"
                                                        );
                                                      } else {
                                                        document.execCommand(
                                                          "copy",
                                                          true,
                                                          i6?.email
                                                        );
                                                        toast.success(
                                                          "Sao chép thành công !"
                                                        );
                                                      }
                                                    }}
                                                  >
                                                    <CopyOutlined />
                                                  </button>
                                                </div>
                                              ),
                                              children: (
                                                <Collapse
                                                  items={i6?.childs?.map(
                                                    (i7: any) => {
                                                      return {
                                                        key: i7?._id,
                                                        label: (
                                                          <div
                                                            style={{
                                                              color: getColor(
                                                                i7?.affiliateLevel
                                                              ),
                                                            }}
                                                            className="d-flex align-items-center justify-content-between"
                                                          >
                                                            <div className="fw-semibold">
                                                              {i7?.email +
                                                                " " +
                                                                i7?.fullName}
                                                            </div>
                                                            <button
                                                              className="btn border border-0 p-0"
                                                              onClick={async () => {
                                                                if (
                                                                  "clipboard" in
                                                                  navigator
                                                                ) {
                                                                  await navigator.clipboard.writeText(
                                                                    i7?.email
                                                                  );
                                                                  toast.success(
                                                                    "Sao chép thành công !"
                                                                  );
                                                                } else {
                                                                  document.execCommand(
                                                                    "copy",
                                                                    true,
                                                                    i7?.email
                                                                  );
                                                                  toast.success(
                                                                    "Sao chép thành công !"
                                                                  );
                                                                }
                                                              }}
                                                            >
                                                              <CopyOutlined />
                                                            </button>
                                                          </div>
                                                        ),
                                                        children: (
                                                          <Collapse
                                                            items={i7?.childs?.map(
                                                              (i8: any) => {
                                                                return {
                                                                  key: i8?._id,
                                                                  label: (
                                                                    <div
                                                                      style={{
                                                                        color:
                                                                          getColor(
                                                                            i8?.affiliateLevel
                                                                          ),
                                                                      }}
                                                                      className="d-flex align-items-center justify-content-between"
                                                                    >
                                                                      <div className="fw-semibold">
                                                                        {i8?.email +
                                                                          " " +
                                                                          i8?.fullName}
                                                                      </div>
                                                                      <button
                                                                        className="btn border border-0 p-0"
                                                                        onClick={async () => {
                                                                          if (
                                                                            "clipboard" in
                                                                            navigator
                                                                          ) {
                                                                            await navigator.clipboard.writeText(
                                                                              i8?.email
                                                                            );
                                                                            toast.success(
                                                                              "Sao chép thành công !"
                                                                            );
                                                                          } else {
                                                                            document.execCommand(
                                                                              "copy",
                                                                              true,
                                                                              i8?.email
                                                                            );
                                                                            toast.success(
                                                                              "Sao chép thành công !"
                                                                            );
                                                                          }
                                                                        }}
                                                                      >
                                                                        <CopyOutlined />
                                                                      </button>
                                                                    </div>
                                                                  ),
                                                                  children: (
                                                                    <Collapse
                                                                      items={i8?.childs?.map(
                                                                        (
                                                                          i9: any
                                                                        ) => {
                                                                          return {
                                                                            key: i9?._id,
                                                                            label:
                                                                              (
                                                                                <div
                                                                                  style={{
                                                                                    color:
                                                                                      getColor(
                                                                                        i9?.affiliateLevel
                                                                                      ),
                                                                                  }}
                                                                                  className="d-flex align-items-center justify-content-between"
                                                                                >
                                                                                  <div className="fw-semibold">
                                                                                    {i9?.email +
                                                                                      " " +
                                                                                      i9?.fullName}
                                                                                  </div>
                                                                                  <button
                                                                                    className="btn border border-0 p-0"
                                                                                    onClick={async () => {
                                                                                      if (
                                                                                        "clipboard" in
                                                                                        navigator
                                                                                      ) {
                                                                                        await navigator.clipboard.writeText(
                                                                                          i9?.email
                                                                                        );
                                                                                        toast.success(
                                                                                          "Sao chép thành công !"
                                                                                        );
                                                                                      } else {
                                                                                        document.execCommand(
                                                                                          "copy",
                                                                                          true,
                                                                                          i9?.email
                                                                                        );
                                                                                        toast.success(
                                                                                          "Sao chép thành công !"
                                                                                        );
                                                                                      }
                                                                                    }}
                                                                                  >
                                                                                    <CopyOutlined />
                                                                                  </button>
                                                                                </div>
                                                                              ),
                                                                          };
                                                                        }
                                                                      )}
                                                                    />
                                                                  ),
                                                                };
                                                              }
                                                            )}
                                                          />
                                                        ),
                                                      };
                                                    }
                                                  )}
                                                />
                                              ),
                                            };
                                          })}
                                        />
                                      ),
                                    };
                                  })}
                                />
                              ),
                            };
                          })}
                        />
                      ),
                    };
                  })}
                />
              ),
            };
          })}
        />
      ),
    };
  });

  const items: CollapseProps["items"] = data?.map((i: any) => {
    return {
      key: i?._id,
      label: (
        <div
          style={{ color: getColor(i?.affiliateLevel) }}
          className="d-flex align-items-center justify-content-between"
        >
          <div className="fw-semibold">{i?.email + " " + i?.fullName}</div>
          <button
            className="btn border border-0 p-0"
            onClick={async () => {
              if ("clipboard" in navigator) {
                await navigator.clipboard.writeText(i?.email);
                toast.success("Sao chép thành công !");
              } else {
                document.execCommand("copy", true, i?.email);
                toast.success("Sao chép thành công !");
              }
            }}
          >
            <CopyOutlined />
          </button>
        </div>
      ),
      children: <Collapse items={items1} />,
    };
  });

  const onChange = (key: string | string[]) => {
    console.log(key);
    // getTree(key);
  };

  return (
    <div className="col col-md-9 mt-5">
      <div className="bg-white p-3 rounded-2 container-right">
        <div className="txt-title-right">Mời bạn bè</div>
        <div className="border w-100" />
        <div className="title-input pt-4 d-flex">Mã giới thiệu</div>
        <Input
          className="mt-1 input-bank"
          value={isUserData?.data?.myRef}
          style={{
            height: 40,
          }}
          suffix={
            <button
              className="btn border border-0 p-0"
              onClick={async () => {
                if ("clipboard" in navigator) {
                  await navigator.clipboard.writeText(isUserData?.data?.myRef);
                  toast.success("Sao chép thành công !");
                } else {
                  document.execCommand("copy", true, isUserData?.data?.myRef);
                  toast.success("Sao chép thành công !");
                }
              }}
            >
              <Tooltip title="Copy">
                <CopyOutlined style={{ color: "#626262" }} />
              </Tooltip>
            </button>
          }
        />
        <div className="title-input pt-4 d-flex">Link đăng ký</div>
        <Input
          className="mt-1 input-bank"
          value={`ffe.com.vn/sign-up?ref=${isUserData?.data?.myRef}`}
          style={{
            height: 40,
          }}
          suffix={
            <button
              className="btn border border-0 p-0"
              onClick={async () => {
                if ("clipboard" in navigator) {
                  await navigator.clipboard.writeText(
                    `ffe.com.vn/sign-up?ref=${isUserData?.data?.myRef}`
                  );
                  toast.success("Sao chép thành công !");
                } else {
                  document.execCommand(
                    "copy",
                    true,
                    `ffe.com.vn/sign-up?ref=${isUserData?.data?.myRef}`
                  );
                  toast.success("Sao chép thành công !");
                }
              }}
            >
              <Tooltip title="Copy">
                <CopyOutlined style={{ color: "#626262" }} />
              </Tooltip>
            </button>
          }
        />
      </div>
      <>
        <div className="row pt-4 ps-3">
          {level.map((item, index) => {
            return (
              <div
                key={item.id}
                className="d-flex align-items-center"
                style={{
                  width: 200,
                  margin: 8,
                }}
              >
                <div
                  style={{
                    backgroundColor: item.color,
                    width: 30,
                    height: 30,
                    minWidth: 30,
                    marginRight: 8,
                  }}
                />
                <div
                  style={{
                    color: item.color,
                  }}
                >
                  {item.text}
                </div>
              </div>
            );
          })}
        </div>
        <Collapse className="ms-3 mt-2" onChange={onChange} items={items} />
      </>
    </div>
  );
};

export default Share;
