import { Input, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import formatNumber, { formatMoneyText } from "src/common/FormatNumber";
import { getListBank } from "src/components/Features/user/userSlice";
import { withdrawCreate } from "src/components/Features/wallet/walletSlice";
import { useAppDispatch } from "src/components/Redux/store";
import { ModalProps } from "src/constants/Interface";

const ModalWithdraw = (props: any) => {
  const { isOpen, isClose } = props;
  const dispatch = useAppDispatch();
  const [data, setData] = useState([]);
  const [money, setMoney] = useState("");
  const [chooseBank, setChooseBank] = useState<any>(null);

  // useEffect(() => {
  //   getData();
  // }, []);

  const getData = async () => {
    const resp: any = await dispatch(getListBank());
    console.log("resp list bank---", resp);
    if (resp.payload.status === true) {
      const data = resp.payload.data || [];
      const mapData = data.map(
        (bank: { userBankID: number; bankName: string }) => {
          return {
            value: bank?.userBankID,
            label: bank?.bankName,
            ...bank,
          };
        }
      );
      setData(mapData);
    }
  };

  const onSubmit = async () => {
    const params = {
      bankName: chooseBank?.bankName,
      bankCode: chooseBank?.bankCode,
      bankNumber: chooseBank?.bankNumber,
      bankAccountName: chooseBank?.nameOfBankCardHolder,
      amount: +money.split(".").join(""),
    };
    const resp: any = await dispatch(withdrawCreate(params));
    if (resp?.payload?.status === true) {
      isClose();
      toast.success("Tạo lệnh rút tiền thành công!");
    }
  };

  return (
    <Modal
      title={"Rút tiền"}
      open={isOpen}
      footer={null}
      closeIcon={null}
      onCancel={() => isClose()}
      width={480}
      maskClosable={false}
    >
      <div className="title-input pt-3 d-flex">
        Chọn ngân hàng của bạn <div className="text-danger ps-1"> *</div>
      </div>
      <Select
        showSearch={false}
        onClick={getData}
        style={{ width: "100%", height: 40 }}
        className="mt-1"
        placeholder={"Chọn ngân hàng"}
        optionFilterProp="children"
        onChange={(value, opt) => setChooseBank(opt)}
        filterOption={(input, option: any) =>
          (option?.label ?? "").includes(input)
        }
        filterSort={(optionA: any, optionB: any) =>
          (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase())
        }
        options={data}
      />

      {chooseBank && (
        <>
          <div className="title-input pt-3">Số tài khoản</div>
          <Input
            placeholder={"Nhập số tiền"}
            className="mt-1"
            value={chooseBank?.bankNumber}
            style={{
              height: 40,
            }}
          />
          <div className="title-input pt-3">Tên người thụ hưởng</div>
          <Input
            placeholder={"Nhập số tiền"}
            className="mt-1"
            value={chooseBank?.nameOfBankCardHolder}
            style={{
              height: 40,
            }}
          />
          <div className="title-input pt-3">Chi nhánh</div>
          <Input
            placeholder={"Nhập số tiền"}
            className="mt-1"
            value={chooseBank?.BankBranch}
            style={{
              height: 40,
            }}
          />
        </>
      )}

      <div className="title-input pt-3 d-flex">
        Số tiền muốn rút <div className="text-danger ps-1"> *</div>
      </div>
      <Input
        placeholder={"Nhập số tiền"}
        className="mt-1"
        value={money}
        onChange={(e) => setMoney(formatMoneyText(e.target.value))}
        style={{
          height: 40,
        }}
      />
      <div className="title-input pt-3 d-flex">Phí rút tiền</div>
      <Input
        placeholder={"Nhập số tiền"}
        className="mt-1"
        value={formatNumber((+money.split(".").join("") * 3) / 100)}
        style={{
          height: 40,
        }}
      />

      <div className="text-secondary pt-2">
        Số tiền tối thiểu là 100.000đ với 3% phí rút tiền
      </div>
      <div className="d-flex justify-content-end mt-4 gap-2">
        <button className="btn-close-recharge" onClick={() => isClose()}>
          Hủy
        </button>
        <button className="btn-submit-recharge" onClick={onSubmit}>
          Xác nhận
        </button>
      </div>
    </Modal>
  );
};

export default ModalWithdraw;
