import { Input, Modal } from "antd";
import React, { useState } from "react";
import { toast } from "react-toastify";
import formatNumber, { formatMoneyText } from "src/common/FormatNumber";
import {
  walletSendVoucher,
  walletVerifyVoucher,
} from "src/components/Features/wallet/walletSlice";
import { useAppDispatch } from "src/components/Redux/store";
import useGetProfile from "src/hooks/useGetProfile";

function ModalTransferVoucher(props: any) {
  const { isOpen, isClose } = props;
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");
  const [numberVoucher, setNumberVoucher] = useState("");
  const [code, setCode] = useState("");
  const [step, setStep] = useState("1");
  const isUserData: any = useGetProfile();

  const onSubmitStep1 = async () => {
    const params = {
      email,
      numVoucher: +numberVoucher.split(".").join(""),
    };
    const resp: any = await dispatch(walletSendVoucher(params));
    console.log("resp", resp);
    if (resp?.payload?.status) {
      toast.success(
        "Mã OTP đã được gửi về Email của bạn,vui lòng nhập kiểm tra !"
      );
      setStep("2");
    }
  };

  const onTransfer = async () => {
    const params = {
      email,
      code: code,
    };
    const resp: any = await dispatch(walletVerifyVoucher(params));
    console.log("resp", resp);
    if (resp?.payload?.status) {
      toast.success("Thành công !");
      setStep("1");
      isClose();
    }
  };

  return (
    <Modal
      title={"Chuyển voucher"}
      open={isOpen}
      footer={null}
      closeIcon={null}
      onCancel={() => isClose()}
      width={480}
      maskClosable={false}
    >
      {step === "1" ? (
        <>
          <div className="title-input pt-3 d-flex">
            Nhập email người nhận <div className="text-danger ps-1"> *</div>
          </div>
          <Input
            placeholder={"Nhập email"}
            className="mt-1"
            value={email}
            style={{
              height: 40,
            }}
            onChange={(val) => setEmail(val.target.value)}
          />
          <div className="title-input pt-3 d-flex">
            Nhập số voucher cần chuyển{" "}
            <div className="text-danger ps-1"> *</div>
          </div>
          <Input
            placeholder={"Nhập số voucher"}
            className="mt-1"
            value={numberVoucher}
            style={{
              height: 40,
            }}
            onChange={(val) =>
              setNumberVoucher(formatMoneyText(val.target.value))
            }
          />
          <div className="d-flex justify-content-end mt-2">
            Số voucher khả dụng:
            <div className="text-danger fw-semibold ms-1">
              {formatNumber(isUserData?.data?.walletInfo?.amountVoucher)}đ
            </div>
          </div>
          <div className="d-flex justify-content-end mt-4 gap-2">
            <button className="btn-close-recharge" onClick={() => isClose()}>
              Hủy
            </button>
            <button className="btn-submit-recharge" onClick={onSubmitStep1}>
              Tiếp tục
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="title-input pt-3 d-flex">
            Nhập OTP <div className="text-danger ps-1"> *</div>
          </div>
          <Input
            placeholder={"Nhập OTP"}
            className="mt-1"
            value={code}
            style={{
              height: 40,
            }}
            onChange={(val) => setCode(val.target.value)}
          />
          <div className="d-flex justify-content-end mt-4 gap-2">
            <button className="btn-close-recharge" onClick={() => isClose()}>
              Hủy
            </button>
            <button className="btn-submit-recharge" onClick={onTransfer}>
              Xác nhận
            </button>
          </div>
        </>
      )}
    </Modal>
  );
}

export default ModalTransferVoucher;
