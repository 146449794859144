import { toast } from 'react-toastify';
import client from './Client';

const Accumulative = {
  async getInvestmentList() {
    try {
      const res = await client.post(`/api/accumulative/investment-list`);
      return res;
    } catch (err) {
      toast.error(err?.message);
    }
  },

  async accumulativeCreate(params: any) {
    try {
      const res = await client.post(
        `/api/accumulative/accumulative-create`,
        params,
      );
      return res;
    } catch (err) {
      toast.error(err?.message);
    }
  },

  async getAccumulativeList() {
    try {
      const res = await client.post(`/api/accumulative/accumulative-list`);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async getAccumulativeProfitTransaction(params: any) {
    try {
      const res = await client.post(
        `/api/accumulative/profit-transaction`,
        params,
      );
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async accumulativeWithdraw(params: any) {
    try {
      const res = await client.post(
        `/api/accumulative/accumulative-withdraw`,
        params,
      );
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async accumulativeProfitWithdraw(params: any) {
    try {
      const res = await client.post(
        `/api/accumulative/profit-withdraw`,
        params,
      );
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },
};

export default Accumulative;
