import React, { memo, useEffect, useState } from "react";
import Slider from "react-slick";
import { useAppDispatch } from "src/components/Redux/store";
import { getNews, getProducts } from "src/components/Features/home/homeSlice";
import { ListTitleProductHome } from "src/constants/Array";
import { Card } from "antd";
import { API_URL } from "src/controllers/Client";
import { useLocation, useNavigate } from "react-router-dom";
import HomeImages from "src/assets/Images/Home";
import { useSelector } from "react-redux";
import { selectLoading } from "src/components/Redux/selector";
import Lottie from "react-lottie";
import loading from "src/assets/Lotties/loading.json";
import formatNumber from "src/common/FormatNumber";
import NavBar from "../Navbar";

const ProductNotRegister = () => {
  const { state } = useLocation();
  const dispatch = useAppDispatch();
  const isLoading = useSelector(selectLoading);
  const navigate = useNavigate();
  const [newFeed, setNewFeed] = useState([]);
  const [currentListProduct, setCurrentListProduct] = useState(0);
  const [dataProducts, setDataProducts] = useState([]);

  useEffect(() => {
    getNewFeed();
    getProduct("");
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const getNewFeed = async () => {
    const resp: any = await dispatch(getNews({}));
    // console.log("resp", resp);
    if (resp.payload.status === true) {
      const data = resp?.payload?.data;
      const sliceData = data.slice(0, 2);
      setNewFeed(data);
    }
  };

  const getProduct = async (type: string) => {
    const params = {
      search: {
        // productType: type,
        productName: state?.searchQuery,
      },
      limit: 1000,
    };
    const paramsSearch = {
      search: {
        productType: type,
        productName: state?.searchQuery,
      },
      limit: 1000,
    };
    const resp: any = await dispatch(getProducts(type ? paramsSearch : params));
    console.log("resp product 123---", resp);
    const data = resp?.payload?.data;
    setDataProducts(data);
  };

  return (
    <div className="container-home">
      <NavBar />
      <div className="bg-white pt-4 pb-5">
        <div className="container-xxl">
          <Slider {...settings}>
            {newFeed.map((item: any, index: number) => {
              return (
                <button
                  className="btn border border-0 p-0"
                  onClick={() =>
                    navigate("/new-detail", { state: { item: item } })
                  }
                >
                  <img
                    key={index}
                    src={`${API_URL}${item?.urlImage}`}
                    className="img-newfeed"
                    alt=""
                  />
                </button>
              );
            })}
          </Slider>
        </div>
      </div>
      <div className="container-xxl d-flex pt-5 w-100">
        <div
          className="bg-white p-3 rounded d-none d-sm-block"
          style={{ height: 324 }}
        >
          <div className="txt-title-left">Danh mục</div>
          <div className="d-flex flex-column align-items-start">
            {ListTitleProductHome.map((item, index) => {
              let checked = false;
              if (index === currentListProduct) {
                checked = true;
              }
              return (
                <button
                  onClick={() => {
                    getProduct(item.type);
                    setCurrentListProduct(index);
                  }}
                  className={
                    checked ? "btn-left-product" : "btn-left-block-product"
                  }
                  key={item.id}
                >
                  {item.text}
                </button>
              );
            })}
          </div>
        </div>
        <div className="bg-white ms-4 p-3 rounded w-100 mb-5">
          <div className="txt-title-left">
            {`Kết quả tìm kiếm cho "${state?.searchQuery}"`}
          </div>
          {!dataProducts.length ? (
            <div className="fw-semibold text-center pt-5">
              Không có sản phẩm nào
            </div>
          ) : (
            dataProducts.map((item: any, index) => {
              const findVideo = item?.images?.find(
                (x: { includes: (arg0: string) => any }) => !x?.includes(".mp4")
              );
              return (
                <Card
                  key={item?.productID}
                  onClick={() =>
                    navigate(`/product/detail?id=${item?.productID}`, {
                      state: { productID: item?.productID },
                    })
                  }
                  hoverable
                  className="border border-1 btn mt-4 rounded p-0 card-items"
                  cover={
                    <img
                      src={
                        findVideo
                          ? `${API_URL}${findVideo}`
                          : HomeImages.logo_product
                      }
                      className="img-product"
                    />
                  }
                >
                  <div className="txt-item-product d-block d-md-none">
                    {item?.productName.substring(0, 16) + "..."}
                  </div>
                  <div className="txt-item-product d-none d-md-block">
                    {item?.productName.substring(0, 30) + "..."}
                  </div>
                  {item?.priceCompare > 0 && (
                    <div className="txt-price-compare">
                      {formatNumber(item?.priceCompare)}đ
                    </div>
                  )}
                  <div className="txt-price">
                    {formatNumber(item?.price + item?.priceVocher)}đ
                  </div>
                  <div className="txt-sold">Đã bán: {item?.sold}</div>
                </Card>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(ProductNotRegister);
