import { Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { ModalProps } from "src/constants/Interface";
import "./Modal.css";
import { useAppDispatch } from "src/components/Redux/store";
import {
  depositCreate,
  listBankDeposit,
} from "src/components/Features/wallet/walletSlice";
import { formatMoneyText } from "src/common/FormatNumber";

const RechargeStep1 = (props: any) => {
  const dispatch = useAppDispatch();
  const { isOpen, isClose, dataStep1, dataBank } = props;
  const [money, setMoney] = useState("");

  const onSubmit = async () => {
    const params = {
      bankConfigID: dataBank[0]?.bankConfigID,
      amount: money.split(".").join(""),
    };
    const resp: any = await dispatch(depositCreate(params));
    console.log("resp deposit---", resp);
    if (resp.payload.status === true) {
      dataStep1(resp?.payload?.data);
    }
  };

  return (
    <Modal
      title={"Nạp tiền vào ví"}
      open={isOpen}
      footer={null}
      closeIcon={null}
      onCancel={() => isClose()}
      width={480}
      maskClosable={false}
    >
      <div className="d-flex gap-2">
        <img src={dataBank[0]?.logo} width={100} height={36} alt="" />
        <div>
          <div className="fw-semibold">
            {dataBank[0]?.bankShortName},{dataBank[0]?.bankCode}
          </div>
          <div className="text-secondary">{dataBank[0]?.bankName}</div>
        </div>
      </div>
      <div className="title-input pt-3 d-flex">
        Số tiền cần nạp <div className="text-danger ps-1"> *</div>
      </div>
      <Input
        placeholder={"Nhập số tiền"}
        className="mt-1"
        value={money}
        onChange={(e) => setMoney(formatMoneyText(e.target.value))}
        style={{
          height: 40,
        }}
      />

      <div className="d-flex justify-content-end mt-4 gap-2">
        <button className="btn-close-recharge" onClick={() => isClose()}>
          Hủy
        </button>
        <button className="btn-submit-recharge" onClick={onSubmit}>
          Tiếp tục
        </button>
      </div>
    </Modal>
  );
};

export default RechargeStep1;
