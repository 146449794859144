import React, { memo, useState } from "react";
import AuthImages from "src/assets/Images/Auth";
import HomeImages from "src/assets/Images/Home";
import "./Auth.css";
import { Input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useAppDispatch } from "src/components/Redux/store";
import { login, setUserLogin } from "src/components/Features/user/userSlice";
import { useSelector } from "react-redux";
import { selectLoading } from "src/components/Redux/selector";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Types from "src/constants/Types";

const Auth = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector(selectLoading);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { state } = useLocation();

  const onLogin = async () => {
    const params = {
      email,
      password,
    };
    const resp: any = await dispatch(login(params));
    if (resp?.payload?.status === true) {
      dispatch(setUserLogin(resp.payload));
      Cookies.set(Types.ACCESS_TOKEN, resp.payload?.accessToken);
      if (state?.productID) {
        setTimeout(() => {
          navigate(`/product/detail?id=${state?.productID}`, {
            state: { productID: state?.productID },
          });
        }, 500);
      }
    }
  };

  return (
    <div>
      <div className="container-xxl py-4">
        <button
          className="btn border border-0 p-0"
          onClick={() => navigate("/")}
        >
          <img src={AuthImages.logo} width={154} height={48} />
        </button>
      </div>
      <div className="main py-5">
        <div className="container-xxl d-none d-sm-block">
          <div className="row align-items-center">
            <div className="col-md-7">
              {/* <div className="txt-title-auth">
                Sàn thương mại điện tử khởi nghiệp
              </div> */}
              <img src={HomeImages.logo} width={386} height={120} />
            </div>
            <div className="rounded p-4 bg-white col-md-4">
              <div className="txt-title-right">Đăng nhập</div>
              <div className="title-input pt-4">Email</div>
              <Input
                placeholder="email@gmail.com"
                className="mt-1"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{
                  height: 40,
                }}
              />
              <div className="title-input pt-3">Mật khẩu</div>
              <Input.Password
                placeholder="Mật khẩu đăng nhập"
                className="mt-1"
                value={password}
                style={{
                  height: 40,
                }}
                onChange={(e) => setPassword(e.target.value)}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
              <div
                className="d-flex justify-content-end pt-2 btn border border-0 p-0 txt-forgot"
                onClick={() => navigate("/forgot-password")}
              >
                Quên mật khẩu ?
              </div>
              <button
                className="btn-login"
                onClick={onLogin}
                disabled={!email || !password}
              >
                {isLoading ? (
                  <div
                    className="spinner-border spinner-border-sm text-light"
                    role="status"
                  ></div>
                ) : (
                  `Đăng nhập`
                )}
              </button>
              <button
                className="btn-register"
                onClick={() => navigate("/sign-up")}
              >
                Đăng ký
              </button>
            </div>
          </div>
        </div>

        <div className="d-block d-sm-none d-flex flex-column align-items-center">
          <img src={HomeImages.logo} width={160} height={50} />
          <div className="rounded p-4 w-100">
            <div className="title-input-sm">Email</div>
            <Input
              placeholder="email@gmail.com"
              className="mt-1"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{
                height: 40,
              }}
            />
            <div className="title-input-sm pt-3">Mật khẩu</div>
            <Input.Password
              placeholder="Mật khẩu đăng nhập"
              className="mt-1"
              value={password}
              style={{
                height: 40,
              }}
              onChange={(e) => setPassword(e.target.value)}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
            <button
              className="txt-forgot-sm"
              onClick={() => navigate("/forgot-password")}
            >
              Quên mật khẩu ?
            </button>
            <button
              className="btn-login-sm"
              onClick={onLogin}
              disabled={!email || !password}
            >
              {isLoading ? (
                <div
                  className="spinner-border spinner-border-sm text-danger"
                  role="status"
                ></div>
              ) : (
                `Đăng nhập`
              )}
            </button>
            <button
              className="btn-register"
              onClick={() => navigate("/sign-up")}
            >
              Đăng ký
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Auth);
