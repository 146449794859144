const NavbarImages = {
    account_balance_wallet: require('./account_balance_wallet.png'),
    account_balance: require('./account_balance.png'),
    home_pin: require('./home_pin.png'),
    logout: require('./logout.png'),
    person: require('./person.png'),
    savings: require('./savings.png'),
    share: require('./share.png'),
    shopping_cart: require('./shopping_cart.png'),
}

export default NavbarImages