import { RightOutlined } from "@ant-design/icons";
import moment from "moment";
import React, { memo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HomeImages from "src/assets/Images/Home";
import ProductImages from "src/assets/Images/Product";
import formatNumber from "src/common/FormatNumber";
import { setNavigateProfile } from "src/components/Features/user/userSlice";
import { useAppDispatch } from "src/components/Redux/store";
import "./OrderDetail.css";
import { API_URL } from "src/controllers/Client";

const OrderDetail = () => {
  const { state } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const data = state?.data || {};

  const renderType: any = {
    New: "Chờ xác nhận",
    Pending: "Đang giao",
    Success: "Hoàn thành",
    Cancel: "Đã hủy",
  };

  const renderColor: any = {
    New: "#FFA500",
    Pending: "#EE4D2D",
    Success: "#5EBB25",
    Cancel: "#CA2D35",
  };

  return (
    <div className="col col-md-9 mt-5">
      <div className="d-none d-md-block">
        <div className="d-flex gap-2 ps-4">
          <button
            className="btn border border-0 p-0 text-danger"
            onClick={() => {
              dispatch(setNavigateProfile(false));
            }}
          >
            Trang chủ <RightOutlined />
          </button>
          <button
            className="btn border border-0 p-0 text-danger"
            onClick={() => navigate(-1)}
          >
            Tài khoản <RightOutlined />
          </button>
          <button
            className="btn border border-0 p-0 text-danger"
            onClick={() => navigate(-1)}
          >
            Đơn hàng <RightOutlined />
          </button>
          <button
            className="text-danger border border-0 btn p-0"
            onClick={() => navigate(-1)}
          >
            Chờ xác nhận <RightOutlined />
          </button>
          <div>Đơn hàng {data?.orderID}</div>
        </div>
      </div>

      <div className="bg-white p-3 mt-2 rounded-1 container-right">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex gap-2">
            <div className="txt-order-id">Đơn hàng {data?.orderID}</div>
            <div className="txt-order-time">
              Đặt ngày {moment(data?.createdAt).format("DD-MM-YYYY HH:mm:ss")}
            </div>
          </div>
          <div
            className="text-white rounded-3 py-1 px-2 text-center"
            style={{
              background: renderColor[data?.status],
            }}
          >
            {renderType[data?.status]}
          </div>
        </div>
        <div className="border w-100 my-3" />
        <div className="txt-order-id">Địa chỉ nhận hàng</div>
        <div className="border w-100 my-3" />
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <div className="d-flex align-items-center">
              <div className="txt-name-address">{data?.shippingName}</div>
              <div className="txt-name-address mx-1">•</div>
              <div className="txt-name-address">
                {data?.shippingPhoneNumber}
              </div>
            </div>
            <div className="txt-des-address pt-1 text-start">
              {data?.shippingAddress},{data?.shippingWardName},
              {data?.shippingDistrictName},{data?.shippingProvinceName}
            </div>
          </div>
          {/* <button className="btn border border-0 p-0 text-danger">Sửa</button> */}
        </div>
        <div className="border w-100 my-3" />
        <div className="txt-order-id">Sản phẩm</div>
        <div className="border w-100 my-3" />
        <div className="py-3 row col col-lg-12">
          <div className="txt-ul col col-lg-6">Sản phẩm</div>
          <div className="txt-ul col col-lg-2">Đơn giá</div>
          <div className="txt-ul col col-lg-2">Số lượng</div>
          <div className="txt-ul col col-lg-2">Thành tiền</div>
        </div>
        <div className="border w-100 my-3" />
        {data?.orderDetails?.map((item: any) => {
          const findVideo = item?.images?.find(
            (x: { includes: (arg0: string) => any }) => !x?.includes(".mp4")
          );
          return (
            <div className="mb-3 row col col-lg-12" key={item?.productID}>
              <div className="d-flex col col-lg-6">
                <img
                  src={
                    findVideo
                      ? `${API_URL}${findVideo}`
                      : HomeImages.logo_product
                  }
                  width={92}
                  height={92}
                  style={{
                    minWidth: 92,
                  }}
                />
                <div className="ps-3 txt-product-name d-none d-md-block">
                  {item?.productName}
                </div>
              </div>
              <div className="col col-lg-2 d-flex align-items-center">
                {formatNumber(item?.price)}đ
              </div>
              <div className="col col-lg-2 d-flex align-items-center">
                {item?.quantity}
              </div>
              <div className="col col-lg-2 d-flex align-items-center txt-total-price">
                {formatNumber(item?.price * item?.quantity)}đ
              </div>
            </div>
          );
        })}
        <div className="border w-100 my-3" />
        <div className="txt-order-id">Phương thức thanh toán</div>
        <div className="border w-100 my-3" />
        <div className="container-payment-method">
          <div className="d-flex gap-2">
            <img
              src={
                data?.paymentMethod === "Bank"
                  ? ProductImages.account_balance
                  : ProductImages.account_balance_wallet
              }
              width={24}
              height={24}
              alt=""
            />
            <div>
              {data?.paymentMethod === "Bank"
                ? "Thanh toán chuyển khoản"
                : "Thanh toán bằng ví cá nhân"}
            </div>
          </div>
        </div>
        <div className="d-flex gap-2 py-4 justify-content-end">
          <div className="txt-order-id">Thành tiền</div>
          <div className="txt-product-detail-price">
            {formatNumber(data?.total + data?.totalVoucher)}đ
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(OrderDetail);
