import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getNews } from "src/components/Features/home/homeSlice";
import { useAppDispatch } from "src/components/Redux/store";
import { API_URL } from "src/controllers/Client";

const Notification = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [newFeed, setNewFeed] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const resp: any = await dispatch(getNews({}));
    if (resp.payload.status === true) {
      const data = resp?.payload?.data;
      setNewFeed(data);
    }
  };
  return (
    <div className="container-home">
      <div className="container-xxl py-5">
        <div className="bg-white p-3 rounded-1">
          <div className="txt-title-right ps-3">
            Tất cả thông báo ({newFeed?.length})
          </div>
          <div className="border w-100 my-3" />
          {newFeed.map((item: any) => (
            <button
              onClick={() => navigate("/new-detail", { state: { item: item } })}
              className="btn border border-0 p-0 w-100  mb-2 d-flex gap-2 p-3 rounded-3"
              style={{
                background: "#F5F5F5",
              }}
            >
              <img
                src={`${API_URL}${item?.urlImage}`}
                width={60}
                height={60}
                alt=""
              />
              <div>
                <div className="fw-semibold text-start">{item?.title}</div>
                <div
                  className="text-secondary text-start"
                  style={{
                    fontSize: 12,
                  }}
                >
                  {moment(item?.createdAt).format("DD/MM/YYYY HH:mm:ss")}
                </div>
              </div>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Notification;
