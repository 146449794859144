import { RightOutlined } from "@ant-design/icons";
import React from "react";
import { useNavigate } from "react-router-dom";

const Guide = () => {
  const navigate = useNavigate();
  return (
    <div className="container-home">
      <div className="container-xxl py-5">
        <div className="d-flex align-items-center gap-2">
          <button
            className="btn border border-0 p-0 text-danger"
            onClick={() => navigate(-1)}
          >
            Trang chủ <RightOutlined />
          </button>
          <div>Hướng dẫn nạp rút tiền</div>
        </div>
        <div className="bg-white rounded-1 p-3 mt-3 mb-5">
          <div className="txt-title">HƯỚNG DẪN NẠP VÀ RÚT TIỀN</div>
          <div className="border w-100 my-3" />
          <div className="fw-semibold">Bước 1:</div>
          <div>· Chọn tích lũy</div>
          <div className="fw-semibold">Bước 2:</div>
          <div>· * Nhập số tiền bạn muốn tích luỹ và bấm xác nhận</div>
          <div>
            · * Lưu ý: Số tiền tích luỹ tối thiểu là 200.000đ và tối đa là
            500.000.000đ
          </div>
          <div className="fw-semibold">Bước 3:</div>
          <div>· * Chọn kỳ hạn tích luỹ và chờ hệ thống FFE xác nhận</div>
          <div>
            · * Lợi nhuận sẽ được tính từ thời gian hệ thống FFE nhận được tiền
          </div>
        </div>
      </div>
    </div>
  );
};

export default Guide;
