const HomeImages = {
    logo: require('./logo.png'),
    home: require('./home.png'),
    login: require('./login.png'),
    notifications: require('./notifications.png'),
    person_add: require('./person_add.png'),
    icon_search: require('./icon_search.png'),
    icon_cart: require('./icon_cart.png'),
    logo_product: require('./logo_product.png'),
    shopping_cart: require('./shopping_cart.png'),
}

export default HomeImages