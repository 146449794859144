import React, { memo, useEffect, useState } from "react";
import "./Wallet.css";
import formatNumber from "src/common/FormatNumber";
import { ListStatusWallet } from "src/constants/Array";
import { useAppDispatch } from "src/components/Redux/store";
import {
  listBankDeposit,
  transactionCancel,
  transactionList,
} from "src/components/Features/wallet/walletSlice";
import { Dropdown, MenuProps, Spin, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import ProfileImages from "src/assets/Images/Profile";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import momentBirthday from "src/common/Moment";
import moment from "moment";
import { CloseCircleFilled, InfoCircleFilled } from "@ant-design/icons";
import { toast } from "react-toastify";
import RechargeStep1 from "./Components/RechargeStep1";
import RechargeStep2 from "./Components/RechargeStep2";
import RechargeStep3 from "./Components/RechargeStep3";
import ModalWithdraw from "./Components/ModalWithdraw";
import { useSelector } from "react-redux";
import { selectLoading } from "src/components/Redux/selector";
import ModalTransferVoucher from "./Components/ModalTransferVoucher";
import Cookies from "js-cookie";
import Types from "src/constants/Types";
import { getProfile, logOut } from "src/components/Features/user/userSlice";

const Wallet = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isUserData, setIsUserData] = useState<any>(null);
  const [currentStatus, setCurrentStatus] = useState(0);
  const [dataHistoty, setDataHistory] = useState([]);
  const [chooseItem, setChooseItem] = useState<any>(null);
  const [dataRechargeStep1, setDataRechargeStep1] = useState<any>(null);
  const [openRechargeStep1, setOpenRechargeStep1] = useState(false);
  const [openRechargeStep2, setOpenRechargeStep2] = useState(false);
  const [openRechargeStep3, setOpenRechargeStep3] = useState(false);
  const [openWithdraw, setOpenWithdraw] = useState(false);
  const [openTransferVoucher, setOpenTransferVoucher] = useState(false);
  const [dataBank, setDataBank] = useState<any>([]);
  const isLoading = useSelector(selectLoading);

  useEffect(() => {
    getData("Deposit");
    getUser();
  }, []);

  const getUser = async () => {
    const accessToken = Cookies.get(Types.ACCESS_TOKEN);
    if (accessToken) {
      const resp: any = await dispatch(getProfile());
      // console.log('resp user---', resp);
      if (resp?.payload?.status === true) {
        const data = resp.payload.data || {};
        setIsUserData(data);
      } else {
        Cookies.remove(Types.ACCESS_TOKEN);
        dispatch(logOut());
      }
    }
  };

  const getData = async (type: string) => {
    const params = {
      page: 1,
      limit: 60,
      search: {
        type: type,
      },
    };
    const resp: any = await dispatch(transactionList(params));
    console.log("resp trans---", resp);
    if (resp.payload.status === true) {
      const data = resp.payload.transactions || [];
      setDataHistory(data);
    }
  };

  const onCancel = async () => {
    const params = {
      walletTransactionID: chooseItem?.walletTransactionID,
    };
    const resp: any = await dispatch(transactionCancel(params));
    console.log("resp cancel----", resp);

    if (resp?.payload?.status === true) {
      toast.success("Hủy giao dịch thành công !");
    }
  };

  const getBank = async () => {
    const resp: any = await dispatch(listBankDeposit({}));
    console.log("resp bank----", resp);
    if (resp.payload.status === true) {
      const data = resp.payload.banks || [];
      setDataBank(data);
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: "#",
      dataIndex: "",
      key: "1",
      render: (_: any) => (
        <Dropdown menu={{ items }} trigger={["click"]}>
          <button
            className="btn border border-0"
            onClick={() => setChooseItem(_)}
          >
            <img src={ProfileImages.icon_dot} width={32} height={32} alt="" />
          </button>
        </Dropdown>
      ),
    },
    {
      title: "Mã giao dịch",
      dataIndex: "transactionCode",
      key: "2",
    },
    {
      title: "Tài khoản nguồn",
      dataIndex: "",
      key: "3",
      render: (item: any) => (
        <div>
          <div>{item?.userInfo?.fullName}</div>
          <div className="fw-semibold">{item?.userInfo?.email}</div>
        </div>
      ),
    },
    {
      title: "Tài khoản đích",
      dataIndex: "",
      key: "4",
      render: (item: any) => (
        <div>
          <div className="fw-semibold">{item?.bankCode}</div>
          <div className="fw-semibold">{item?.bankNumber}</div>
          <div className="fw-semibold">{item?.accountName}</div>
        </div>
      ),
    },
    {
      title: "Số tiền",
      dataIndex: "",
      key: "5",
      render: (item: any) => (
        <>
          <div>
            {item?.actualMoneyReceived &&
              item?.actualMoneyReceived !== item?.total && (
                <div className="fw-semibold text-danger text-decoration-line-through">
                  {formatNumber(item?.total)}đ
                </div>
              )}
            <div className="fw-semibold">
              {formatNumber(
                item?.actualMoneyReceived
                  ? item?.actualMoneyReceived
                  : item?.total
              )}
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Tình trạng",
      dataIndex: "status",
      key: "5",
      render: (item: any) => (
        <div
          className="text-white rounded-3 p-2"
          style={{
            background: renderColor[item],
            width: "max-content",
          }}
        >
          {renderType[item]}
        </div>
      ),
    },
    // {
    //   title: "Nội dung",
    //   dataIndex: "content",
    //   key: "5",
    //   render: (item: any) => <div>{item}</div>,
    // },
  ];

  const items: MenuProps["items"] = [
    {
      label: (
        <button
          onClick={() =>
            navigate("/wallet-detail", { state: { item: chooseItem } })
          }
          className="btn border border-0 p-0 gap-2 d-flex align-items-center py-2"
        >
          <img src={ProfileImages.error} width={20} height={20} alt="" />
          Chi tiết
        </button>
      ),
      key: "0",
    },
    {
      label: (
        <button
          onClick={() => onCancel()}
          className="btn border border-0 p-0 gap-2 d-flex align-items-center text-danger py-2"
        >
          <img src={ProfileImages.close} width={20} height={20} alt="" />
          Huỷ giao dịch
        </button>
      ),
      key: "1",
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
  };

  const renderType: any = {
    Pending: "Đang xử lý",
    Success: "Thành công",
    Cancel: "Lỗi hệ thống",
  };

  const renderColor: any = {
    Pending: "#EFA023",
    Success: "#5EBB25",
    Cancel: "#CA2D35",
  };

  const onOpenRechargeStep2 = (value: any) => {
    setOpenRechargeStep1(false);
    setDataRechargeStep1(value);
    setOpenRechargeStep2(true);
  };

  return (
    <div className="col col-md-9 mt-5">
      <div className="bg-white pt-4 rounded-2 container-right">
        <div className="d-none d-md-block">
          <div className="d-flex align-items-center justify-content-between px-3">
            <div className="txt-title-right">Ví cá nhân</div>
            <div className="d-flex align-items-center gap-2">
              <button
                className="btn-recharge"
                onClick={() => {
                  getBank();
                  setTimeout(() => {
                    setOpenRechargeStep1(true);
                  }, 300);
                }}
              >
                Nạp tiền
              </button>
              <button
                className="btn-withdraw"
                onClick={() => setOpenWithdraw(true)}
              >
                Rút tiền
              </button>
              <button
                className="btn-transfer-voucher"
                onClick={() => setOpenTransferVoucher(true)}
              >
                Chuyển voucher
              </button>
            </div>
          </div>
        </div>
        <div className="d-block d-md-none ps-3">
          <div className="txt-title-right">Ví cá nhân</div>
          <div className="d-flex align-items-center gap-2 pt-2">
            <button
              className="btn-recharge"
              onClick={() => {
                getBank();
                setTimeout(() => {
                  setOpenRechargeStep1(true);
                }, 300);
              }}
            >
              Nạp tiền
            </button>
            <button
              className="btn-withdraw"
              onClick={() => setOpenWithdraw(true)}
            >
              Rút tiền
            </button>
            <button
              className="btn-transfer-voucher"
              onClick={() => setOpenTransferVoucher(true)}
            >
              Chuyển voucher
            </button>
          </div>
        </div>
        <div className="border w-100 my-3" />
        <div className="px-4">
          <div className="row gap-3">
            <div className="rounded-2 border p-3 col">
              <div className="txt-title-wallet">Ví chính</div>
              <div className="txt-amount-wallet">
                {formatNumber(isUserData?.walletInfo?.accountBalance)}đ
              </div>
            </div>
            <div className="rounded-2 border p-3 col">
              <div className="txt-title-wallet">Ví coin</div>
              <div className="txt-amount-wallet">
                {formatNumber(isUserData?.walletInfo?.totalCoin)}
              </div>
            </div>
          </div>
          <div className="row gap-3 py-3">
            <div className="rounded-2 border p-3 col">
              <div className="txt-title-wallet">Ví voucher</div>
              <div className="txt-amount-wallet">
                {formatNumber(isUserData?.walletInfo?.amountVoucher)}đ
              </div>
            </div>
            <div className="rounded-2 border p-3 col">
              <div className="txt-title-wallet">Ví cổ phần</div>
              <div className="txt-amount-wallet">
                {formatNumber(isUserData?.walletInfo?.amountStocks)}đ
              </div>
            </div>
            <div className="rounded-2 border p-3 col">
              <div className="txt-title-wallet">Ví cổ phần (hoa hồng)</div>
              <div className="txt-amount-wallet">
                {formatNumber(isUserData?.walletInfo?.amountShare)}đ
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white p-3 rounded-2 mt-3 container-right">
        <div className="d-none d-lg-block">
          <div className="d-flex gap-3 ps-3">
            {ListStatusWallet.map((item, index) => {
              let checked = false;
              if (item.id === currentStatus) {
                checked = true;
              }
              return (
                <button
                  onClick={() => {
                    setCurrentStatus(item.id);
                    getData(item.type);
                  }}
                  className={
                    checked
                      ? "btn border border-0 p-0 text-dark fw-medium"
                      : "btn border border-0 p-0 text-secondary fw-medium"
                  }
                  key={item.id}
                >
                  {item.text}
                  <div
                    className={
                      checked
                        ? "border border-danger"
                        : "border border-white rounded-5"
                    }
                  />
                </button>
              );
            })}
          </div>
          <div className="border mb-3" />
          <Table columns={columns} dataSource={dataHistoty} />
        </div>
        <div className="d-block d-lg-none">
          <Slider {...settings}>
            {ListStatusWallet.map((item) => {
              let checked = false;
              if (item.id === currentStatus) {
                checked = true;
              }
              return (
                <button
                  onClick={() => {
                    setCurrentStatus(item.id);
                    getData(item.type);
                  }}
                  className={
                    checked
                      ? "btn border border-0 p-0 text-dark fw-medium"
                      : "btn border border-0 p-0 text-secondary fw-medium"
                  }
                  key={item.id}
                >
                  {item.text}
                  <div
                    className={
                      checked
                        ? "border border-danger"
                        : "border border-white rounded-5"
                    }
                  />
                </button>
              );
            })}
          </Slider>
          {isLoading ? (
            <Spin />
          ) : (
            dataHistoty.map((item: any, index) => {
              return (
                <div className="mt-3" key={item?._id}>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="fw-semibold">Mã giao dịch</p>
                    <p className="text-primary">{item?.transactionCode}</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="fw-semibold">Loại giao dịch</p>
                    <p>{ListStatusWallet[currentStatus].text}</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="fw-semibold">Tài khoản nguồn</p>
                    <div className="d-flex align-items-end flex-column">
                      <div className="fw-semibold">
                        {item?.userInfo?.fullName}
                      </div>
                      <div className="fw-semibold">{item?.userInfo?.email}</div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center pt-2">
                    <p className="fw-semibold">Tài khoản đích</p>
                    <div className="d-flex align-items-end flex-column">
                      <div className="fw-semibold">{item?.bankCode}</div>
                      <div className="fw-semibold">{item?.bankNumber}</div>
                      <div className="fw-semibold">{item?.accountName}</div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center py-2">
                    <p className="fw-semibold">Số tiền</p>
                    <div>
                      {item?.actualMoneyReceived &&
                        item?.actualMoneyReceived !== item?.total && (
                          <div className="fw-semibold text-danger text-decoration-line-through">
                            {formatNumber(item?.total)}đ
                          </div>
                        )}
                      <div className="fw-semibold">
                        {formatNumber(
                          item?.actualMoneyReceived
                            ? item?.actualMoneyReceived
                            : item?.total
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="fw-semibold">Tình trạng</p>
                    <div
                      className="text-white rounded-3 p-2"
                      style={{
                        background: renderColor[item?.status],
                      }}
                    >
                      {renderType[item?.status]}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center py-2">
                    <p className="fw-semibold">Ngày tạo</p>
                    <div>
                      <div>
                        {moment(item?.createdAt).format("DD-MM-YYYY hh:mm:ss")}
                      </div>
                      <div>
                        {moment(item?.updatedAt).format("DD-MM-YYYY hh:mm:ss")}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center pb-2">
                    <p className="fw-semibold">Nội dung</p>
                    <p>{item?.content}</p>
                  </div>
                  <div className="d-flex justify-content-end gap-2 mb-4">
                    <button
                      className="btn border border-0 p-2 bg-primary text-white"
                      onClick={() =>
                        navigate("/wallet-detail", { state: { item } })
                      }
                    >
                      <InfoCircleFilled /> Chi tiết
                    </button>
                    <button
                      className="btn border border-0 p-2 bg-danger text-white"
                      onClick={onCancel}
                    >
                      <CloseCircleFilled /> Hủy giao dịch
                    </button>
                  </div>
                  <div className="border w-100" />
                </div>
              );
            })
          )}
        </div>
      </div>

      <RechargeStep1
        isClose={() => setOpenRechargeStep1(false)}
        isOpen={openRechargeStep1}
        dataStep1={(value: any) => {
          onOpenRechargeStep2(value);
        }}
        dataBank={dataBank}
      />

      <RechargeStep2
        isClose={() => setOpenRechargeStep2(false)}
        isOpen={openRechargeStep2}
        dataStep1={dataRechargeStep1}
        onSubmit={() => {
          setOpenRechargeStep2(false);
          setOpenRechargeStep3(true);
        }}
      />

      <RechargeStep3
        isClose={() => setOpenRechargeStep3(false)}
        isOpen={openRechargeStep3}
        dataStep1={dataRechargeStep1}
      />

      <ModalWithdraw
        isClose={() => setOpenWithdraw(false)}
        isOpen={openWithdraw}
      />

      <ModalTransferVoucher
        isClose={() => setOpenTransferVoucher(false)}
        isOpen={openTransferVoucher}
      />
    </div>
  );
};

export default memo(Wallet);
