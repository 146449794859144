import React, { memo, useEffect, useState } from "react";
import AuthImages from "src/assets/Images/Auth";
import HomeImages from "src/assets/Images/Home";
import "./Auth.css";
import { Input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useAppDispatch } from "src/components/Redux/store";
import {
  login,
  register,
  setUserLogin,
} from "src/components/Features/user/userSlice";
import { useSelector } from "react-redux";
import { selectLoading } from "src/components/Redux/selector";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

const Register = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector(selectLoading);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [username, setUsername] = useState("");
  const [phone, setPhone] = useState("");
  const [ref, setRef] = useState<any>("");
  let [searchParams, setSearchParams] = useSearchParams();

  const onRegister = async () => {
    const params = {
      fullName: username,
      email,
      password,
      repeatPassword: rePassword,
      ref,
    };
    const resp: any = await dispatch(register(params));
    if (resp?.payload?.status === true) {
      navigate("/verify-otp", { state: { values: params } });
      toast.success(`Mã OTP đã được gửi đến ${email}`);
    }
  };

  useEffect(() => {
    handleDeepLink();
  }, []);

  function handleDeepLink() {
    console.log(searchParams.get("ref"));
    const params = searchParams.get("ref");
    setRef(params);
  }

  return (
    <div>
      <div className="container-xxl py-4">
        <button
          className="btn border border-0 p-0"
          onClick={() => navigate("/")}
        >
          <img src={AuthImages.logo} width={154} height={48} />
        </button>
      </div>
      <div className="main py-5">
        <div className="container-xxl d-none d-sm-block">
          <div className="row align-items-center">
            <div className="col-md-7">
              {/* <div className="txt-title-auth">
                Sàn thương mại điện tử khởi nghiệp
              </div> */}
              <img src={HomeImages.logo} width={386} height={120} />
            </div>
            <div className="rounded p-4 bg-white col-md-5">
              <div className="txt-title-right">Đăng ký</div>
              <div className="row align-items-center pt-4">
                <div className="col-md-6">
                  <div className="title-input">Họ và tên</div>
                  <Input
                    placeholder="Nhập họ và tên"
                    className="mt-1 rounded-3"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    style={{
                      height: 40,
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <div className="title-input">Số điện thoại</div>
                  <Input
                    placeholder="Nhập số điện thoại"
                    className="mt-1 rounded-3"
                    value={phone}
                    style={{
                      height: 40,
                    }}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </div>
              <div className="pt-2">
                <div className="title-input">Email</div>
                <Input
                  placeholder="email@gmail.com"
                  className="mt-1 rounded-3"
                  value={email}
                  style={{
                    height: 40,
                  }}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="row align-items-center pt-2">
                <div className="col-md-6">
                  <div className="title-input">Mật khẩu</div>
                  <Input.Password
                    placeholder="Nhập mật khẩu"
                    className="mt-1 rounded-3"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    style={{
                      height: 40,
                    }}
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </div>
                <div className="col-md-6">
                  <div className="title-input">Nhập lại mật khẩu</div>
                  <Input.Password
                    placeholder="Nhập lại mật khẩu"
                    className="mt-1 rounded-3"
                    value={rePassword}
                    style={{
                      height: 40,
                    }}
                    onChange={(e) => setRePassword(e.target.value)}
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </div>
              </div>
              <div className="pt-2">
                <div className="title-input">Mã giới thiệu</div>
                <Input
                  placeholder="email@gmail.com"
                  className="mt-1 rounded-3"
                  value={ref}
                  style={{
                    height: 40,
                  }}
                  onChange={(e) => setRef(e.target.value)}
                />
              </div>

              <button className="btn-login" onClick={onRegister}>
                {isLoading ? (
                  <div
                    className="spinner-border spinner-border-sm text-light"
                    role="status"
                  ></div>
                ) : (
                  `Đăng ký`
                )}
              </button>
              <button
                className="btn-register"
                onClick={() => navigate("/sign-in")}
              >
                Đăng nhập
              </button>
            </div>
          </div>
        </div>

        <div className="d-block d-sm-none d-flex flex-column align-items-center">
          <img src={HomeImages.logo} width={160} height={50} />
          <div className="rounded p-4 w-100">
            <div className="title-input-sm">Họ và tên</div>
            <Input
              placeholder="Nhập họ và tên"
              className="mt-1"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              style={{
                height: 40,
              }}
            />
            <div className="title-input-sm pt-3">Số điện thoại</div>
            <Input
              placeholder="Nhập số điện thoại"
              className="mt-1"
              value={phone}
              style={{
                height: 40,
              }}
              onChange={(e) => setPhone(e.target.value)}
            />
            <div className="title-input-sm pt-3">Email</div>
            <Input
              placeholder="Nhập email"
              className="mt-1"
              value={email}
              style={{
                height: 40,
              }}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="title-input-sm pt-3">Mật khẩu</div>
            <Input.Password
              placeholder="Nhập mật khẩu"
              className="mt-1 rounded-3"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{
                height: 40,
              }}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
            <div className="title-input-sm pt-3">Nhập lại mật khẩu</div>
            <Input.Password
              placeholder="Nhập lại mật khẩu"
              className="mt-1 rounded-3"
              value={rePassword}
              style={{
                height: 40,
              }}
              onChange={(e) => setRePassword(e.target.value)}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
            <div className="title-input-sm pt-3">Mã giới thiệu</div>
            <Input
              placeholder="Nhập mã giới thiệu"
              className="mt-1 rounded-3"
              value={ref}
              style={{
                height: 40,
              }}
              onChange={(e) => setRef(e.target.value)}
            />
            <button className="txt-forgot-sm">Quên mật khẩu ?</button>
            <button className="btn-login-sm" onClick={onRegister}>
              {isLoading ? (
                <div
                  className="spinner-border spinner-border-sm text-danger"
                  role="status"
                ></div>
              ) : (
                `Đăng ký`
              )}
            </button>
            <button
              className="btn-register"
              onClick={() => navigate("/sign-in")}
            >
              Đăng nhập
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Register);
