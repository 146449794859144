import React, { memo, useEffect, useState } from "react";
import { ForwardOutlined, RightOutlined } from "@ant-design/icons";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Slider from "react-slick";
import { API_URL } from "src/controllers/Client";
import "./ProductDetail.css";
import formatNumber from "src/common/FormatNumber";
import HomeImages from "src/assets/Images/Home";
import { useAppDispatch } from "src/components/Redux/store";
import { getProductDetail } from "src/components/Features/product/productSlice";
import { getProducts } from "src/components/Features/home/homeSlice";
import { cardAdd } from "src/components/Features/cart/cartSlice";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selectLoading } from "src/components/Redux/selector";
import useGetProfile from "src/hooks/useGetProfile";
import ReactPlayer from "react-player";

const ProductDetail = () => {
  const { state } = useLocation();
  const user: any = useGetProfile();
  const navigate = useNavigate();
  const isLoading = useSelector(selectLoading);
  const dispatch = useAppDispatch();
  const [productID, setProductID] = useState(state?.productID);
  const [numberProduct, setNumberProduct] = useState<any>("1");
  const [dataProducts, setDataProducts] = useState([]);
  const [dataProductDetail, setDataProductDetail] = useState<any>(null);
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    getDataProductDetail();
    getProduct();
  }, []);

  const getDataProductDetail = async () => {
    const params = searchParams.get("id");
    const resp: any = await dispatch(
      getProductDetail(params ? params : productID)
    );
    console.log("resp detail product ----", resp);
    if (resp?.payload?.status === true) {
      setDataProductDetail(resp?.payload?.data);
    }
  };

  const getProduct = async () => {
    const params = { limit: 1000 };

    const resp: any = await dispatch(getProducts(params));

    const data = resp?.payload?.data;
    setDataProducts(data);
  };

  const onAddToCard = async () => {
    const params = {
      productID: dataProductDetail?.productID,
      quantity: +numberProduct,
    };
    const resp: any = await dispatch(cardAdd(params));
    if (resp?.payload?.status === true) {
      toast.success("Thêm vào giỏ hàng thành công");
    }
  };

  const handleBuy = async () => {
    navigate(`/buy-product`, {
      state: { item: dataProductDetail, numberProduct },
    });
  };

  const onShare = async () => {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(
        `https://ffe.com.vn/product/detail?id=${productID}&ref=${user?.data?.myRef}`
      );
      toast.success("Sao chép thành công !");
    } else {
      document.execCommand(
        "copy",
        true,
        `https://ffe.com.vn/product/detail?id=${productID}&ref=${user?.data?.myRef}`
      );
      toast.success("Sao chép thành công !");
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };

  return (
    <div className="container-home">
      <div className="container-xxl py-5">
        <div className="d-none d-md-block">
          <div className="d-flex">
            <button
              className="btn border border-0 p-0 text-danger"
              onClick={() => navigate(-1)}
            >
              Trang chủ <RightOutlined />
            </button>
            <button
              className="btn border border-0 p-0 text-danger mx-2"
              onClick={() => navigate(-1)}
            >
              Danh sách sản phẩm <RightOutlined />
            </button>
            <div>{dataProductDetail?.productName}</div>
          </div>
        </div>
        <div className="row py-5 bg-white rounded-1 mt-2">
          <div className="col-md-5">
            <Slider {...settings}>
              {dataProductDetail?.images?.map((img: any, index: number) => {
                const findVideo = img?.includes(".mp4");
                return findVideo ? (
                  <ReactPlayer
                    url={`${API_URL}${img}`}
                    controls
                    width={"100%"}
                    key={index}
                  />
                ) : (
                  <img
                    key={index}
                    src={`${API_URL}${img}`}
                    className="img-product-detail"
                    alt=""
                  />
                );
              })}
            </Slider>
          </div>
          <div className="col-md-6">
            <div className="txt-title-product">
              {dataProductDetail?.productName}
            </div>
            {dataProductDetail?.priceCompare > 0 && (
              <div className="txt-price-compare">
                {formatNumber(dataProductDetail?.priceCompare)}đ
              </div>
            )}
            <div className="txt-product-price">
              {formatNumber(
                dataProductDetail?.price + dataProductDetail?.priceVocher
              )}
              đ
            </div>
            {dataProductDetail?.priceVocher > 0 && (
              <div className="border border-1 border-primary p-2 rounded-3 mt-3">
                <div className="text-primary fw-semibold">
                  Giá voucher: {formatNumber(dataProductDetail?.priceVocher)}đ
                </div>
                <div className="text-primary">
                  · Nếu số dư ví voucher lớn hơn hoặc bằng giá voucher thì khi
                  mua mỗi sản phẩm hệ thống sẽ tự động trừ{" "}
                  {formatNumber(dataProductDetail?.priceVocher)}đ trong ví
                  voucher và trừ {formatNumber(dataProductDetail?.price)}đ trong
                  ví cá nhân của bạn.
                </div>
                <div className="text-danger">
                  · Ngược lại, bạn sẽ cần thanh toán{" "}
                  {formatNumber(
                    dataProductDetail?.price + dataProductDetail?.priceVocher
                  )}
                  đ khi mua sản phẩm
                </div>
              </div>
            )}
            {dataProductDetail?.descreptionShort && (
              <>
                <div className="txt-title-description">Mô tả</div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: dataProductDetail?.descreptionShort,
                  }}
                  className="txt-description"
                ></div>
              </>
            )}
            <div className="d-flex align-items-center gap-2 mt-2">
              <div className="txt-add-number me-2">Số lượng</div>
              <button
                className="btn border border-0 p-0 fw-semibold fs-3"
                onClick={() => setNumberProduct(+numberProduct - 1)}
                disabled={+numberProduct === 1}
              >
                -
              </button>
              <input
                type="text"
                className="input-add-number"
                value={numberProduct}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(text) => setNumberProduct(text.target.value)}
              />
              <button
                className="btn border border-0 p-0 fw-semibold fs-3"
                onClick={() => setNumberProduct(+numberProduct + 1)}
              >
                +
              </button>
            </div>
            <button className="btn-share-product" onClick={onShare}>
              Chia sẻ sản phẩm
            </button>
            <div className="d-flex pt-3 gap-2">
              <button className="btn-buy-product" onClick={handleBuy}>
                {isLoading ? (
                  <div
                    className="spinner-border spinner-border-sm text-danger"
                    role="status"
                  ></div>
                ) : (
                  `Mua ngay`
                )}
              </button>
              <button className="btn-add-to-cart" onClick={() => onAddToCard()}>
                <img
                  src={HomeImages.shopping_cart}
                  width={24}
                  height={24}
                  alt=""
                />
                Thêm vào giỏ hàng
              </button>
            </div>
          </div>
        </div>

        <div className="bg-white p-3 mt-4 rounded-1">
          <div className="txt-title-all-product">Sản phẩm tương tự</div>
          <Slider
            slidesToShow={6}
            dots={false}
            infinite={true}
            speed={500}
            slidesToScroll={1}
            autoplay={true}
            className="my-3"
            arrows={true}
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 3,
                  infinite: true,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 2,
                  initialSlide: 2,
                },
              },
              {
                breakpoint: 576,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ]}
          >
            {dataProducts?.map((item: any, index: number) => {
              const findVideo = item?.images?.find(
                (x: { includes: (arg0: string) => any }) => !x?.includes(".mp4")
              );
              return (
                <div
                  className="border rounded-3 d-flex flex-column justify-content-between item-container btn p-0"
                  key={item?.productID}
                  onClick={() =>
                    navigate(`/loading`, {
                      state: { productID: item?.productID },
                    })
                  }
                >
                  <div>
                    <img
                      src={
                        findVideo
                          ? `${API_URL}${findVideo}`
                          : HomeImages.logo_product
                      }
                      className="rounded-3 img-item"
                    />

                    <div className="pt-2 px-2">
                      <div
                        className="txt-item-product d-block d-md-none"
                        style={{
                          fontSize: 14,
                        }}
                      >
                        {item?.productName.substring(0, 14) + "..."}
                      </div>
                      <div className="txt-item-product d-none d-md-block">
                        {item?.productName.substring(0, 30) + "..."}
                      </div>
                    </div>
                    {item?.priceCompare > 0 && (
                      <div className="txt-price-compare ps-2">
                        {formatNumber(item?.priceCompare)}đ
                      </div>
                    )}
                    <div className="txt-price ps-2">
                      {formatNumber(item?.price + item?.priceVocher)}đ
                    </div>
                  </div>
                  <div className="txt-sold ps-2">Đã bán: {item?.sold}</div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default memo(ProductDetail);
