import React from "react";
import { useNavigate } from "react-router-dom";
import ProductImages from "src/assets/Images/Product";
import "./PaymentSuccess.css";
import { useAppDispatch } from "src/components/Redux/store";
import { setNavigateProfile } from "src/components/Features/user/userSlice";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <div className="container-home py-5">
      <div className="container-xxl">
        <div className="bg-white rounded-1 p-5 d-flex flex-column align-items-center">
          <img src={ProductImages.success} width={80} height={80} alt="" />
          <div className="fw-semibold fs-4 pt-2 pb-1">Đặt hàng hành công</div>
          <div className="text-secondary text-center">
            Đơn hàng của bạn đã được tiếp nhận và chờ xử lý
          </div>
          <div className="gap-2 d-flex pt-4">
            <button className="btn-goback-home" onClick={() => navigate("/")}>
              Trở về trang chủ
            </button>
            <button
              className="btn-infor-product"
              onClick={() => {
                dispatch(setNavigateProfile(true));
                navigate("/list-order");
              }}
            >
              Thông tin đơn hàng
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
