import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { ModalProps } from "src/constants/Interface";
import "./Modal.css";
import ProductImages from "src/assets/Images/Product";
import { useAppDispatch } from "src/components/Redux/store";
import { removeAddress } from "src/components/Features/address/addressSlice";
import { toast } from "react-toastify";
import { getAddress } from "src/components/Features/user/userSlice";

const ModalAddress = (props: any) => {
  const dispatch = useAppDispatch();
  const { isOpen, isClose, onAddAddress, chooseAddress, isSubmit } = props;
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [listAddress, setListAddress] = useState([]);

  useEffect(() => {
    getUserAddress();
  }, [isOpen]);

  const getUserAddress = async () => {
    const resp: any = await dispatch(getAddress());
    if (resp.payload.status === true) {
      console.log("resp address---", resp);
      const data = resp.payload.data || [];
      setListAddress(data);
    }
  };

  const onDelete = async (userAddressID: number | string) => {
    const resp: any = await dispatch(removeAddress({ userAddressID }));
    if (resp.payload.status === true) {
      toast.success("Xóa địa chỉ thành công");
      getUserAddress();
      isSubmit();
    }
  };

  return (
    <Modal
      title={null}
      open={isOpen}
      footer={null}
      closeIcon={null}
      onCancel={() => isClose()}
      width={800}
    >
      <div>
        <div className="fw-semibold fs-5">Sổ địa chỉ</div>
        {!listAddress.length ? (
          <div className="text-center pt-3 text-secondary fs-5">
            Bạn chưa có địa chỉ nào, vui lòng thêm địa chỉ mới
          </div>
        ) : (
          listAddress.map((item: any, index: number) => {
            let checked = false;
            if (index === currentIndex) {
              checked = true;
            }
            return (
              <button
                key={item?._id}
                className="btn border border-0 p-0 d-flex align-items-center justify-content-between w-100 mt-2"
                onClick={() => {
                  setCurrentIndex(index);
                  chooseAddress(item);
                }}
              >
                <div className="d-flex align-items-center">
                  <div className="div-circle border border-dark">
                    {checked && <div className="div-inner-circle" />}
                  </div>
                  <div className="ps-2">
                    <div className="d-flex align-items-center">
                      <div className="txt-name-address">{item?.name}</div>
                      <div className="txt-name-address mx-1">•</div>
                      <div className="txt-name-address">{item?.phone}</div>
                    </div>
                    <div className="txt-des-address pt-1 text-start">
                      {item?.address},{item?.wardName},{item?.districtName},
                      {item?.provinceName}
                    </div>
                    {item?.isDefault && (
                      <div className="txt-default mt-1">Mặc định</div>
                    )}
                  </div>
                </div>
                <div>
                  {/* <button className="border border-0 btn p-0">
                    <img
                      src={ProductImages.icon_edit}
                      width={40}
                      height={40}
                      alt=""
                    />
                  </button> */}
                  <button
                    className="border border-0 btn p-0 ms-2"
                    onClick={() => onDelete(item?.userAddressID)}
                  >
                    <img
                      src={ProductImages.icon_delete}
                      width={40}
                      height={40}
                      alt=""
                    />
                  </button>
                </div>
              </button>
            );
          })
        )}

        <div className="w-100 d-flex justify-content-end pt-5">
          <button className="btn-add-address" onClick={onAddAddress}>
            + Thêm địa chỉ mới
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalAddress;
