import React, { useEffect } from "react";
import Lottie from "react-lottie";
import loading from "src/assets/Lotties/loading1.json";
import "./Loading.css";
import { useLocation, useNavigate } from "react-router-dom";

const Loading = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate(`/product/detail?id=${state?.productID}`, {
        state: { productID: state?.productID },
      });
    }, 1200);
  }, []);

  return (
    <div className="container-loading">
      <Lottie
        options={{
          loop: true,
          animationData: loading,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        height={60}
        width={60}
      />
    </div>
  );
};

export default Loading;
