import NavbarImages from "src/assets/Images/Navbar";

export const ListTitleProductHome = [
  {
    id: 0,
    text: "Sản phẩm tài trợ",
    type: "Sponsor",
  },
  {
    id: 1,
    text: "Sản phẩm độc quyền",
    type: "Monopoly",
  },
  {
    id: 2,
    text: "Sản phẩm liên kết",
    type: "Affiliate",
  },
  {
    id: 3,
    text: "Gói đại lý",
    type: "Agency",
  },
];

export const FuncProfile = [
  {
    id: 0,
    text: "Thông tin tài khoản",
    icon: NavbarImages.person,
  },
  {
    id: 1,
    text: "Ngân hàng của tôi",
    icon: NavbarImages.account_balance,
  },
  {
    id: 2,
    text: "Sổ địa chỉ",
    icon: NavbarImages.home_pin,
  },
  {
    id: 3,
    text: "Đơn hàng",
    icon: NavbarImages.shopping_cart,
  },
  {
    id: 4,
    text: "Ví cá nhân",
    icon: NavbarImages.account_balance_wallet,
  },
  // {
  //     id: 5,
  //     text: 'Ví tích lũy',
  //     icon: NavbarImages.savings,
  // },
  {
    id: 6,
    text: "Mời bạn bè",
    icon: NavbarImages.share,
  },
];

export const UserProfile = [
  {
    id: 0,
    text: "Họ và tên",
  },
  {
    id: 1,
    text: "Email",
  },
  {
    id: 2,
    text: "Số điện thoại",
  },
  {
    id: 3,
    text: "Ngày sinh",
  },
  {
    id: 4,
    text: "Tình trạng",
  },
  {
    id: 5,
    text: "Trạng thái",
  },
  {
    id: 6,
    text: "Cấp bậc",
  },
  {
    id: 7,
    text: "Người giới thiệu",
  },
];

export const ListStatusOrder = [
  {
    id: 0,
    text: "Tất cả",
    status: "",
  },
  {
    id: 1,
    text: "Chờ xác nhận",
    status: "New",
  },
  {
    id: 2,
    text: "Đang giao",
    status: "Pending",
  },
  {
    id: 3,
    text: "Hoàn thành",
    status: "Success",
  },
  {
    id: 4,
    text: "Đã hủy",
    status: "Cancel",
  },
];

export const ListStatusWallet = [
  {
    id: 0,
    text: "Nạp tiền",
    type: "Deposit",
  },
  {
    id: 1,
    text: "Rút tiền",
    type: "WithDraw",
  },
  {
    id: 2,
    text: "Mua hàng",
    type: "PaymentOrder",
  },
  {
    id: 3,
    text: "Hoa hồng",
    type: "payCommissions",
  },
  {
    id: 4,
    text: "Mua voucher",
    type: "buyVoucherProduct",
  },
  {
    id: 5,
    text: "Mua coin",
    type: "buyCoin",
  },
  {
    id: 6,
    text: "Mua cổ phần",
    type: "buyStock",
  },
];

export const AccumulationHistory = [
  {
    id: 0,
    text: "Lịch sử tích luỹ",
  },
  {
    id: 1,
    text: "Lịch sử lợi nhuận",
  },
];
