import React, { memo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const QRPayment = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  return (
    <div className="container-home py-5">
      <div className="container-xxl">
        <div className="bg-white rounded-1 p-5 d-flex flex-column align-items-center">
          <div
            className="pb-4 text-center"
            style={{
              color: "#EE4D2D",
              fontWeight: "700",
            }}
          >
            Vui lòng quét mã thanh toán và chuyển khoản với nội dung là mã đơn
            hàng bên dưới!
          </div>
          <img src={state?.data?.paymentUrl} width={290} height={360} />
          {/* <div>
            <div>
            Nội dung chuyển khoản:
            </div>
            <div>
                1234
            </div>
          </div> */}
          <button
            className="btn-buy-product mt-5"
            onClick={() => navigate("/*")}
          >
            Hoàn tất
          </button>
        </div>
      </div>
    </div>
  );
};

export default memo(QRPayment);
