import React, { memo, useEffect, useState } from "react";
import "./Profile.css";
import ListLeftProfile from "../ListLeftProfile";
import useGetProfile from "src/hooks/useGetProfile";
import ProfileImages from "src/assets/Images/Profile";
import momentBirthday from "src/common/Moment";
import Types, { GetMemberColor, GetMemberName } from "src/constants/Types";
import formatNumber from "src/common/FormatNumber";
import { useAppDispatch } from "src/components/Redux/store";
import ModalChangePassword from "./Components/ModalChangePassword";
import { UserProfile } from "src/constants/Array";
import ModalUpdateProfile from "./Components/ModalUpdateProfile";
import Cookies from "js-cookie";
import { getProfile } from "src/components/Features/user/userSlice";

const Profile = () => {
  const dispatch = useAppDispatch();
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openUpdateProfile, setOpenUpdateProfile] = useState(false);
  const [userData, setUserData] = useState<any>(null);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    const accessToken = Cookies.get(Types.ACCESS_TOKEN);
    if (accessToken) {
      const resp: any = await dispatch(getProfile());
      // console.log('resp user---', resp);
      if (resp?.payload?.status === true) {
        const data = resp.payload.data || {};
        setUserData(data);
      }
    }
  };

  return (
    <div className="col col-md-9 mt-5">
      <div className="bg-white py-4 px-3 col rounded container-right">
        <div className="d-flex justify-content-between">
          <div className="txt-title-right">Thông tin tài khoản</div>
          <div className="d-flex gap-2">
            <button
              className="btn-edit"
              onClick={() => setOpenUpdateProfile(true)}
            >
              <img src={ProfileImages.edit} width={20} height={20} />
              Cập nhật
            </button>
            <button
              className="btn-change-password"
              onClick={() => setOpenChangePassword(true)}
            >
              <img src={ProfileImages.lock} width={20} height={20} />
              Đổi mật khẩu
            </button>
          </div>
        </div>
        <div className="w-100 border my-4" />
        <div className="col-md-12">
          <div className="d-flex">
            <div>
              {UserProfile.map((item) => (
                <p key={item.id} className="txt-profile-left">
                  {item.text}
                </p>
              ))}
            </div>
            <div className="ps-4">
              <p className="txt-profile-right">{userData?.fullName}</p>
              <p className="txt-profile-right">{userData?.email}</p>
              <p className="txt-profile-right">{userData?.phoneNumber || 0}</p>
              <p className="txt-profile-right">
                {momentBirthday(userData?.birthDay)}
              </p>
              <p className="txt-profile-right">
                {userData?.isVerified ? "Đã xác thực" : "Chưa xác thực"}
              </p>
              <p className="txt-profile-right">Đang hoạt động</p>
              <p
                className="txt-profile-right"
                style={{
                  color: GetMemberColor(userData?.affiliateLevel),
                }}
              >
                {GetMemberName(userData?.affiliateLevel)}
              </p>
              <p className="txt-profile-right">{userData?.parentInfo?.email}</p>
            </div>
          </div>
        </div>
        <div className="w-100 border my-4" />
        <div className="txt-title-right pb-3">Thông tin mua hàng</div>
        <div className="d-flex">
          <div>
            <p className="txt-profile-left">Đơn đã đặt</p>
            <p className="txt-profile-left">Tổng tiền mua hàng</p>
          </div>
          <div className="ps-4">
            <p className="txt-profile-right">{userData?.totalOrder}</p>
            <p className="txt-profile-right">
              {formatNumber(userData?.totalPurchase)}đ
            </p>
          </div>
        </div>

        <div className="w-100 border my-4" />
        <div className="txt-title-right pb-3">Ví cá nhân</div>
        <div className="d-flex">
          <div>
            <p className="txt-profile-left">Điểm điểm danh</p>
            <p className="txt-profile-left">Số dư ví</p>
            <p className="txt-profile-left">Ví voucher</p>
            <p className="txt-profile-left">Ví mua cổ phần</p>
          </div>
          <div className="ps-4">
            <p className="txt-profile-right">
              {userData?.walletInfo?.pointVIC}
            </p>
            <p className="txt-profile-right">
              {formatNumber(userData?.walletInfo?.accountBalance)}đ
            </p>
            <p className="txt-profile-right">
              {formatNumber(userData?.walletInfo?.amountVoucher)}đ
            </p>
            <p className="txt-profile-right">
              {formatNumber(userData?.walletInfo?.amountShare)}đ
            </p>
          </div>
        </div>

        <div className="w-100 border my-4" />
        <div className="txt-title-right pb-3">Doanh thu và lợi nhuận</div>
        <div className="d-flex">
          <div>
            <p className="txt-profile-left">Số tiền nạp (thành công/tổng số)</p>
            <p className="txt-profile-left">Số tiền rút (thành công/tổng số)</p>
            <p className="txt-profile-left">Tổng hoa hồng</p>
          </div>
          <div className="ps-4">
            <p className="txt-profile-right">
              {formatNumber(
                userData?.walletReport[0]
                  ? userData?.walletReport[0]?.totalDepositSuccess
                  : 0
              )}
              đ
            </p>
            <p className="txt-profile-right">
              {formatNumber(
                userData?.walletReport[0]
                  ? userData?.walletReport[0]?.totalWithDrawSuccess
                  : 0
              )}
              đ
            </p>
            <p className="txt-profile-right">
              {formatNumber(
                userData?.walletReport[0]
                  ? userData?.walletReport[0]?.totalPayCommission
                  : 0
              )}
              đ
            </p>
          </div>
        </div>
      </div>
      <ModalChangePassword
        isOpen={openChangePassword}
        isClose={() => setOpenChangePassword(false)}
      />

      <ModalUpdateProfile
        isOpen={openUpdateProfile}
        isClose={() => setOpenUpdateProfile(false)}
      />
    </div>
  );
};

export default memo(Profile);
