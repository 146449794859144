import { createSlice } from '@reduxjs/toolkit';
import {
  accumulativeProfitWithdraw,
  accumulativeWithdraw,
  getAccumulativeProfitTransaction,
} from '../accumulative/accumulativeSlice';
import { cardAdd, getCardList } from '../cart/cartSlice';
import { getHome, getProducts } from '../home/homeSlice';
import { getListOrder, getProductDetail } from '../product/productSlice';
import {
  addAddress,
  changePassword,
  createAddress,
  createBank,
  deleteAccount,
  deleteBank,
  getAddress,
  getListBank,
  getProfile,
  login,
  register,
  resetPassword,
  restPassword,
  updateBank,
  updateProfile,
  verifyEmail,
  verifyOTP,
} from '../user/userSlice';
import { transactionList } from '../wallet/walletSlice';

export const loadingSlice = createSlice({
  name: 'loading',
  initialState: false,
  reducers: {
    toggletLoading: state => !state,
    resetLoading: state => false,
  },
  extraReducers: {
    [login.pending.type]: () => true,
    [login.fulfilled.type]: () => false,
    [login.rejected.type]: () => false,

    [register.pending.type]: () => true,
    [register.fulfilled.type]: () => false,
    [register.rejected.type]: () => false,

    // [getHome.pending.type]: () => true,
    // [getHome.fulfilled.type]: () => false,
    // [getHome.rejected.type]: () => false,

    [getProductDetail.pending.type]: () => true,
    [getProductDetail.fulfilled.type]: () => false,
    [getProductDetail.rejected.type]: () => false,

    [getProducts.pending.type]: () => true,
    [getProducts.fulfilled.type]: () => false,
    [getProducts.rejected.type]: () => false,

    [transactionList.pending.type]: () => true,
    [transactionList.fulfilled.type]: () => false,
    [transactionList.rejected.type]: () => false,

    // [getCardList.pending.type]: () => true,
    // [getCardList.fulfilled.type]: () => false,
    // [getCardList.rejected.type]: () => false,

    [cardAdd.pending.type]: () => true,
    [cardAdd.fulfilled.type]: () => false,
    [cardAdd.rejected.type]: () => false,

    // [getProfile.pending.type]: () => true,
    // [getProfile.fulfilled.type]: () => false,
    // [getProfile.rejected.type]: () => false,

    [addAddress.pending.type]: () => true,
    [addAddress.fulfilled.type]: () => false,
    [addAddress.rejected.type]: () => false,

    [getListOrder.pending.type]: () => true,
    [getListOrder.fulfilled.type]: () => false,
    [getListOrder.rejected.type]: () => false,

    [updateProfile.pending.type]: () => true,
    [updateProfile.fulfilled.type]: () => false,
    [updateProfile.rejected.type]: () => false,

    [changePassword.pending.type]: () => true,
    [changePassword.fulfilled.type]: () => false,
    [changePassword.rejected.type]: () => false,

    [getAddress.pending.type]: () => true,
    [getAddress.fulfilled.type]: () => false,
    [getAddress.rejected.type]: () => false,

    [createAddress.pending.type]: () => true,
    [createAddress.fulfilled.type]: () => false,
    [createAddress.rejected.type]: () => false,

    [getListBank.pending.type]: () => true,
    [getListBank.fulfilled.type]: () => false,
    [getListBank.rejected.type]: () => false,

    [createBank.pending.type]: () => true,
    [createBank.fulfilled.type]: () => false,
    [createBank.rejected.type]: () => false,

    [getAccumulativeProfitTransaction.pending.type]: () => true,
    [getAccumulativeProfitTransaction.fulfilled.type]: () => false,
    [getAccumulativeProfitTransaction.rejected.type]: () => false,

    [accumulativeWithdraw.pending.type]: () => true,
    [accumulativeWithdraw.fulfilled.type]: () => false,
    [accumulativeWithdraw.rejected.type]: () => false,

    [accumulativeProfitWithdraw.pending.type]: () => true,
    [accumulativeProfitWithdraw.fulfilled.type]: () => false,
    [accumulativeProfitWithdraw.rejected.type]: () => false,

    [verifyEmail.pending.type]: () => true,
    [verifyEmail.fulfilled.type]: () => false,
    [verifyEmail.rejected.type]: () => false,

    [restPassword.pending.type]: () => true,
    [restPassword.fulfilled.type]: () => false,
    [restPassword.rejected.type]: () => false,

    [resetPassword.pending.type]: () => true,
    [resetPassword.fulfilled.type]: () => false,
    [resetPassword.rejected.type]: () => false,

    [verifyOTP.pending.type]: () => true,
    [verifyOTP.fulfilled.type]: () => false,
    [verifyOTP.rejected.type]: () => false,

    [deleteAccount.pending.type]: () => true,
    [deleteAccount.fulfilled.type]: () => false,
    [deleteAccount.rejected.type]: () => false,

    [updateBank.pending.type]: () => true,
    [updateBank.fulfilled.type]: () => false,
    [updateBank.rejected.type]: () => false,

    [deleteBank.pending.type]: () => true,
    [deleteBank.fulfilled.type]: () => false,
    [deleteBank.rejected.type]: () => false,
  },
});

const { actions, reducer } = loadingSlice;
export const { toggletLoading, resetLoading } = actions;
export default reducer;
