import React, { memo, useState } from "react";
import AuthImages from "src/assets/Images/Auth";
import HomeImages from "src/assets/Images/Home";
import "./Auth.css";
import { useAppDispatch } from "src/components/Redux/store";
import {
  register,
  setUserLogin,
  verifyEmail,
} from "src/components/Features/user/userSlice";
import { useSelector } from "react-redux";
import { selectLoading } from "src/components/Redux/selector";
import { useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { Input } from "antd";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import Types from "src/constants/Types";

const VerifyOTP = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const isLoading = useSelector(selectLoading);
  const [otp, setOtp] = useState("");

  const onVerify = async () => {
    const params = {
      email: state?.values?.email,
      code: otp,
    };
    const resp: any = await dispatch(verifyEmail(params));
    if (resp?.payload?.status === true) {
      dispatch(setUserLogin(resp.payload));
      Cookies.set(Types.ACCESS_TOKEN, resp.payload?.accessToken);
      toast.success(resp?.payload?.message);
    }
  };

  const onReSendOTP = async () => {
    const params = {
      fullName: state?.values.username,
      email: state?.values.email,
      password: state?.values.password,
      repeatPassword: state?.values.repassword,
      ref: state?.values.ref,
    };
    const resp: any = await dispatch(register(params));
    if (resp.payload.status === true) {
      toast.success("OTP đã được gửi lại đến email của bạn!");
    }
  };

  return (
    <div>
      <div className="container-xxl py-4">
        <button
          className="btn border border-0 p-0"
          onClick={() => navigate("/")}
        >
          <img src={AuthImages.logo} width={154} height={48} />
        </button>
      </div>
      <div className="main py-5">
        <div className="container-xxl d-none d-sm-block">
          <div className="row align-items-center">
            <div className="col-md-7">
              {/* <div className="txt-title-auth">
                Sàn thương mại điện tử khởi nghiệp
              </div> */}
              <img src={HomeImages.logo} width={386} height={120} />
            </div>
            <div className="rounded p-4 bg-white col-md-4">
              <div className="txt-title-right">Xác nhận Email</div>
              <div className="title-input pt-4">
                Kiểm tra email của bạn và nhập mã OTP đã được gửi từ hệ thống
              </div>
              <div className="d-flex justify-content-center">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderInput={(props: any) => (
                    <Input
                      {...props}
                      className="input-otp mx-2 mt-3 fw-semibold"
                      style={{
                        width: 46,
                      }}
                    />
                  )}
                />
              </div>

              <button className="btn-login" onClick={onVerify}>
                {isLoading ? (
                  <div
                    className="spinner-border spinner-border-sm text-light"
                    role="status"
                  ></div>
                ) : (
                  `Hoàn tất`
                )}
              </button>
              <button className="btn-register" onClick={onReSendOTP}>
                Gửi lại
              </button>
            </div>
          </div>
        </div>

        <div className="d-block d-sm-none d-flex flex-column align-items-center">
          <img src={HomeImages.logo} width={160} height={50} />
          <div className="rounded p-4 w-100">
            <div className="title-input-sm pt-4">
              Kiểm tra email của bạn và nhập mã OTP đã được gửi từ hệ thống
            </div>
            <div className="d-flex justify-content-center">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderInput={(props: any) => (
                  <Input
                    {...props}
                    className="input-otp mx-2 mt-3 fw-semibold"
                    style={{
                      width: 46,
                    }}
                  />
                )}
              />
            </div>
            <button className="btn-login-sm" onClick={onVerify}>
              {isLoading ? (
                <div
                  className="spinner-border spinner-border-sm text-danger"
                  role="status"
                ></div>
              ) : (
                `Hoàn tất`
              )}
            </button>
            <button className="btn-register" onClick={onReSendOTP}>
              Gửi lại
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(VerifyOTP);
