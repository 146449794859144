import React, { memo, useEffect, useState } from "react";
import ProfileImages from "src/assets/Images/Profile";
import "./Accumulation.css";
import { useAppDispatch } from "src/components/Redux/store";
import {
  getAccumulativeList,
  getAccumulativeProfitTransaction,
  getInvestmentList,
} from "src/components/Features/accumulative/accumulativeSlice";
import formatNumber from "src/common/FormatNumber";
import useGetProfile from "src/hooks/useGetProfile";
import { AccumulationHistory } from "src/constants/Array";
import { Pagination, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import CreateAccmulation from "./Components/CreateAccmulation";

const Accumulation = () => {
  const dispatch = useAppDispatch();
  const userInfo: any = useGetProfile();
  const [data, setData] = useState([]);
  const [currentStatus, setCurrentStatus] = useState(0);
  const [dataTransaction, setDataTransaction] = useState([]);
  const [dataAccumulation, setDataAccumulation] = useState([]);
  const [openCreateAccumulation, setOpenCreateAccumulation] = useState(false);

  useEffect(() => {
    getData();
    getAccuList();
  }, []);

  const getData = async () => {
    const resp: any = await dispatch(getInvestmentList());
    if (resp.payload.status === true) {
      // console.log("resp?.payload?.data", resp?.payload?.data);

      const resp_data = resp?.payload?.data || [];
      setData(resp_data);
    }
  };

  const getAccuList = async () => {
    const resp: any = await dispatch(getAccumulativeList());
    if (resp.payload.status === true) {
      const resp_data = resp?.payload?.accumulative || [];
      // console.log("resp?.payload?.accumulative", resp?.payload?.accumulative);

      setDataAccumulation(resp_data);
    }
  };

  const getListTransactions = async (page: number) => {
    const resp: any = await dispatch(
      getAccumulativeProfitTransaction({ limit: 10, page: page })
    );
    console.log("resp trans----", resp);
    if (resp.payload.status === true) {
      setDataTransaction(resp?.payload?.data);
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: "#",
      dataIndex: "",
      key: "1",
      render: (item, r, index) => <div>{index + 1}</div>,
    },
    {
      title: "Gói tích luỹ",
      dataIndex: "",
      key: "2",
      render: (item) => (
        <div className="txt-table-title">{item?.timeDeposit} tháng</div>
      ),
    },
    {
      title: "Lãi suất",
      dataIndex: "",
      key: "2",
      render: (item) => (
        <div className="txt-table-title">{item?.interestRate}%</div>
      ),
    },
    {
      title: "Số tiền",
      dataIndex: "",
      key: "2",
      render: (item) => (
        <div className="txt-table-title">{formatNumber(item?.amount)}đ</div>
      ),
    },
    {
      title: "Trạng thái",
      dataIndex: "",
      key: "2",
      render: (item) => (
        <div className="bg-success text-white rounded-5 p-1 text-center fw-semibold">
          {item?.status === "Open" ? "Đang mở" : "Đóng"}
        </div>
      ),
    },
    {
      title: "Ngày bắt đầu",
      dataIndex: "",
      key: "2",
      render: (item) => (
        <div>{moment(item?.createdDate).format("DD-MM-YYYY hh:mm:ss")}</div>
      ),
    },
    {
      title: "Ngày đáo hạn",
      dataIndex: "",
      key: "2",
      render: (item) => (
        <div>{moment(item?.maturityDate).format("DD-MM-YYYY hh:mm:ss")}</div>
      ),
    },
  ];

  const onChange = (value: number) => {
    getListTransactions(value);
  };

  return (
    <div className="col col-md-9 mt-5">
      <div className="bg-white p-3 rounded-2 container-right">
        <div className="d-flex align-items-center justify-content-between">
          <div className="txt-title-right">Chính sách tích lũy</div>
          <button
            className="btn-create-accumulation"
            onClick={() => setOpenCreateAccumulation(true)}
          >
            <img
              src={ProfileImages.heo}
              width={20}
              height={20}
              className="me-1"
              alt=""
            />
            Tạo tích luỹ
          </button>
        </div>
        <div className="border w-100 my-4" />
        <div className="border rounded-1">
          <div className="col row p-3">
            <div className="txt-invest-title col col-lg-4">Gói tích luỹ</div>
            <div className="txt-invest-title col col-lg-4">Lãi suất</div>
            <div className="txt-invest-title col col-lg-4">Kỳ hạn chi trả</div>
          </div>
          <div className="border w-100" />
          {data.map((item: any, index: number) => (
            <div>
              <div key={item?._id} className="col row p-3">
                <div className="txt-invest-list col col-lg-4 text-start">
                  {item?.mounth} tháng
                </div>
                <div className="txt-invest-list col col-lg-4 text-start">
                  {item?.interestRate}% / tháng
                </div>
                <div className="txt-invest-list col col-lg-4 text-start">
                  Trả theo ngày sau 24h
                </div>
              </div>
              <div className="border w-100" />
            </div>
          ))}
        </div>
      </div>
      <div className="bg-white p-3 rounded-2 container-right mt-3">
        <div className="rounded-3 p-3 border">
          <div className="txt-invest-title">Tổng tích luỹ</div>
          <p className="txt-total-accumulation pt-1">
            {formatNumber(userInfo?.data?.walletInfo?.totalAccumulated)} VNĐ
          </p>
        </div>
        <div className="d-none d-lg-block">
          <div className="d-flex gap-3 pt-4">
            {AccumulationHistory.map((item) => {
              let checked = false;
              if (item.id === currentStatus) {
                checked = true;
              }
              return (
                <button
                  onClick={() => {
                    setCurrentStatus(item.id);
                    if (item.id === 0) {
                      getAccuList();
                    } else {
                      getListTransactions(1);
                    }
                  }}
                  className={
                    checked
                      ? "btn border border-0 p-0 text-dark fw-medium"
                      : "btn border border-0 p-0 text-secondary fw-medium"
                  }
                  key={item.id}
                >
                  {item.text}
                  <div
                    className={
                      checked
                        ? "border border-danger mt-1"
                        : "border border-white rounded-5 mt-1"
                    }
                  />
                </button>
              );
            })}
          </div>
          <div className="border w-100 mb-3" />
          {currentStatus === 0 ? (
            <Table columns={columns} dataSource={dataAccumulation} />
          ) : (
            <div>
              {dataTransaction.map((item: any) => (
                <div className="mb-3" key={item?._id}>
                  <div>{item?.content}</div>
                  <div className="pt-1 text-success fw-semibold">
                    +{formatNumber(item?.amount)}đ
                  </div>
                </div>
              ))}
              <div className="d-flex align-items-center justify-content-center">
                <Pagination
                  defaultCurrent={1}
                  onChange={onChange}
                  total={1000}
                />
              </div>
            </div>
          )}
        </div>
        <div className="d-block d-lg-none">
          <div className="d-flex gap-3 pt-4">
            {AccumulationHistory.map((item, index) => {
              let checked = false;
              if (item.id === currentStatus) {
                checked = true;
              }
              return (
                <button
                  onClick={() => {
                    setCurrentStatus(item.id);
                    if (item.id === 0) {
                      getAccuList();
                    } else {
                      getListTransactions(1);
                    }
                  }}
                  className={
                    checked
                      ? "btn border border-0 p-0 text-dark fw-medium"
                      : "btn border border-0 p-0 text-secondary fw-medium"
                  }
                  key={item.id}
                >
                  {item.text}
                  <div
                    className={
                      checked
                        ? "border border-danger mt-1"
                        : "border border-white rounded-5 mt-1"
                    }
                  />
                </button>
              );
            })}
          </div>
          {currentStatus === 0 ? (
            dataAccumulation.map((item: any, index: number) => (
              <div className="mt-3">
                <div className="d-flex gap-2 align-items-center">
                  <p className="txt-table-title">#</p>
                  <p className="txt-table-title">{index + 1}</p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="txt-table-title">Gói tích luỹ</p>
                  <p className="txt-table-title">{item?.timeDeposit} tháng</p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="txt-table-title">Lãi suất</p>
                  <p className="txt-table-title">{item?.interestRate}%</p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="txt-table-title">Số tiền</p>
                  <p className="txt-table-title">
                    {formatNumber(item?.amount)}đ
                  </p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="txt-table-title">Trạng thái</div>
                  <div className="bg-success text-white rounded-5 px-2 py-1 text-center fw-semibold">
                    {item?.status === "Open" ? "Đang mở" : "Đóng"}
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center pt-3">
                  <p className="txt-table-title">Ngày bắt đầu</p>
                  <p className="txt-table-title">
                    {moment(item?.createdDate).format("DD-MM-YYYY hh:mm:ss")}
                  </p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="txt-table-title">Ngày đáo hạn</p>
                  <p className="txt-table-title">
                    {moment(item?.maturityDate).format("DD-MM-YYYY hh:mm:ss")}
                  </p>
                </div>
                <div className="border w-100" />
              </div>
            ))
          ) : (
            <div>
              {dataTransaction.map((item: any) => (
                <div className="mt-3" key={item?._id}>
                  <div>{item?.content}</div>
                  <div className="pt-1 text-success fw-semibold">
                    +{formatNumber(item?.amount)}đ
                  </div>
                </div>
              ))}
              <div className="d-flex align-items-center justify-content-center">
                <Pagination
                  defaultCurrent={1}
                  onChange={onChange}
                  total={1000}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <CreateAccmulation
        data={data}
        isOpen={openCreateAccumulation}
        isClose={() => setOpenCreateAccumulation(false)}
        onUpdate={() => getAccuList()}
      />
    </div>
  );
};

export default memo(Accumulation);
