import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunk } from 'src/components/Redux/store';
import Homes from 'src/controllers/Home';

const homeInitialState = {};

export const getHome = createAsyncThunk('home/getHome', async () => {
  const resp = await Homes.getHomePage();
  return resp;
});

export const getNews = createAsyncThunk('home/getNews', async (params: any) => {
  const resp = await Homes.getNews(params);
  return resp;
});

export const getNewDetail = createAsyncThunk(
  'home/getNewDetail',
  async (id: string) => {
    const resp = await Homes.getNewDetail(id);
    return resp;
  },
);

export const getProducts = createAsyncThunk(
  'home/getProducts',
  async (params: any) => {
    const resp = await Homes.getProducts(params);
    return resp;
  },
);

const home = createSlice({
  name: 'home',
  initialState: homeInitialState,
  reducers: {},
});

const { actions, reducer } = home;
export default reducer;
