import React, { memo, useState } from "react";
import AuthImages from "src/assets/Images/Auth";
import HomeImages from "src/assets/Images/Home";
import "./Auth.css";
import { Input } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useAppDispatch } from "src/components/Redux/store";
import {
  restPassword,
  setUserLogin,
} from "src/components/Features/user/userSlice";
import { useSelector } from "react-redux";
import { selectLoading } from "src/components/Redux/selector";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector(selectLoading);
  const [email, setEmail] = useState("");

  const onSubmit = async () => {
    const params = {
      email,
    };
    const resp: any = await dispatch(restPassword(params));
    if (resp?.payload?.status === true) {
      toast.success(`Mã OTP đã được gửi đến ${email}`);
      navigate("/otp-forgot-password", { state: { email } });
    }
  };

  return (
    <div>
      <div className="container-xxl py-4">
        <button
          className="btn border border-0 p-0"
          onClick={() => navigate("/")}
        >
          <img src={AuthImages.logo} width={154} height={48} />
        </button>
      </div>
      <div className="main py-5">
        <div className="container-xxl d-none d-sm-block">
          <div className="row align-items-center">
            <div className="col-md-7">
              {/* <div className="txt-title-auth">
                Sàn thương mại điện tử khởi nghiệp
              </div> */}
              <img src={HomeImages.logo} width={386} height={120} />
            </div>
            <div className="rounded p-4 bg-white col-md-4">
              <div className="txt-title-right">Quên mật khẩu</div>
              <div className="pt-4">
                Nhập email đăng ký tài khoản của bạn để đặt lại mật khẩu
              </div>
              <div className="title-input pt-4">Email</div>
              <Input
                placeholder="email@gmail.com"
                className="mt-1"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{
                  height: 40,
                }}
              />

              <button className="btn-login" onClick={onSubmit}>
                {isLoading ? (
                  <div
                    className="spinner-border spinner-border-sm text-light"
                    role="status"
                  ></div>
                ) : (
                  `Gửi mã`
                )}
              </button>
              <button className="btn-register" onClick={() => navigate(-1)}>
                <ArrowLeftOutlined /> Quay lại trang đăng nhập
              </button>
            </div>
          </div>
        </div>

        <div className="d-block d-sm-none d-flex flex-column align-items-center">
          <img src={HomeImages.logo} width={160} height={50} />
          <div className="rounded p-4 w-100">
            <div className="title-input-sm">Quên mật khẩu</div>
            <Input
              placeholder="email@gmail.com"
              className="mt-1"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{
                height: 40,
              }}
            />
            <button className="btn-login-sm" onClick={onSubmit}>
              {isLoading ? (
                <div
                  className="spinner-border spinner-border-sm text-danger"
                  role="status"
                ></div>
              ) : (
                `Gửi mã`
              )}
            </button>
            <button
              className="btn border border-0 text-white w-100"
              onClick={() => navigate(-1)}
            >
              <ArrowLeftOutlined /> Quay lại trang đăng nhập
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ForgotPassword);
