import { Modal } from "antd";
import React, { useState } from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import type { UploadChangeParam } from "antd/es/upload";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { useAppDispatch } from "src/components/Redux/store";
import {
  uploadImage,
  walletImagePayment,
} from "src/components/Features/wallet/walletSlice";
import { toast } from "react-toastify";

const RechargeStep3 = (props: any) => {
  const { isOpen, isClose, dataStep1 } = props;
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Chọn ảnh giao dịch</div>
    </div>
  );

  const onSubmit = async () => {
    if (!fileList.length) {
      toast.error("Bạn bắt buộc phải có ảnh giao dịch !");
    } else {
      const formData: any = new FormData();
      formData.append("image", fileList[0]?.originFileObj);
      const resp: any = await dispatch(uploadImage(formData));
      console.log("resp upload", resp);
      if (resp?.payload?.status === true) {
        const params = {
          walletTransactionID: dataStep1?.walletTransactionID,
          image: resp?.payload?.data[0]?.path,
        };
        const res: any = await dispatch(walletImagePayment(params));
        if (res?.payload?.status === true) {
          isClose();
          toast.success("Thành công!");
        }
      }
    }
  };

  return (
    <Modal
      title={"Nạp tiền vào ví"}
      open={isOpen}
      footer={null}
      closeIcon={null}
      onCancel={() => isClose()}
      width={480}
      maskClosable={false}
      keyboard={false}
    >
      <Upload
        listType="picture-card"
        className="avatar-uploader"
        action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
        // beforeUpload={beforeUpload}
        onPreview={handlePreview}
        onChange={handleChange}
        maxCount={1}
        fileList={fileList}
      >
        {fileList.length >= 1 ? null : uploadButton}
      </Upload>
      <button
        className="btn-submit-recharge p-2 d-flex mt-3"
        onClick={onSubmit}
      >
        Hoàn tất
      </button>
    </Modal>
  );
};

export default RechargeStep3;
