import React, { memo, useEffect, useState } from "react";
import "./MyBank.css";
import { useAppDispatch } from "src/components/Redux/store";
import { getListBank } from "src/components/Features/user/userSlice";
import moment from "moment";
import ProductImages from "src/assets/Images/Product";
import ModalDeleteBank from "src/components/Modals/ModalDeleteBank";
import ModalEditBank from "src/components/Modals/ModalEditBank";
import ModalAddBank from "src/components/Modals/ModalAddBank";

const MyBank = () => {
  const dispatch = useAppDispatch();
  const [openAddBank, setOpenAddBank] = useState(false);
  const [openEditBank, setOpenEditBank] = useState(false);
  const [openDeleteBank, setOpenDeleteBank] = useState(false);
  const [chooseBank, setChooseBank] = useState<any>(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const resp: any = await dispatch(getListBank());
    console.log("resp list bank---", resp);
    if (resp.payload.status === true) {
      const data = resp.payload.data || [];
      setData(data);
    }
  };

  return (
    <div className="col col-md-7 mt-5">
      <div className="bg-white py-4 px-3 rounded container-right">
        <div className="d-flex justify-content-between">
          <div className="txt-title-right">Tài khoản ngân hàng</div>
          <button className="btn-add-bank" onClick={() => setOpenAddBank(true)}>
            + Thêm mới
          </button>
        </div>
        <div className="border w-100 my-4" />
        {!data.length ? (
          <div>Bạn chưa thêm tài khoản ngân hàng nào !</div>
        ) : (
          data.map((item: any) => (
            <div key={item?._id} className="border rounded-3 p-4 mb-4">
              <div className="d-flex justify-content-between align-items-center ">
                <div className="d-flex">
                  <div className="txt-title-name-bank">
                    <p>Ngân hàng</p>
                    <p>Chi nhánh</p>
                    <p>Số tài khoản</p>
                    <p>Chủ tài khoản</p>
                    <p>Ngày tạo</p>
                    <p>Ngày cập nhật</p>
                  </div>
                  <div className="ps-4 txt-data-bank">
                    <p>{item?.bankName}</p>
                    <p>{item?.BankBranch}</p>
                    <p>{item?.bankNumber}</p>
                    <p>{item?.nameOfBankCardHolder}</p>
                    <p>
                      {moment(item?.createdAt).format("HH:mm:ss - DD/MM/YYYY")}
                    </p>
                    <p>
                      {moment(item?.updatedAt).format("HH:mm:ss - DD/MM/YYYY")}
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <img src={item?.logo} width={120} height={50} />
                  <div className="d-flex gap-2 pt-5">
                    <button
                      className="btn border border-0 p-0"
                      onClick={() => {
                        setChooseBank(item);
                        setOpenEditBank(true);
                      }}
                    >
                      <img
                        src={ProductImages.icon_edit}
                        width={40}
                        height={40}
                      />
                    </button>
                    <button
                      className="btn border border-0 p-0"
                      onClick={() => {
                        setChooseBank(item);
                        setOpenDeleteBank(true);
                      }}
                    >
                      <img
                        src={ProductImages.icon_delete}
                        width={40}
                        height={40}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      <ModalDeleteBank
        isOpen={openDeleteBank}
        isClose={() => setOpenDeleteBank(false)}
        userBankID={chooseBank?.userBankID}
        onUpdate={() => getData()}
      />

      <ModalEditBank
        isOpen={openEditBank}
        isClose={() => setOpenEditBank(false)}
        currentBank={chooseBank}
        onUpdate={() => getData()}
      />

      <ModalAddBank
        isOpen={openAddBank}
        isClose={() => setOpenAddBank(false)}
        onUpdate={() => getData()}
      />
    </div>
  );
};

export default memo(MyBank);
