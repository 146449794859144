import { RightOutlined } from "@ant-design/icons";
import React from "react";
import { useNavigate } from "react-router-dom";

const GuideNewUser = () => {
  const navigate = useNavigate();
  return (
    <div className="container-home">
      <div className="container-xxl py-5">
        <div className="d-flex align-items-center gap-2">
          <button
            className="btn border border-0 p-0 text-danger"
            onClick={() => navigate(-1)}
          >
            Trang chủ <RightOutlined />
          </button>
          <div>Hướng dẫn người mới</div>
        </div>
        <div className="bg-white rounded-1 p-3 mt-3 mb-5">
          <div className="txt-title">HƯỚNG DẪN NGƯỜI MỚI</div>
          <div className="border w-100 my-3" />
          <div className="fw-semibold">Bước 1: Tạo tài khoản</div>
          <div>· Tạo tài khoản mới với email bạn muốn đăng ký</div>
          <div className="fw-semibold">Bước 2: Nạp tiền vào ví cá nhân</div>
          <div>
            · * Chọn mục" Ví", lựa chọn hình thức nạp tiền với VNĐ hoặc USDT
          </div>
          <div>
            · * Nhập số tiền muốn nạp vào Ví, chụp lại ảnh màn hình bạn đã
            chuyển tiền tới FFE, chờ hệ thống xác nhận
          </div>
          <div className="fw-semibold">Bước 3: Thực hiện đầu tư tích luỹ</div>
          <div>
            · Chọn chính sách phù hợp với mục đích đầu tư của bạn, nhập số tiền
            bạn muốn Tích luỹ và đợi xác nhận từ hệ thống FFE
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuideNewUser;
