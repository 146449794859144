import React, { memo, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "./Home.css";
import { useAppDispatch } from "src/components/Redux/store";
import { getNews, getProducts } from "src/components/Features/home/homeSlice";
import { ListTitleProductHome } from "src/constants/Array";
import { Card } from "antd";
import { API_URL } from "src/controllers/Client";
import { useNavigate } from "react-router-dom";
import HomeImages from "src/assets/Images/Home";
import { useSelector } from "react-redux";
import { selectLoading } from "src/components/Redux/selector";
import Lottie from "react-lottie";
import loading from "src/assets/Lotties/loading.json";
import formatNumber from "src/common/FormatNumber";
import NavBar from "../Navbar";

const HomeNotRegister = () => {
  const myRef1 = useRef<any>(null);
  const myRef2 = useRef<any>(null);
  const myRef3 = useRef<any>(null);
  const myRef4 = useRef<any>(null);
  const dispatch = useAppDispatch();
  const isLoading = useSelector(selectLoading);
  const navigate = useNavigate();
  const [newFeed, setNewFeed] = useState([]);
  const [currentListProduct, setCurrentListProduct] = useState(0);
  const [dataProducts, setDataProducts] = useState([]);
  const [sliceNewFeed, setSliceNewFeed] = useState([]);
  const [dataProductSponsor, setDataProductSponsor] = useState([]);
  const [dataProductMonopoly, setDataProductMonopoly] = useState([]);
  const [dataProductAffillicate, setDataProductAffillicate] = useState([]);
  const [dataProductAgency, setDataProductAgency] = useState([]);

  useEffect(() => {
    getNewFeed();
    getProductSponsor();
    getProductMonopoly();
    getProductAffillicate();
    getProductAgency();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    swipeToSlide: true,
  };

  const getNewFeed = async () => {
    const resp: any = await dispatch(getNews({}));
    // console.log("resp", resp);
    if (resp.payload.status === true) {
      const data = resp?.payload?.data;
      const sliceData = data.slice(data?.length - 3, data?.length - 1);
      const sliceNewFeed = data.slice(0, data?.length - 3);
      setNewFeed(sliceNewFeed);
      setSliceNewFeed(sliceData);
    }
  };

  const getProductSponsor = async () => {
    const params = {
      search: {
        productType: "Sponsor",
      },
      limit: 12,
    };
    const resp: any = await dispatch(getProducts(params));
    console.log("resp product 123---", resp);
    const data = resp?.payload?.data;
    setDataProductSponsor(data);
  };

  const getProductMonopoly = async () => {
    const params = {
      search: {
        productType: "Monopoly",
      },
      limit: 12,
    };
    const resp: any = await dispatch(getProducts(params));
    // console.log('resp product---', resp);
    const data = resp?.payload?.data;
    setDataProductMonopoly(data);
  };

  const getProductAffillicate = async () => {
    const params = {
      search: {
        productType: "Affiliate",
      },
      limit: 12,
    };
    const resp: any = await dispatch(getProducts(params));
    // console.log('resp product affi---', resp);
    const data = resp?.payload?.data;
    setDataProductAffillicate(data);
  };
  const getProductAgency = async () => {
    const params = {
      search: {
        productType: "Agency",
      },
      limit: 12,
    };
    const resp: any = await dispatch(getProducts(params));
    console.log("resp product agency---", resp);
    const data = resp?.payload?.data;
    setDataProductAgency(data);
  };

  const onScroll = (index: number) => {
    setCurrentListProduct(index);
    switch (index) {
      case 0:
        myRef1.current.scrollIntoView();
        break;
      case 1:
        myRef2.current.scrollIntoView();
        break;
      case 2:
        myRef3.current.scrollIntoView();
        break;
      case 3:
        myRef4.current.scrollIntoView();
        break;

      default:
        break;
    }
  };

  return (
    <div className="container-home">
      <NavBar />
      <div className="bg-white pt-4 pb-5">
        <div className="container-xxl d-flex">
          <div className="container-newfeed-left">
            <Slider {...settings}>
              {newFeed.map((item: any, index: number) => {
                return (
                  <button
                    className="btn border border-0 p-0"
                    onClick={() =>
                      navigate("/new-detail", { state: { item: item } })
                    }
                  >
                    <img
                      key={index}
                      src={`${API_URL}${item?.urlImage}`}
                      className="img-newfeed"
                      alt=""
                    />
                  </button>
                );
              })}
            </Slider>
          </div>
          <div className="d-none d-md-block">
            <div className="d-flex flex-column align-items-center">
              {sliceNewFeed.map((item: any, index) => {
                return (
                  <button
                    className="btn border border-0 p-0 mb-1"
                    onClick={() =>
                      navigate("/new-detail", { state: { item: item } })
                    }
                  >
                    <img
                      key={index}
                      src={`${API_URL}${item?.urlImage}`}
                      className="img-newfeed-slice"
                      alt=""
                    />
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl d-flex gap-2 container-body-product">
        <div
          className="bg-white p-3 rounded d-none d-sm-block"
          style={{ height: 350 }}
        >
          <div className="txt-title-left">Danh mục</div>
          <div className="d-flex flex-column align-items-start">
            {ListTitleProductHome.map((item, index) => {
              let checked = false;
              if (index === currentListProduct) {
                checked = true;
              }
              return (
                <button
                  onClick={() => onScroll(index)}
                  className={
                    checked ? "btn-left-product" : "btn-left-block-product"
                  }
                  key={item.id}
                >
                  {item.text}
                </button>
              );
            })}
          </div>
        </div>
        <div className="bg-white pb-3 rounded w-100 mb-5">
          <div
            ref={myRef1}
            className="d-flex justify-content-between align-items-center  p-3"
          >
            <div className="txt-title-left">Sản phẩm tài trợ</div>
            <div
              onClick={() =>
                navigate("/all-products", {
                  state: { productType: "Sponsor", index: 0 },
                })
              }
              className="text-danger btn border border-0 p-0"
            >
              Xem thêm
            </div>
          </div>
          {/* <div className="d-block d-sm-none pt-3">
            <Slider
              dots={false}
              infinite={true}
              autoplay={false}
              swipeToSlide={true}
              slidesToShow={2}
              slidesToScroll={1}
            >
              {ListTitleProductHome.map((item, index) => {
                let checked = false;
                if (index === currentListProduct) {
                  checked = true;
                }
                return (
                  <button
                    onClick={() => {
                      setCurrentListProduct(index);
                    }}
                    className={
                      !checked
                        ? "btn border border-0 p-0"
                        : "btn border border-0 p-0 fw-bolder"
                    }
                    key={item.id}
                  >
                    {item.text}
                  </button>
                );
              })}
            </Slider>
          </div> */}
          {isLoading ? (
            <div className="w-100 d-flex align-items-center justify-content-center h-100">
              <Lottie
                options={{
                  loop: true,
                  animationData: loading,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                height={60}
                width={60}
              />
            </div>
          ) : (
            <>
              {dataProductSponsor.map((item: any, index) => {
                const findVideo = item?.images?.find(
                  (x: { includes: (arg0: string) => any }) =>
                    !x?.includes(".mp4")
                );
                return (
                  <Card
                    key={item?.productID}
                    onClick={() =>
                      navigate(`/product/detail?id=${item?.productID}`, {
                        state: { productID: item?.productID },
                      })
                    }
                    hoverable
                    className=" btn mt-4 p-0 card-items shadow-sm"
                    cover={
                      <img
                        src={
                          findVideo
                            ? `${API_URL}${findVideo}`
                            : HomeImages.logo_product
                        }
                        className="img-product"
                      />
                    }
                  >
                    <div
                      className="txt-item-product d-block d-md-none"
                      style={{
                        fontSize: 14,
                      }}
                    >
                      {item?.productName.substring(0, 14) + "..."}
                    </div>
                    <div className="txt-item-product d-none d-md-block">
                      {item?.productName.substring(0, 30) + "..."}
                    </div>
                    {item?.priceCompare > 0 && (
                      <div className="txt-price-compare">
                        {formatNumber(item?.priceCompare)}đ
                      </div>
                    )}
                    <div className="txt-price">
                      {formatNumber(item?.price + item?.priceVocher)}đ
                    </div>
                    <div className="txt-sold">Đã bán: {item?.sold}</div>
                  </Card>
                );
              })}

              <div
                ref={myRef2}
                className="d-flex justify-content-between align-items-center border-top border-5 w-100 border-light-subtle mt-5 mb-2 pe-3"
              >
                <div className="txt-title-left p-3">Sản phẩm độc quyền</div>
                <div
                  className="text-danger btn border border-0 p-0"
                  onClick={() =>
                    navigate("/all-products", {
                      state: { productType: "Monopoly", index: 1 },
                    })
                  }
                >
                  Xem thêm
                </div>
              </div>
              {dataProductMonopoly.map((item: any, index) => {
                const findVideo = item?.images?.find(
                  (x: { includes: (arg0: string) => any }) =>
                    !x?.includes(".mp4")
                );
                return (
                  <Card
                    key={item?.productID}
                    onClick={() =>
                      navigate(`/product/detail?id=${item?.productID}`, {
                        state: { productID: item?.productID },
                      })
                    }
                    hoverable
                    className=" btn mt-4 p-0 card-items shadow-sm"
                    cover={
                      <img
                        src={
                          findVideo
                            ? `${API_URL}${findVideo}`
                            : HomeImages.logo_product
                        }
                        className="img-product"
                      />
                    }
                  >
                    <div
                      className="txt-item-product d-block d-md-none"
                      style={{
                        fontSize: 14,
                      }}
                    >
                      {item?.productName.substring(0, 14) + "..."}
                    </div>
                    <div className="txt-item-product d-none d-md-block">
                      {item?.productName.substring(0, 30) + "..."}
                    </div>
                    {item?.priceCompare > 0 && (
                      <div className="txt-price-compare">
                        {formatNumber(item?.priceCompare)}đ
                      </div>
                    )}
                    <div className="txt-price">
                      {formatNumber(item?.price + item?.priceVocher)}đ
                    </div>
                    <div className="txt-sold">Đã bán: {item?.sold}</div>
                  </Card>
                );
              })}
              <div
                ref={myRef3}
                className="d-flex justify-content-between align-items-center border-top border-5 w-100 border-light-subtle mt-5 mb-2 pe-3"
              >
                <div className="txt-title-left p-3">Sản phẩm liên kết</div>
                <div
                  className="text-danger btn border border-0 p-0"
                  onClick={() =>
                    navigate("/all-products", {
                      state: { productType: "Affiliate", index: 2 },
                    })
                  }
                >
                  Xem thêm
                </div>
              </div>
              {dataProductAffillicate.map((item: any, index) => {
                const findVideo = item?.images?.find(
                  (x: { includes: (arg0: string) => any }) =>
                    !x?.includes(".mp4")
                );
                return (
                  <Card
                    key={item?.productID}
                    onClick={() =>
                      navigate(`/product/detail?id=${item?.productID}`, {
                        state: { productID: item?.productID },
                      })
                    }
                    hoverable
                    className=" btn mt-4 p-0 card-items shadow-sm"
                    cover={
                      <img
                        src={
                          findVideo
                            ? `${API_URL}${findVideo}`
                            : HomeImages.logo_product
                        }
                        className="img-product"
                      />
                    }
                  >
                    <div
                      className="txt-item-product d-block d-md-none"
                      style={{
                        fontSize: 14,
                      }}
                    >
                      {item?.productName.substring(0, 14) + "..."}
                    </div>
                    <div className="txt-item-product d-none d-md-block">
                      {item?.productName.substring(0, 30) + "..."}
                    </div>
                    {item?.priceCompare > 0 && (
                      <div className="txt-price-compare">
                        {formatNumber(item?.priceCompare)}đ
                      </div>
                    )}
                    <div className="txt-price">
                      {formatNumber(item?.price + item?.priceVocher)}đ
                    </div>
                    <div className="txt-sold">Đã bán: {item?.sold}</div>
                  </Card>
                );
              })}
              <div
                ref={myRef4}
                className="d-flex justify-content-between align-items-center border-top border-5 w-100 border-light-subtle mt-5 mb-2 pe-3"
              >
                <div className="txt-title-left p-3">Gói đại lý</div>
                <div
                  className="text-danger btn border border-0 p-0"
                  onClick={() =>
                    navigate("/all-products", {
                      state: { productType: "Agency", index: 3 },
                    })
                  }
                >
                  Xem thêm
                </div>
              </div>
              {dataProductAgency.map((item: any, index) => {
                const findVideo = item?.images?.find(
                  (x: { includes: (arg0: string) => any }) =>
                    !x?.includes(".mp4")
                );
                return (
                  <Card
                    key={item?.productID}
                    onClick={() =>
                      navigate(`/product/detail?id=${item?.productID}`, {
                        state: { productID: item?.productID },
                      })
                    }
                    hoverable
                    className=" btn mt-4 p-0 card-items shadow-sm"
                    cover={
                      <img
                        src={
                          findVideo
                            ? `${API_URL}${findVideo}`
                            : HomeImages.logo_product
                        }
                        className="img-product"
                      />
                    }
                  >
                    <div
                      className="txt-item-product d-block d-md-none"
                      style={{
                        fontSize: 14,
                      }}
                    >
                      {item?.productName.substring(0, 14) + "..."}
                    </div>
                    <div className="txt-item-product d-none d-md-block">
                      {item?.productName.substring(0, 30) + "..."}
                    </div>
                    {item?.priceCompare > 0 && (
                      <div className="txt-price-compare">
                        {formatNumber(item?.priceCompare)}đ
                      </div>
                    )}
                    <div className="txt-price">
                      {formatNumber(item?.price + item?.priceVocher)}đ
                    </div>
                    <div className="txt-sold">Đã bán: {item?.sold}</div>
                  </Card>
                );
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(HomeNotRegister);
