import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import Address from 'src/controllers/Address';
const addressInitialState = {};

export const getListProvince = createAsyncThunk(
  'address/getListProvince',
  async () => {
    const resp = await Address.getListProvince();
    return resp;
  },
);

export const getListDistrict = createAsyncThunk(
  'address/getListDistrict',
  async (id: string) => {
    const resp = await Address.getListDistrict(id);
    return resp;
  },
);

export const getListWard = createAsyncThunk(
  'address/getListWard',
  async (id: string) => {
    const resp = await Address.getListWard(id);
    return resp;
  },
);

export const createAddress = createAsyncThunk(
  'address/createAddress',
  async (params: any) => {
    const resp = await Address.createAddress(params);
    return resp;
  },
);

export const updateAddress = createAsyncThunk(
  'address/updateAddress',
  async (params: any) => {
    const resp = await Address.updateAddress(params);
    return resp;
  },
);

export const removeAddress = createAsyncThunk(
  'address/removeAddress',
  async (params: any) => {
    const resp = await Address.removeAddress(params);
    return resp;
  },
);

const address = createSlice({
  name: 'address',
  initialState: addressInitialState,
  reducers: {},
});

const { actions, reducer } = address;
export default reducer;
