import client from "./Client";

const Address = {
  async getListProvince() {
    try {
      const res = await client.get(`/api/list-province`);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async getListDistrict(id: string) {
    try {
      const res = await client.get(`/api/list-district/${id}`);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async getListWard(id: string) {
    try {
      const res = await client.get(`/api/list-ward/${id}`);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async createAddress(params: any) {
    try {
      const res = await client.post(`/api/user/address-create`, params);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async updateAddress(params: any) {
    try {
      const res = await client.post(`/api/user/address-update`, params);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async removeAddress(params: any) {
    try {
      const res = await client.post(`/api/user/remove-address`, params);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },
};

export default Address;
