import React from "react";
import NavBar from "../Navbar";
import { useSelector } from "react-redux";
import { userInfor } from "src/components/Redux/selector";

function WarrantyPolicy() {
  const user = useSelector(userInfor);

  return (
    <div>
      {!user && <NavBar />}
      <div className="container bg-white rounded-1 p-3 d-flex flex-column">
        <div className="py-3 fw-semibold fs-4 text-center">
          Chính sách bảo hành
        </div>
        <div className="fw-semibold">1. Trường hợp được bảo hành:</div>
        <div>- Sản phẩm trong thời hạn còn bảo hành</div>
        <div>- Sản phẩm được bảo hành theo quy định của nhà cung cấp</div>
        <div>- Quý khách xuất trình phiếu bảo hành khi bảo hành.</div>
        <div className="pt-1 fw-semibold">
          2. Trường hợp không được bảo hành:
        </div>
        <div>
          - Sản phẩm đã quá thời hạn ghi trên Phiếu bảo hành hoặc mất Phiếu bảo
          hành.
        </div>
        <div>
          - Phiếu bảo hành không ghi rõ mã số sản phẩm và ngày mua hàng.
        </div>
        <div>
          - Mã số sản phẩm và Phiếu bảo hành không trùng khớp nhau hoặc không
          xác định được vì bất kỳ lý do nào.
        </div>
        <div>- Sản phẩm bị trầy xước do quá trình sử dụng lâu ngày</div>
        <div>- Sản phẩm bị bể móp, biến dạng do bị va đập</div>
        <div>
          - Khách hàng tự ý can thiệp vào máy của sản phẩm hoặc đem đến một nơi
          nào khác sửa chữa.
        </div>
        <div className="pt-1 fw-semibold">
          3. Điều kiện đổi trả hàng hoặc hoàn tiền 100%:
        </div>
        <div>
          - Sản phẩm nhận được nếu xảy ra lỗi, được nhân viên kĩ thuật của nhà
          sản xuất lập biên bản cho phép đổi trả.
        </div>
        <div>
          - Sản phẩm không giống với sản phẩm mà Quý khách đã đặt hàng trên
          website của chúng tôi.
        </div>
      </div>
    </div>
  );
}

export default WarrantyPolicy;
