

const ProductImages = {
    account_balance_wallet: require('./account_balance_wallet.png'),
    account_balance: require('./account_balance.png'),
    success: require('./success.png'),
    icon_edit: require('./icon_edit.png'),
    icon_delete: require('./icon_delete.png'),
    icon_delete_grey: require('./icon_delete_grey.png'),
}

export default ProductImages