import { Input, Modal, Select } from "antd";
import Checkbox, { CheckboxChangeEvent } from "antd/es/checkbox";
import React, { memo, useEffect, useState } from "react";
import {
  createAddress,
  getListDistrict,
  getListProvince,
  getListWard,
  updateAddress,
} from "src/components/Features/address/addressSlice";
import { useAppDispatch } from "src/components/Redux/store";
import { User } from "src/interface";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selectLoading } from "src/components/Redux/selector";
const ModalEditAddress = (props: any) => {
  const { isOpen, isClose, isSubmit, chooseAddress } = props;
  const dispatch = useAppDispatch();
  const isLoading = useSelector(selectLoading);
  const [fullname, setFullname] = useState("");
  const [phone, setPhone] = useState("");
  const [data, setData] = useState([]);
  const [dataDistrict, setDataDistrict] = useState([]);
  const [dataWard, setDataWard] = useState([]);
  const [chooseProvince, setChooseProvince] = useState<any>(null);
  const [chooseDistrict, setChooseDistrict] = useState<any>(null);
  const [chooseWard, setChooseWard] = useState<any>(null);
  const [defaultAddress, setDefaultAddress] = useState(false);
  const [address, setAddress] = useState("");

  useEffect(() => {
    getAddress();
  }, []);

  const getAddress = async () => {
    const resp: any = await dispatch(getListProvince());
    if (resp.payload.status === true) {
      const data = resp?.payload?.data;
      const mapData = data.map((item: User) => {
        return {
          value: item?.provinceID,
          label: item?.provinceName,
        };
      });
      setData(mapData);
    }
  };

  const getDistrict = async (provinceID: string) => {
    const resp: any = await dispatch(getListDistrict(provinceID));
    if (resp.payload.status === true) {
      const data = resp?.payload?.data;
      const mapData = data.map((item: User) => {
        return {
          value: item?.districtID,
          label: item?.districtName,
        };
      });
      setDataDistrict(mapData);
    }
  };

  const onChange = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`);
    setDefaultAddress(e.target.checked);
  };

  const getWard = async (districtID: string) => {
    const resp: any = await dispatch(getListWard(districtID));
    if (resp.payload.status === true) {
      // console.log("resp.payload", resp.payload);
      const data = resp?.payload?.data;
      const mapData = data.map((item: User) => {
        return {
          value: item?.wardID,
          label: item?.wardName,
        };
      });

      setDataWard(mapData);
    }
  };

  const onChooseProvince = (value: { value: string }) => {
    getDistrict(value?.value);
    setChooseProvince(value);
  };

  const onChooseDistrict = (value: { value: string }) => {
    getWard(value?.value);
    setChooseDistrict(value);
  };

  const onAdd = async () => {
    const params = {
      name: fullname ? fullname : chooseAddress?.name,
      type: "Company",
      isDefault: defaultAddress ? defaultAddress : chooseAddress?.isDefault,
      phone: phone ? phone : chooseAddress?.phone,
      provinceID: chooseProvince?.value
        ? +chooseProvince?.value
        : chooseAddress?.provinceID,
      provinceName: chooseProvince?.label
        ? chooseProvince?.label
        : chooseProvince?.provinceName,
      districtID: chooseDistrict?.value
        ? +chooseDistrict?.value
        : chooseAddress?.districtID,
      districtName: chooseDistrict?.label
        ? chooseDistrict?.label
        : chooseAddress?.districtName,
      wardID: chooseWard?.value ? +chooseWard?.value : chooseAddress?.wardID,
      wardName: chooseWard?.label ? chooseWard?.label : chooseAddress?.wardName,
      address: address ? address : chooseAddress?.address,
      userAddressID: chooseAddress?.userAddressID,
    };

    const resp: any = await dispatch(updateAddress(params));
    console.log("resp", resp);

    if (resp?.payload?.status === true) {
      toast.success("Sửa địa chỉ thành công!");
      isClose();
      isSubmit();
    }
  };

  return (
    <Modal
      title={null}
      open={isOpen}
      footer={null}
      closeIcon={null}
      onCancel={() => isClose()}
      width={800}
    >
      <div className="fw-semibold fs-5">Sửa địa chỉ</div>
      <div className="d-flex gap-2">
        <div className="w-50">
          <div className="title-input pt-4 d-flex">
            Họ tên <div className="text-danger ps-1"> *</div>
          </div>
          <Input
            placeholder={chooseAddress?.name}
            className="mt-1"
            value={fullname}
            onChange={(e) => setFullname(e.target.value)}
            style={{
              height: 40,
            }}
          />
        </div>
        <div className="w-50">
          <div className="title-input pt-4 d-flex">
            Số điện thoại <div className="text-danger ps-1"> *</div>
          </div>
          <Input
            placeholder={chooseAddress?.phone}
            className="mt-1"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            style={{
              height: 40,
            }}
          />
        </div>
      </div>
      <div className="d-flex gap-2">
        <div className="w-50">
          <div className="title-input pt-4 d-flex">
            Tỉnh/Thành phố <div className="text-danger ps-1"> *</div>
          </div>
          <Select
            showSearch
            style={{ width: "100%", height: 40 }}
            className="mt-1"
            placeholder={chooseAddress?.provinceName}
            optionFilterProp="children"
            onChange={(value, opt) => onChooseProvince(opt)}
            filterOption={(input, option: any) =>
              (option?.label ?? "").includes(input)
            }
            filterSort={(optionA: any, optionB: any) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={data}
          />
        </div>
        <div className="w-50">
          <div className="title-input pt-4 d-flex">
            Quận/Huyện <div className="text-danger ps-1"> *</div>
          </div>
          <Select
            showSearch
            style={{ width: "100%", height: 40 }}
            className="mt-1"
            placeholder={chooseAddress?.districtName}
            optionFilterProp="children"
            onChange={(value, otp) => onChooseDistrict(otp)}
            filterOption={(input, option: any) =>
              (option?.label ?? "").includes(input)
            }
            filterSort={(optionA: any, optionB: any) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={dataDistrict}
          />
        </div>
      </div>
      <div className="w-100">
        <div className="title-input pt-4 d-flex">
          Xã/Phường <div className="text-danger ps-1"> *</div>
        </div>
        <Select
          showSearch
          style={{ width: "100%", height: 40 }}
          className="mt-1"
          placeholder={chooseAddress?.wardName}
          optionFilterProp="children"
          onChange={(value, opt) => setChooseWard(opt)}
          filterOption={(input, option: any) =>
            (option?.label ?? "").includes(input)
          }
          filterSort={(optionA: any, optionB: any) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          options={dataWard}
        />
      </div>
      <div className="title-input pt-4">Địa chỉ cụ thể</div>
      <Input
        placeholder={chooseAddress?.address}
        className="mt-1"
        style={{ height: 80 }}
        value={address}
        onChange={(text) => setAddress(text.target.value)}
      />
      <Checkbox onChange={onChange} className="mt-2 mb-5">
        Đặt làm địa chỉ mặc định
      </Checkbox>
      <div className="d-flex justify-content-end">
        <div className="d-flex gap-2">
          <button className="btn-modal-close" onClick={() => isClose()}>
            Hủy
          </button>
          <button
            className="btn-submit"
            onClick={onAdd}
            style={{
              width: 120,
            }}
          >
            {isLoading ? (
              <div
                className="spinner-border spinner-border-sm text-danger"
                role="status"
              ></div>
            ) : (
              `Cập nhật`
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default memo(ModalEditAddress);
