import { DatePicker, Input, Modal } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { updateProfile } from "src/components/Features/user/userSlice";
import { useAppDispatch } from "src/components/Redux/store";
import { ModalProps } from "src/constants/Interface";
import useGetProfile from "src/hooks/useGetProfile";
import "../Profile.css";

const ModalUpdateProfile = (props: ModalProps) => {
  const { isOpen, isClose } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isUserData: any = useGetProfile();
  const [fullname, setFullname] = useState("");
  const [phone, setPhone] = useState("");
  const [birhday, setBirhday] = useState("");

  const onUpdate = async () => {
    if (!phone || !fullname || !birhday) {
      toast.error("Vui lòng nhập đầy đủ thông tin");
    } else if (phone.length < 10) {
      toast.error("Vui lòng nhập đúng số điện thoại");
    } else {
      const params = {
        fullName: fullname,
        phoneNumber: phone,
        birthDay: birhday,
      };
      const resp: any = await dispatch(updateProfile(params));
      if (resp.payload.status === true) {
        toast.success("Cập nhật thành công!");
        isClose();
      }
    }
  };

  return (
    <Modal
      title="Cập nhật thông tin tài khoản"
      open={isOpen}
      footer={null}
      closeIcon={null}
      onCancel={() => isClose()}
      width={480}
    >
      <div className="title-input pt-4 d-flex">
        Họ tên <div className="text-danger ps-1"> *</div>
      </div>
      <Input
        placeholder={isUserData?.data?.fullName}
        className="mt-1 input-email"
        value={fullname}
        onChange={(e) => setFullname(e.target.value)}
        style={{
          height: 40,
        }}
      />
      <div className="title-input pt-3 d-flex">
        Email <div className="text-danger ps-1"> *</div>
      </div>
      <Input
        className="mt-1 input-email"
        value={isUserData?.data?.email}
        style={{
          height: 40,
        }}
      />
      <div className="title-input pt-3 d-flex">
        Số điện thoại <div className="text-danger ps-1"> *</div>
      </div>
      <Input
        placeholder={isUserData?.data?.phoneNumber}
        className="mt-1 input-email"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        style={{
          height: 40,
        }}
        maxLength={10}
      />
      <div className="title-input pt-3 d-flex">
        Ngày sinh <div className="text-danger ps-1"> *</div>
      </div>
      <DatePicker
        // defaultValue={dayjs(isUserData?.data?.birthDay, "DD/MM/YYYY")}
        placeholder={
          isUserData?.data?.birthDay
            ? moment(isUserData?.data?.birthDay).format("DD/MM/YYYY")
            : "Chọn ngày sinh"
        }
        format={"YYYY/MM/DD"}
        className="w-100 mt-1"
        onChange={(date, dateString: any) => setBirhday(dateString)}
      />

      <div className="d-flex justify-content-end pt-5 gap-2">
        <button className="btn-close-modal" onClick={() => isClose()}>
          Huỷ
        </button>
        <button className="btn-submit-modal" onClick={() => onUpdate()}>
          Cập nhật
        </button>
      </div>
    </Modal>
  );
};

export default memo(ModalUpdateProfile);
