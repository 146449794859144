import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import moment from "moment";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import formatNumber from "src/common/FormatNumber";
import "./WalletDetail.css";
import { transactionCancel } from "src/components/Features/wallet/walletSlice";
import { useAppDispatch } from "src/components/Redux/store";
import { toast } from "react-toastify";
import { setNavigateProfile } from "src/components/Features/user/userSlice";

const WalletDetail = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const item = state?.item;

  const renderType: any = {
    Pending: "Đang xử lý",
    Success: "Thành công",
    Cancel: "Lỗi hệ thống",
  };

  const renderColor: any = {
    Pending: "#EFA023",
    Success: "#5EBB25",
    Cancel: "#CA2D35",
  };

  const onCancel = async () => {
    const params = {
      walletTransactionID: item?.walletTransactionID,
    };
    const resp: any = await dispatch(transactionCancel(params));
    console.log("resp cancel----", resp);

    if (resp?.payload?.status === true) {
      toast.success("Hủy giao dịch thành công !");
      navigate(-1);
    }
  };

  return (
    <div className="col col-md-9 mt-5">
      <div className="d-flex gap-2 ps-4 pb-3">
        <button
          className="btn border border-0 p-0 text-danger"
          onClick={() => {
            dispatch(setNavigateProfile(false));
          }}
        >
          Trang chủ <RightOutlined />
        </button>
        <button
          className="btn border border-0 p-0 text-danger"
          onClick={() => navigate(-1)}
        >
          Ví của tôi <RightOutlined />
        </button>
        <div>Chi tiết giao dịch</div>
      </div>
      <div className="bg-white p-4 rounded-2 container-right">
        <div className="col row">
          <div className="col-lg-6">
            <div className="txt-title-right">Thông tin giao dịch</div>
            <div className="d-flex justify-content-between align-items-center pt-2">
              <p className="fw-semibold">Mã giao dịch</p>
              <p className="text-primary">{item?.transactionCode}</p>
            </div>
            {/* <div className="d-flex justify-content-between align-items-center">
              <p className="fw-semibold">Loại giao dịch</p>
              <p>{ListStatusWallet[currentStatus].text}</p>
            </div> */}
            <div className="d-flex justify-content-between align-items-center">
              <p className="fw-semibold">Tài khoản nguồn</p>
              <div className="d-flex align-items-end flex-column">
                <div className="fw-semibold">{item?.userInfo?.fullName}</div>
                <div className="fw-semibold">{item?.userInfo?.email}</div>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center pt-2">
              <p className="fw-semibold">Tài khoản đích</p>
              <div className="d-flex align-items-end flex-column">
                <div className="fw-semibold">{item?.bankCode}</div>
                <div className="fw-semibold">{item?.bankNumber}</div>
                <div className="fw-semibold">{item?.accountName}</div>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center py-2">
              <p className="fw-semibold">Số tiền</p>
              <p className="fw-semibold">{formatNumber(item?.amount)}đ</p>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <p className="fw-semibold">Tình trạng</p>
              <div
                className="text-white rounded-3 p-2"
                style={{
                  background: renderColor[item?.status],
                }}
              >
                {renderType[item?.status]}
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center py-2">
              <p className="fw-semibold">Ngày tạo</p>
              <div>
                <div>
                  {moment(item?.createdAt).format("DD-MM-YYYY hh:mm:ss")}
                </div>
                <div>
                  {moment(item?.updatedAt).format("DD-MM-YYYY hh:mm:ss")}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center pb-2">
              <p className="fw-semibold">Nội dung</p>
              <p>{item?.content}</p>
            </div>
          </div>
          <div className="col-lg-6 d-flex flex-column align-items-center">
            <div className="txt-title-right">Ảnh giao dịch</div>
            <img src={item?.url} className="mt-3" height={334} alt="" />
          </div>
        </div>

        <div className="d-flex gap-2 justify-content-end">
          <button className="btn-back-wallet" onClick={() => navigate(-1)}>
            <LeftOutlined /> Quay về danh sách
          </button>
          <button className="btn-cancel-wallet" onClick={onCancel}>
            Hủy giao dịch
          </button>
        </div>
      </div>
    </div>
  );
};

export default WalletDetail;
