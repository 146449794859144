import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Input, Modal } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { changePassword } from "src/components/Features/user/userSlice";
import { selectLoading } from "src/components/Redux/selector";
import { useAppDispatch } from "src/components/Redux/store";
import { ModalProps } from "src/constants/Interface";

const ModalChangePassword = (props: ModalProps) => {
  const { isOpen, isClose } = props;
  const dispatch = useAppDispatch();
  const isLoading = useSelector(selectLoading);
  const [newPassword, setNewPassword] = useState("");
  const [reNewPassword, setReNewPassword] = useState("");

  const handleChangePassword = async () => {
    const params = {
      newPassword,
      repeatNewPassword: reNewPassword,
    };
    const resp: any = await dispatch(changePassword(params));
    console.log("resp", resp);
    if (resp?.payload?.status === true) {
      toast.success("Đổi mật khẩu thành công!");
      isClose();
    }
  };

  return (
    <Modal
      title="Thay đổi mật khẩu"
      open={isOpen}
      footer={null}
      closeIcon={null}
      onCancel={() => isClose()}
      width={400}
    >
      <div className="title-input pt-4">Mật khẩu mới</div>
      <Input.Password
        placeholder="Tối thiểu 6 chữ cái và số"
        className="mt-1"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        style={{
          height: 40,
        }}
        iconRender={(visible) =>
          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
        }
      />
      <div className="title-input pt-2">Nhập lại mật khẩu</div>
      <Input.Password
        placeholder="Nhập chính xác"
        className="mt-1"
        value={reNewPassword}
        onChange={(e) => setReNewPassword(e.target.value)}
        style={{
          height: 40,
        }}
        iconRender={(visible) =>
          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
        }
      />
      <button
        onClick={() => handleChangePassword()}
        className="btn-submit-change-password"
      >
        {isLoading ? (
          <div
            className="spinner-border spinner-border-sm text-light"
            role="status"
          ></div>
        ) : (
          `Xác nhận`
        )}
      </button>
    </Modal>
  );
};

export default ModalChangePassword;
