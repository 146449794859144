import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import Cart from 'src/controllers/Cart';
import { CartProps } from 'src/interface';

const cartInitialState = {};

export const getCardList = createAsyncThunk('cart/getCardList', async () => {
  const resp = await Cart.getCardList();
  return resp;
});

export const cardAdd = createAsyncThunk(
  'cart/cardAdd',
  async (params: CartProps) => {
    const resp = await Cart.cartAdd(params);
    return resp;
  },
);

export const cartUpdate = createAsyncThunk(
  'cart/cartUpdate',
  async (params: CartProps) => {
    const resp = await Cart.cartUpdate(params);
    return resp;
  },
);

export const orderCreate = createAsyncThunk(
  'cart/orderCreate',
  async (params: any) => {
    const resp = await Cart.orderCreate(params);
    return resp;
  },
);

const cart = createSlice({
  name: 'cart',
  initialState: cartInitialState,
  reducers: {},
});

const { actions, reducer } = cart;
export default reducer;
