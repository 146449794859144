import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getListOrder } from "src/components/Features/product/productSlice";
import { selectLoading } from "src/components/Redux/selector";
import { useAppDispatch } from "src/components/Redux/store";
import { ListStatusOrder } from "src/constants/Array";
import "./Order.css";
import moment from "moment";
import HomeImages from "src/assets/Images/Home";
import formatNumber from "src/common/FormatNumber";
import { useNavigate } from "react-router-dom";
import { API_URL } from "src/controllers/Client";

const ListOrder = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector(selectLoading);
  const [data, setData] = useState([]);
  const [currentStatus, setCurrentStatus] = useState(0);
  const [params, setParams] = useState<any>({
    limit: 100,
  });

  useEffect(() => {
    getData();
  }, [params]);

  const getData = async () => {
    const resp: any = await dispatch(getListOrder(params));
    console.log("resp list order---", resp);
    if (resp.payload.status === true) {
      const data = resp.payload.orders || [];
      setData(data);
    }
  };

  return (
    <div className="col col-md-9 mt-5">
      <div className="bg-white pt-4 rounded-1 container-right">
        <div className="txt-title-right ps-3">Đơn hàng</div>
        <div className="border w-100 my-3" />
        <div className="d-flex gap-3 ps-3">
          {ListStatusOrder.map((item) => {
            let checked = false;
            if (item.id === currentStatus) {
              checked = true;
            }
            return (
              <button
                onClick={() => {
                  setCurrentStatus(item.id);
                  if (item.id === 0) {
                    setParams({ limit: 100 });
                  } else {
                    setParams((prev: any) => ({
                      ...prev,
                      search: {
                        status: item.status,
                      },
                    }));
                  }
                }}
                className={
                  checked
                    ? "btn border border-0 p-0 text-dark fw-medium"
                    : "btn border border-0 p-0 text-secondary fw-medium"
                }
                key={item.id}
              >
                {item.text}
                <div
                  className={
                    checked
                      ? "border border-danger"
                      : "border border-white rounded-5"
                  }
                />
              </button>
            );
          })}
        </div>
      </div>
      {data.length === 0 ? (
        <div className="p-5">Bạn chưa có đơn hàng nào !</div>
      ) : (
        data.map((item: any) => (
          <div
            key={item?.orderID}
            className="bg-white rounded-1 container-right mt-3 p-3"
          >
            <div className="d-flex gap-2">
              <div className="txt-order-id">Đơn hàng {item?.orderID}</div>
              <div className="txt-order-time">
                Đặt ngày {moment(item?.createdAt).format("DD-MM-YYYY HH:mm:ss")}
              </div>
            </div>
            <div className="border w-100 my-3" />
            {item?.orderDetails?.map((i: any) => {
              const findVideo = i?.images?.find(
                (x: { includes: (arg0: string) => any }) => !x?.includes(".mp4")
              );
              return (
                <div className="mb-4" key={i?._id}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex gap-2">
                      <img
                        src={
                          findVideo
                            ? `${API_URL}${findVideo}`
                            : HomeImages.logo_product
                        }
                        width={92}
                        height={92}
                        alt=""
                      />
                      <div className="txt-product-name">
                        <div>{i?.productName}</div>
                        <div>x{i?.quantity}</div>
                      </div>
                    </div>
                    <div className="txt-product-price">
                      {formatNumber((i?.price + i?.priceVocher) * i?.quantity)}đ
                    </div>
                  </div>
                  <div className="border w-100 my-3" />
                </div>
              );
            })}
            <div className="d-flex justify-content-end">
              <div className="d-flex flex-column align-items-end">
                <div className="d-flex align-items-center gap-2">
                  <div className="txt-title-product-price">Thành tiền</div>
                  <div className="txt-product-detail-price">
                    {formatNumber(item?.total + item?.totalVoucher)}đ
                  </div>
                </div>
                <button
                  className="btn-submit mt-3"
                  onClick={() =>
                    navigate("/order-detail", { state: { data: item } })
                  }
                >
                  Chi tiết
                </button>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default memo(ListOrder);
